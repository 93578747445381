import React from 'react'
import MusicList from './MusicList'
// import AddAudioForm from './AddAudioForm'

const Music = ({ addMusic }) => {
  return (
    <div>
      <MusicList addMusic={addMusic}/>
    </div>
  );
};

export default Music