import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import SubHeadings from "./SubHeadings";
import SubHeadings2 from "./SubHeadings2";
import SubHeadings3 from "./SubHeadings3";

import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  get,
} from "firebase/firestore";
import { logEvent } from "firebase/analytics";

const Outline = ({
  db,
  setList,
  setContext,
  topic,
  setTopic,
  newName,
  setNewName,
  data2,
  setData2,
  api,
  setApi,
  researchBracket2,
  headF,
  subhs,
  panel,
  subheadings,
  setSubheadings,
  data,
  check,
  setCheck,
  section1,
  setSection1,
  readyToWrite,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  //---------------STATES-----------------------//

  const [loading, setLoading] = useState(false);
  const [out, setOut] = useState(true);
  const [sub, setSub] = useState(false);
  const initialRender = useRef(true);
  const [go, setGo] = useState(false);
  const [data99, setData99] = useState();

  //------------------------------------------//

  //--------------DATABASE REF----------------//
  const dataCollectionRef = collection(
    db,
    `users/${uid}/documents/${id}/listdata`
  );
  const returnsRef = collection(db, `users/${uid}/documents/${id}/listreturns`);
  const outlineRef = collection(db, `users/${uid}/documents/${id}/outline`);
  //-----------------------------------------//

  const subTab = () => {
    setOut(false);
    setSub(true);
  };

  const outTab = () => {
    setOut(true);
    setSub(false);
  };
  //-----------------API CALL----------------//
  const srcList = async () => {
    setLoading(true);
    setGo(!go);
    clearList();
    let obj = {
      search: newName,
      research2: researchBracket2,
      promp: {
        start: `Make a numbered list of ${newName} that have been mentioned in the text below:`,
        end: `Here's a numbered list of the ${newName} mentioned so far:`,
      },
      tokens: 300,
    };
    try {
      setTimeout(async function () {
        await addDoc(outlineRef, obj);
      }, 1000);
    } catch {
      alert(`something went wront`);
      setLoading(false);
    }
  };
  //---------------------------------------//

  //--------------ADD TO DATABASE---------------//

  //------------------------------------------//

  //------------clearList Function----------------//

  let delarr = [];
  let delarr2 = [];
  let delarr3 = [];
  const clearList = async () => {
    const querySnapshot1 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/listreturns`)
    );
    querySnapshot1.forEach((doc) => {
      delarr.push(doc.id);
    });

    for (let i = 0; i < delarr.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/listreturns`,
        delarr[i]
      );
      deleteDoc(docRef);
    }

    const querySnapshot2 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/listdata`)
    );
    querySnapshot2.forEach((doc) => {
      delarr2.push(doc.id);
    });

    for (let i = 0; i < delarr2.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/listdata`,
        delarr2[i]
      );
      deleteDoc(docRef);
    }

    const querySnapshot3 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/outline`)
    );
    querySnapshot3.forEach((doc) => {
      delarr3.push(doc.id);
    });

    for (let i = 0; i < delarr3.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/outline`,
        delarr3[i]
      );
      deleteDoc(docRef);
    }

    setData2([{ text: "" }]);
  };
  //---------------------------------------------//

  //---------------Event Handler-----------------//

  function handleInputChange(event) {
    const { name, value } = event.target;
    setData2(
      data2.map((data, i) => {
        if (i === parseInt(name)) {
          return {
            ...data,
            text: value,
          };
        } else {
          return data;
        }
      })
    );
  }
  //---------------------------------------------//
  let data1 = [];
  //----------------SNAPSHOT DATABASE-------------//
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;
    onSnapshot(returnsRef, (snapshot) => {
      snapshot.docs.map((doc) => {
        const data = doc.data();
        if (data.error) {
          alert("an error has occured");
        }
        data1.push({ ...data });
      });

      if (isMounted && data1[0]) {
        let data3 = data1[0].completion.split("\n");
        data3.splice(0, 1);
        for (var i = 0; i < data3.length; i++) {
          data3[i] = data3[i].replace(/^\d+\.\s/, "");
        }

        const obj = [];
        data3.forEach((elem, i) => {
          obj[i] = {
            id: i,
            text: elem,
          };
        });
        setData2(obj);

        let context = data1[0].context;
        setContext(context);
      }
    });
    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [loading]);

  useEffect(() => {
    const totalItems = subheadings.length * data2.length;
    if (totalItems > 100) {
      const numItemsToRemove = Math.ceil(
        (totalItems - 100) / subheadings.length
      );
      setData2((prevData) => prevData.slice(0, -numItemsToRemove));
    }
  }, [data2]);

  /////////////////////

  const fetchData = () => {
    onSnapshot(returnsRef, (snapshot) => {
      snapshot.docs.map((doc) => {
        const data = doc.data();
        data1.push(data);
      });
    });
    setData99(data1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  ////////////////////

  useEffect(() => {
    let isMounted = true;

    onSnapshot(returnsRef, (snapshot) => {
      snapshot.docs.map((doc) => {
        if (snapshot.size > 0 && isMounted) {
          setLoading(false);
        }
      });
    });

    return () => {
      isMounted = false;
    };
  }, [data2]);

  //-----------------------------------------------//

  //-----------------Remove Item-------------------//
  const removeItem = (index) => {
    const filteredArray = data2.filter((curElem) => {
      return curElem.id != index;
    });
    setData2(filteredArray);
  };
  //-------------------------------------------------//

  //--------------------Add Item---------------------//
  // const addItem = () => {
  //   setData2([
  //     ...data2,
  //     {
  //       id: data2.length + 1,
  //       text: "",
  //     },
  //   ]);
  // };

  const addItem = () => {
    const totalItems = (subheadings.length + 1) * data2.length;
    if (totalItems <= 100) {
      setData2([
        ...data2,
        {
          id: data2.length + 1,
          text: "",
        },
      ]);
    } else {
      alert("Maximum item limit reached");
    }
  };

  //-------------------------------------------------//

  //------------SEND DATA TO TEXTAREA----------------//
  useEffect(() => {
    //Loading complete

    let string = [];
    for (let i = 0; i < data2.length; i++) {
      string.push(data2[i].text);
    }
    setList(string);
    setContext(newName);
  }, [data2]);
  //-------------------------------------------------------------------------------------//
  // Define a function that will be called when the 'Move Up' button is clicked
  const moveUp = (index) => {
    // Create a new array with the textarea at the given index moved one position up
    const newTextareas = [...data2];
    if (index > 0) {
      const temp = newTextareas[index - 1];
      newTextareas[index - 1] = newTextareas[index];
      newTextareas[index] = temp;
    }
    // Update the component's state with the modified list of textareas
    setData2(newTextareas);
  };

  // Define a function that will be called when the 'Move Down' button is clicked
  const moveDown = (index) => {
    // Create a new array with the textarea at the given index moved one position down
    const newTextareas = [...data2];
    if (index < data2.length - 1) {
      const temp = newTextareas[index + 1];
      newTextareas[index + 1] = newTextareas[index];
      newTextareas[index] = temp;
    }
    // Update the component's state with the modified list of textareas
    setData2(newTextareas);
  };
  //---------------------------//

  //------------------------------------//
  return (
    <>
      {/* <div className={loading === true ? "overlay" : "hide"}>
        <div className={loading === true ? "square-7" : "hide"}></div>{" "}
      </div> */}
      <div className={loading === true ? "overlay2" : "hide"}>
        <div className="container">
          <span className="one"></span>
          <span className="two"></span>
          <span className="three"></span>
          <span className="four"></span>
        </div>
        <div className="five">researching..</div>
      </div>
      <div className="gen-top">
        <div className="gen-top1">
          <input
            className="gen-input"
            placeholder="Enter a brief topic heading (H1)"
            onChange={(e) => {
              e.preventDefault();
              setNewName(e.target.value);
            }}
          />
        </div>
        <div className="out-tabs">
          <div className={out ? "tab-focus" : "tab"} onClick={outTab}>
            H2 headings
          </div>{" "}
          <div className={sub ? "tab-focus" : "tab"} onClick={subTab}>
            {" "}
            H3 headings
          </div>
        </div>
      </div>

      <div className={sub ? "sub" : "hide"}>
        <SubHeadings
          subheadings={subheadings}
          setSubheadings={setSubheadings}
          newName={newName}
          setLoading={setLoading}
          data={data}
          data2={data2}
          readyToWrite={readyToWrite}
        />
      </div>

      <div className={out ? "out" : "hide"}>
        <div className="list-scroll">
          <div className="gen-area">
            <button
              disabled={newName.length < 3}
              className="list-gen-btn"
              onClick={srcList}
            >
              Generate Outline
            </button>
            <div
              className="tool-tip"
              data-tooltip="Enter a concise topic heading above, this will be used to search and generate an outline of sub-topics."
            >
              ?
            </div>
            <button
              disabled={newName.length < 6}
              className="ready-to-write"
              onClick={readyToWrite}
            >
              ✓ Ready to Write
            </button>
          </div>
          {data2.map((data, i) => {
            return (
              <div className="list-color" key={i}>
                <div className="list-area">
                  <div className="list-item">
                    <div onClick={() => moveUp(i)}>ᐱ</div>

                    <div onClick={() => moveDown(i)}>ᐯ</div>
                  </div>

                  <textarea
                    className="list-text"
                    value={data.text}
                    name={i}
                    onChange={handleInputChange}
                  />

                  <button
                    className="remove-item"
                    onClick={() => {
                      removeItem(data.id);
                    }}
                    key={data.id}
                  >
                    x
                  </button>
                </div>
              </div>
            );
          })}
          <div className="outline-btm-btns">
            <div
              className="add-item-btn"
              onClick={() => {
                addItem();
              }}
            >
              + Add Item
            </div>
            <div
              className="clear-list-btn"
              onClick={() => {
                clearList();
              }}
            >
              Clear List
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Outline;
