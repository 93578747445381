import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";

const Conclusion = ({
  conc,
  combi,
  combinedRef,
  db,
  data,
  subArray,
  intro,
  panel,
  initialRender2,
  subheadingtag,
  subtopictag,
  newName,
  data2,
  data3,
  write,
  setWrite,
  autoImage,
  setLoading2,
  loading2,
  editord,
  editorh,
  strings,
  editorRef,
  editorRef2,
  saveFunction,
  countColoredWords,
  research22,
  setResearch22,
  expected,
  setExpected,
  rExpected,
  setrExpected,
  writeBracket,
  setExpectedWords,
  progress22,
  setProgress22,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  //-------------------------------------//

  //--------------------------------------//

  //-----------------------------------------//
  const subsearchRef = collection(db, `users/${uid}/documents/${id}/subsearch`);
  const subsearchRef2 = collection(
    db,
    `users/${uid}/documents/${id}/subsearch2`
  );
  const subsearchRef3 = collection(
    db,
    `users/${uid}/documents/${id}/subsearch3`
  );

  //----------------------------------------//
  const [writeNumber, setWriteNumber] = useState(null);
  useEffect(() => {
    const regex = /\d+/;
    const match = writeBracket.match(regex);
    if (match) {
      const extractedNumber = parseInt(match[0], 10);
      setWriteNumber(extractedNumber);
    }

    if (panel === 1 || panel === 4) {
      let expected = 0;

      expected = listLength; // for subtopics * subheadings;
      if (intro) {
        expected = expected + 1;
      }
      if (conc) {
        expected = expected + 1;
      }
      let wordsNum = expected * writeNumber;
      setExpectedWords(wordsNum);
    }
    if (panel === 2 || panel === 3 || panel === 6) {
      let expected = 0;

      expected = subArray.length; // for subtopics * subheadings;

      if (intro) {
        expected = expected + 1;
      }
      if (conc) {
        expected = expected + 1;
      }
      let wordsNum = expected * writeNumber;
      setExpectedWords(wordsNum);
    }

    if (panel === 9) {
      let expected = 0;

      expected = data2.length; // for number of subtopics
      let wordsNum = expected * writeNumber;
      setExpectedWords(wordsNum);
    }

    if (panel === 5) {
      setExpectedWords(800);
    }
    if (panel === 8 || panel === 7 || panel === 10 || panel === 11) {
      setExpectedWords(500);
    }
  }, [writeBracket, intro, conc, panel, data2, data3, subArray]);

  //------------------------------------------//
  const [snap, setSnap] = useState([]);


  const concRef = collection(db, `users/${uid}/documents/${id}/conclusion`);
  let maintopic = newName;
  let subheadings = subArray;
  let obj = {};
  let subtopics = [];
  let trimText;
  //----------------------------------------------//

  //------------GET EXPECTED INDEX VALUE------------------//

  //info: subData depends on list length which is equal to number of subtopics * subheadings
  //info: subData2 & subData3 depend on subheadings length which is qual of length of subArray
  //---------------------------------------------//
  let listLength;
  if (data && data.subTopics) {
    listLength = data.subTopics.reduce((acc, subTopic) => {
      return acc + (subTopic.subHeadings.length || 1);
    }, 0);
  }
  //panel 1, 4 = have subtopics + subheadings --- intro, conc & photos  --- HAVE TITLE
  //  total = 1 (for title) + number of subtopics +  listLength + (listLength (if photos)) + 1 (if intro) + 1(if conc)

  useEffect(() => {
    let expected = 0;
    let subtopics = [];

    if (panel === 1 || panel === 4) {
      setrExpected(listLength);

      expected = 1; // for title

      expected = expected + data2.length; // for number of subtopics
      expected = expected + listLength; // for subtopics * subheadings;
      if (autoImage) {
        expected = expected + listLength; // for photos;
      }
      if (intro) {
        expected = expected + 1;
      }
      if (conc) {
        expected = expected + 1;
      }

      setExpected(expected);
    }

    if (panel === 2 || panel === 3 || panel === 6) {
      setrExpected(subArray.length);

      expected = 1; // for title

      expected = expected + subArray.length; // for subtopics * subheadings;
      if (autoImage) {
        expected = expected + subArray.length; // for photos;
      }
      if (intro) {
        expected = expected + 1;
      }
      if (conc) {
        expected = expected + 1;
      }
      setExpected(expected);
    }

    if (panel === 9) {
      setrExpected(data2.length);

      expected = 1; //for title
      expected = expected + data2.length; // for number of subtopics

      setExpected(expected);
    }

    if (panel === 5 || panel === 8) {
      expected = 2;

      setExpected(expected);
    }

    if (panel === 7 || panel === 10 || panel === 11) {
      expected = 1;

      setExpected(expected);
    }

    if (snap && snap.length > 0) {
      const alreadySaved = snap.filter((obj) => obj.saved).length;

      expected = expected + alreadySaved;

      setExpected(expected);
    }

    if (panel === 1 && data2 && data2[0]) {
      subtopics = data2.map(function (item) {
        return item.text;
      });
    } else if (panel === 2 && data3 && data3[0]) {
      subtopics = data3.map(function (item) {
        return item.text;
      });
    }

    if (panel === 1 || panel === 4) {
      onSnapshot(subsearchRef, (snapshot) => {
        setResearch22(snapshot.size);
      });
    } else if (panel === 2) {
      onSnapshot(subsearchRef2, (snapshot) => {
        setResearch22(snapshot.size);
      });
    } else if (panel === 3 || panel === 6 || panel === 9) {
      onSnapshot(subsearchRef3, (snapshot) => {
        setResearch22(snapshot.size);
      });
    }
  }, [
    panel,
    listLength,
    data2,
    data3,
    subArray,
    autoImage,
    intro,
    conc,
    snap,
    subsearchRef,
    subsearchRef2,
    subsearchRef3,
  ]);

  //-------------------------------------//

  //-------------Monitor Database & trigger AddDoc---------//

  useEffect(() => {
    let isMounted = true;

    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      onSnapshot(combi, (snapshot) => {
        let data1 = [];
        let trimText;
        snapshot.docs.map((doc) => data1.push({ ...doc.data(), id: doc.id }));
        setSnap(data1);
        // setResearch(snapshot.size)
      });
    }
    return () => {
      isMounted = false;
    };
  }, [panel]);
  //-------------------------------------------------//

  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      let data1 = snap;

      ////----------------------------------------////

      ////----------Get all HTML from aiCombined and strip to text-------///
      let text = data1.reduce((html, obj) => html + obj.html, "");
      ///--------Check text length and reduce as necessary------///

      // Split the text into an array of words
      let words = text.split(" ");
      let count = words.length;

      // Check if the number of words is above 1000
      if (count > 1000) {
        for (let i = 0; i < 3; i++) {
          // Remove 10% of the words randomly
          for (let j = 0; j < count * 0.1; j++) {
            words.splice(Math.floor(Math.random() * words.length), 1);
          }

          count = words.length;

          // Check if the number of words is still above 1000
          if (count <= 1000) {
            break;
          }
        }

        // Check if the number of words is still above 1000 after removing words
        if (count > 1000) {
          let sentences = text.match(/[^\.!\?]+[\.!\?]+/g);
          let new_sentences = [];
          let new_count = 0;
          for (let i = 0; i < sentences.length; i++) {
            let cur_sentence = sentences[i].replace(/[^\w\s]/gi, "");
            let cur_count = cur_sentence.split(" ").length;
            if (new_count + cur_count <= 1000) {
              new_sentences.push(sentences[i]);
              new_count += cur_count;
            } else {
              break;
            }
          }
          trimText = new_sentences.join(" ");
        } else {
          trimText = text;
        }
      } else {
        trimText = text;
      }
      /////---------CREATE CONC PROMP------------------//


      if (panel === 1 || panel === 2 || panel === 3 || panel === 4) {
        let promp;

        if (panel === 1 || panel === 4) {
          promp = `Below is the article I have written on ${maintopic} where I've looked at ${subtopics.join(
            ", "
          )} with subheadings of ${subheadings.join(
            ", "
          )}. Write (in HTML) a detailed Conculsion to this article using a ${subtopictag} "Conclusion" heading at the top.\n\nArticle:\n${trimText}\n\nDetailed Conclusion (in HTML):`;
        } else if (panel === 2) {
          promp = `I have written a comparison review (shown below) on ${maintopic} where I've looked at ${subtopics.join(
            ", "
          )} comparing the ${subheadings.join(
            ", "
          )} of each. Write (in HTML) a detailed Conculsion to this review using a ${subtopictag} "Conclusion" heading at the top.\n\nComparison Review:\n${trimText}\n\nDetailed Conclusion (in HTML):`;
        } else if (panel === 3) {
          promp = `Shown below is the tutorial I have written on ${maintopic} covering the steps ${subArray.join(
            ", "
          )}. Write (in HTML) a detailed Conculsion to this tutorial using a ${subtopictag} "Conclusion" heading at the top.\n\nTutorial:\n${trimText}\n\nDetailed Conclusion (in HTML):`;
        } else if (panel === 6) {
          promp = `Shown below is the Product Review I have written on ${maintopic} covering the subheadings ${subArray.join(
            ", "
          )}. Write (in HTML) a detailed Conculsion to this review using a ${subtopictag} "Conclusion" heading at the top.\n\nProduct Review for ${maintopic}:\n${trimText}\n\nDetailed Conclusion (in HTML):`;
        }

        obj = {
          index: 10000,
          promp: promp,
          text: trimText,
          tokens: 800,
          creativity: 0.8,
        };
      }
      ////---------------ADD CONC-----------------//////

      if (conc && expected - 1 === snap.length) {
        addDoc(concRef, obj);
      }

      //--------------------------//

      if (expected === snap.length) {
        setLoading2(false);
        setWrite(false);
        setTimeout(() => {
          saveFunction();
          countColoredWords();
        }, 1000);
      }
    }
  }, [snap]);

  //---------------DATABASE SYNC FUNCTIONS----------------------//

  //-------------LOADBAR------------------//
  // let prog1 = (snap.length / expected) * 100;
  // let prog2 = (research22 / (expected * 1.8)) * 100;
  // let percentage;
  // if (panel === 1 || panel === 4) {
  //   if (prog1 < 65) {
  //     percentage = prog2 + 10;
  //   } else {
  //     percentage = prog1;
  //   }
  // } else if (prog1 < 50) {
  //   percentage = prog2 + 10;
  // } else {
  //   percentage = prog1;
  // }

  // if (percentage > 100) {
  //   percentage = 100;
  // }

  useEffect(() => {
    let interval;
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (write) {
        setProgress22(0);
        interval = setInterval(() => {
          setProgress22((prevProgress) => {
            const nextProgress = prevProgress + (100 - prevProgress) / 40;
            return nextProgress > 99.99 ? 100 : nextProgress;
          });
        }, 200);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [write]);

  //-------------------------------------//

  return (
    <div className={write ? "top-loader" : "hide"}>
      <div
        className="top-progress"
        style={{ width: `${progress22}%`, zIndex: 99999 }}
      ></div>
    </div>
  );
};

export default Conclusion;
