import React, { useEffect, useState, useContext } from "react";
import { UserAuth } from "../context/AuthContext";

import { Player } from "@remotion/player";
import MyVideo from "../remotion/MyVideo";
import "../Remotion.css"
import Template1 from "../remotion/Template1";

const RemotionTest = ({ db }) => {
  const { user } = UserAuth();

  const [width, setWidth] = useState(100);

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const handleInputChange = (e) => {
    setWidth(parseInt(e.target.value));
  };

  return (
    <>
      {/* {userID === "Aw3pnLuHodOywniqY9fVMZAlPm02" ? ( */}
        <div className="admin-page">
          <input
            type="number"
            value={width.toString()}
            onChange={handleInputChange}
          />
          <p>Width: {width}</p>
          {/* <button onClick={fetchData}>Fetch Width</button> */}

          <div className="player">
            <Player
              component={Template1}
              durationInFrames={5120}
              compositionWidth={500}
              compositionHeight={500}
              fps={30}
              controls
              loop
            />
          </div>
        </div>
      {/* ) : (
        ""
      )} */}
    </>
  );
};

export default RemotionTest;


// import React, { useEffect, useState } from "react";
// import { Sequence, Audio } from "@remotion/media-utils";
// import { getAudioDurationInSeconds } from "@remotion/media-utils";

// const AudioWithDynamicDuration = () => {
//   const [audioDuration, setAudioDuration] = useState(null);
//   const audioSrc = "https://www.kozco.com/tech/LRMonoPhase4.mp3";
//   const fps = 30;

//   useEffect(() => {
//     const fetchAudioDuration = async () => {
//       try {
//         const duration = await getAudioDurationInSeconds(audioSrc);
//         setAudioDuration(duration);
//       } catch (error) {
//         console.error("Failed to fetch audio duration:", error);
//       }
//     };

//     fetchAudioDuration();
//   }, [audioSrc]);

//   const durationInFrames =
//     audioDuration !== null ? Math.ceil(audioDuration * fps) : 0;

//   return (
//     <>{audioDuration}</>
//   );
// };

// export default AudioWithDynamicDuration;