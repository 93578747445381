import { UserAuth } from "../context/AuthContext";

import React, { useState, useEffect } from "react";
import ArticleWriter from "./ArticleWriter";
// import VideoArticle from "./VideoArticle";
import AutoVideo from "../VideoComponents/AutoVideo";
import SocialVideos from "./SocialVideos";
import SocialVideoContainer from "./SocialVideoContainer";
import VideoContainer from "./VideoContainer";
import { collection, addDoc, getDocs, onSnapshot } from "firebase/firestore";

const WizardForm = ({ db, setWizard, addNewDoc, running, setRunning }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [step, setStep] = useState(1);
  // const [running, setRunning] = useState(false);

  const [userID, setUserID] = useState();

  const { user } = UserAuth();
  let uid = user.uid;

  useEffect(() => {
    if (uid) {
      setUserID(uid);
    }
  }, [uid]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setStep(step + 1);
  };

  const handleWizardClose = () => {
    if (running) {
      return alert(`cannot close whilst function is in progress`);
    }
    setWizard(false);
  };

  const handleBackClick = () => {
    if (running) {
      return alert(`cannot go back while function is in progress`);
    }
    setSelectedOption("");
    setStep(step - 1);
  };

  const handleArticle = () => {
    handleBackClick();
    setSelectedOption("Write an Article");
    setStep(2);
  };

  //////////Available Tokens/////////
  const [availableTokens, setAvailableTokens] = useState();

  useEffect(() => {
    async function getAvailableTokens() {
      const querySnapshot = await getDocs(
        collection(db, `users/${userID}/tokens/`)
      );

      let sum = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.tokens) {
          sum += data.tokens;
        }
      });
      const availableTokens = (sum / 2000).toFixed(2);

      setAvailableTokens(availableTokens);
    }

    getAvailableTokens();
  }, [userID]);

  //////////----------------///////////////

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            {" "}
            <div
              className={`option ${
                selectedOption === "Write an Article" ? "selected" : ""
              }`}
              onClick={() => handleOptionClick("Write an Article")}
            >
              <img src="http://sparker.ai/wp-content/uploads/2023/07/tools-icon.png" />{" "}
              Write an Article
            </div>
            <div
              className={`option ${
                selectedOption === "Create a Video Article" ? "selected" : ""
              }`}
              onClick={() => handleOptionClick("Create a Video Article")}
            >
              <img src="http://sparker.ai/wp-content/uploads/2023/08/video-btn-1.png" />{" "}
              Create a Video Article
            </div>
            <div
              className={`option ${
                selectedOption === "Multiple Social Media Videos"
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleOptionClick("Multiple Social Media Videos")}
            >
              <img src="http://sparker.ai/wp-content/uploads/2023/08/social-video-btn.png" />{" "}
              Create Social Media Videos
            </div>
          </>
        );
      case 2:
        if (selectedOption === "Write an Article") {
          return (
            <>
              <div className="filter-tabs2">
                <button className="back-button" onClick={handleBackClick}>
                  ← Back
                </button>
                <div>Article Maker</div>
              </div>
              <ArticleWriter
                db={db}
                setRunning={setRunning}
                availableTokens={availableTokens}
              />
            </>
          );
        } else if (selectedOption === "Create a Video Article") {
          return (
            <>
              <div className="filter-tabs2">
                <button className="back-button" onClick={handleBackClick}>
                  ← Back
                </button>
                <div>Video Maker</div>
              </div>
              <VideoContainer
                db={db}
                handleArticle={handleArticle}
                addNewDoc={addNewDoc}
                setRunning={setRunning}
                availableTokens={availableTokens}
              />
              {/* <AutoVideo db={db} /> */}
            </>
          );
        } else if (selectedOption === "Multiple Social Media Videos") {
          return (
            <>
              <div className="filter-tabs2">
                <button className="back-button" onClick={handleBackClick}>
                  ← Back
                </button>
                <div>Social Videos Maker</div>
              </div>

              <SocialVideoContainer
                db={db}
                handleArticle={handleArticle}
                addNewDoc={addNewDoc}
                setRunning={setRunning}
                availableTokens={availableTokens}
              />
            </>
          );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <div className="wizard-form">
      <div className="wizard-box">
        {" "}
        <div className="filter-tabs2">
          <div className="creator-wizard">WIZARD</div>
          <div
            className="close"
            onClick={() => {
              handleWizardClose();
            }}
          >
            close
          </div>
        </div>
        {renderStepContent()}
      </div>
    </div>
  );
};

export default WizardForm;
