import React, { useState, useEffect, useRef } from "react";
import VoiceList from "../VideoComponents/VoiceList";
import AutoVideo from "../VideoComponents/AutoVideo";
import SocialVideos from "./SocialVideos";
import Accordion from "./Accordion";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
// import VoiceList from "../VideoComponents/VoiceList";

import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import WizardDocuments from "./WizardDocuments";

const VideoContainer = ({ db, handleArticle, addNewDoc, setRunning, availableTokens }) => {
  const [functionTrigger, setFunctionTrigger] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState();
  const [docIDs, setDocIDs] = useState();
  const [updates, setUpdates] = useState();
  const [updates2, setUpdates2] = useState();

  const [selectedDoc, setSelectedDoc] = useState();
  const [num, setNum] = useState();



  
  let mainID = "06KxiU3CScJ5wgoIDUB1";

  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  console.log(updates);

  console.log(`function trigger is: ${functionTrigger}`);

  console.log("All use State Document IDs: ", docIDs);

  const [expandedTab, setExpandedTab] = useState(null);

  const handleTabClick = (index) => {
    setExpandedTab((prevExpandedTab) =>
      prevExpandedTab === index ? null : index
    );
  };

  return (
    <div>
      <div>
        <div className="wiz-tab" onClick={() => handleTabClick(0)}>
          {expandedTab === 0 ? "▼ " : "► "}Select an Article
        </div>
        {expandedTab === 0 && (
          <div className="article-section3">
            <div className="toggle-buttons">
              <div
                className="back-all-niches"
                onClick={() => {
                  handleArticle();
                  // handleOptionClick("Write an Article");
                }}
              >
                + New Article
              </div>
              <div className="wizard-docs">
                {" "}
                <WizardDocuments
                  userID={userID}
                  db={db}
                  selectedDoc={selectedDoc}
                  setSelectedDoc={setSelectedDoc}
                />
              </div>{" "}
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="wiz-tab" onClick={() => handleTabClick(1)}>
          {expandedTab === 1 ? "▼ " : "► "}Select a Voiceover
        </div>
        {expandedTab === 1 && (
          <div>
            <VoiceList
              setSelectedVoice={setSelectedVoice}
              selectedVoice={selectedVoice}
            />
          </div>
        )}
      </div>

      <div>
        <div className="wiz-tab" onClick={() => handleTabClick(2)}>
          {expandedTab === 2 ? "▼ " : "► "}Additional Settings (optional)
        </div>
        {expandedTab === 2 && (
          <div>
            <div className="article-section3">
              <div className="toggle-buttons">
                <div className="toggle-box">
                  <div className="toggle-text">
                    Only Generate Video Preview{" "}
                  </div>
                  <div className="toggle-area">
                    <span>OFF</span>
                    <div className="toggle-button">
                      <input
                        type="checkbox"
                        id="toggle1"
                        name="num"
                        checked={num}
                        onChange={() => setNum(!num)}
                      />{" "}
                      <label htmlFor="toggle1" />
                    </div>
                    <span>ON</span>
                  </div>
                </div>
                {num && (
                  <span className="increase-costs">
                    You will be able to edit the AI generated Video but will
                    need to Download it from within the Video Editing Console.
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="social-video-each">
        <AutoVideo
          db={db}
          id={selectedDoc}
          selectedVoice={selectedVoice}
          setUpdates={setUpdates}
          updates={updates}
          num={num}
          setRunning={setRunning}
          availableTokens={availableTokens}
        />
      </div>
    </div>
  );
};

export default VideoContainer;
