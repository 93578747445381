import React, { useState } from "react";


const FontPicker = ({ setFontFamily, fontFamily, updateFontFamily }) => {
  const handleChange = (e) => {
    updateFontFamily(e.target.value);
  };

  return (
    <div className="font-picker">
      <select value={fontFamily} onChange={handleChange}>
        <option value="">Select a Font</option>
        <option value="Open Sans">Open Sans</option>
        <option value="Roboto">Roboto</option>
        <option value="Lato">Lato</option>
        <option value="Montserrat">Montserrat</option>
        <option value="Raleway">Raleway</option>
        <option value="Source Sans Pro">Source Sans Pro</option>
        <option value="Poppins">Poppins</option>
        <option value="Playfair Display">Playfair Display</option>
        <option value="Ubuntu">Ubuntu</option>
        <option value="Noto Sans">Noto Sans</option>
        <option value="Oswald">Oswald</option>
        <option value="Merriweather">Merriweather</option>
        <option value="Pacifico">Pacifico</option>
        <option value="Quicksand">Quicksand</option>
        <option value="Roboto Condensed">Roboto Condensed</option>
        <option value="Lobster">Lobster</option>
        <option value="Quattrocento Sans">Quattrocento Sans</option>
        <option value="Roboto Slab">Roboto Slab</option>
        <option value="Lora">Lora</option>
        <option value="Nunito">Nunito</option>
        <option value="Josefin Sans">Josefin Sans</option>
        <option value="Titillium Web">Titillium Web</option>
        <option value="Exo">Exo</option>
        <option value="PT Sans">PT Sans</option>
        <option value="Karla">Karla</option>
        <option value="Archivo">Archivo</option>
        <option value="Rubik">Rubik</option>
        <option value="Inconsolata">Inconsolata</option>
        <option value="Barlow">Barlow</option>
        <option value="PT Serif">PT Serif</option>
        <option value="Karla">Karla</option>
        <option value="Anton">Anton</option>
        <option value="Oxygen">Oxygen</option>
        <option value="Work Sans">Work Sans</option>
        <option value="Quicksand">Quicksand</option>
        <option value="Comfortaa">Comfortaa</option>
        <option value="DM Sans">DM Sans</option>
        <option value="Fira Sans">Fira Sans</option>
        <option value="Archivo Black">Archivo Black</option>
        <option value="Questrial">Questrial</option>
        <option value="Rajdhani">Rajdhani</option>
        <option value="Exo 2">Exo 2</option>
        <option value="Nunito Sans">Nunito Sans</option>
        <option value="Abel">Abel</option>
        <option value="Bebas Neue">Bebas Neue</option>
      </select>
    </div>
  );
};

export default FontPicker;
