import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";

const ErrorMessage = ({
  errorsRef,
  combi,
  setWrite,
  loading2,
  setLoading2,
  error,
  setError,
  error2,
  setError2,
  errorMessage,
  db,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;
  //--------------------//
  const stopRef = doc(db, `users/${uid}/documents/${id}/stop/start-stop`);

  //------------------//

  const stopIt = async () => {
    await setDoc(stopRef, { stop: true });

    /////get doc refs for combined/////
    const querySnapshot = await getDocs(combi);
    let docids = [];
    querySnapshot.forEach((doc) => {
      docids.push(doc.id);
    });
    for (let i = 0; i < docids.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/combined`,
        docids[i]
      );

      /////set error fields as empty/////
      setDoc(docRef, { error: "" }, { merge: true });
    }

    setLoading2(false);
    setWrite(false);
    setError(false);
  };

  const ignoreIt = async () => {
    /////get doc refs for combined/////
    const querySnapshot = await getDocs(combi);
    let docids = [];
    querySnapshot.forEach((doc) => {
      docids.push(doc.id);
    });
    for (let i = 0; i < docids.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/combined`,
        docids[i]
      );

      /////set error fields as empty/////
      setDoc(docRef, { error: "" }, { merge: true });
    }

    setError(false);
  };

  const closeit = () => {
    setError2(false);
  };

  return (
    <>
      <div className={`error ${error ? "show" : ""}`}>
        <span>{errorMessage}</span>
        <div className="error-btns">
          <button className="stop" onClick={stopIt}>
            Stop Writing
          </button>
          <button className="ignore" onClick={ignoreIt}>
            Ignore for Now
          </button>
        </div>
      </div>
      <div className={`error ${error2 ? "show" : ""}`}>
        <span>
          Sorry! something went wrong with the server. Please try again after
          some time.
        </span>
        <div className="error-btns">
          <button className="ignore" onClick={closeit}>
            close
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorMessage;
