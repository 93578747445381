// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwjZazuOmS8UY4G4Z6ejmNcLPAyhspKEQ",
  authDomain: "cruncher-ai-app.firebaseapp.com",
  projectId: "cruncher-ai-app",
  storageBucket: "cruncher-ai-app.appspot.com",
  messagingSenderId: "102754476585",
  appId: "1:102754476585:web:14e4460d4aef3081cbf238",
  measurementId: "G-ETXV8CKHV8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
