import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { AuthContextProvider, user } from "./context/AuthContext";
import Movable2 from "./Components/Movable";

import "./Style.css";
import "./Mobile.css";

import { db } from "./firebase-config";
import TextArea from "./Components/TextArea";
import Navbar from "./Components/Navbar";

import Main from "./Pages/Main";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Verify from "./Pages/Verify";
import Account from "./Pages/Account";
import Usage from "./Pages/Usage";
import Billing from "./Pages/Billing";

import Protected from "./Components/Protected";
import Plans from "./Pages/Plans";

import Pricing from "./Pages/Pricing";
import Movable from "./Components/Movable";
import Topups from "./Pages/Topups";
import Admin from "./Pages/Admin";
import RemotionTest from "./Pages/RemotionTest";
import Video from "./Pages/Video";
import Niches from "./Components/Niches";

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route
            path="/manage-subscription"
            element={
              <Protected>
                <Plans db={db} />
              </Protected>
            }
          /> */}
          <Route
            path="/manage-subscription"
            element={
              <Protected>
                <Pricing db={db} />
              </Protected>
            }
          />
          <Route
            path="/top-up"
            element={
              <Protected>
                <Topups db={db} />
              </Protected>
            }
          />

          <Route
            path="/verify"
            element={
              <Protected>
                <Verify />
              </Protected>
            }
          />
          <Route
            path="/admin"
            element={
              <Protected>
                <Admin db={db} />
              </Protected>
            }
          />
          <Route
            path="/remotion-test"
            element={
              <Protected>
                <RemotionTest db={db} />
              </Protected>
            }
          />

          <Route
            path="/"
            element={
              <Protected>
                <Main db={db} />
              </Protected>
            }
          />
          <Route
            path="/doc/:id/:template?"
            element={
              <Protected>
                {" "}
                <TextArea db={db} />{" "}
              </Protected>
            }
          />

          <Route
            path="/video/:id"
            element={
              <Protected>
                {" "}
                <Video db={db} />{" "}
              </Protected>
            }
          />
          <Route
            path="/niche/:id"
            element={
              <Protected>
                {" "}
                <Niches db={db} />{" "}
              </Protected>
            }
          />

          <Route
            path="/account"
            element={
              <Protected>
                <Account db={db} />
              </Protected>
            }
          />
          <Route
            path="/usage"
            element={
              <Protected>
                <Usage />
              </Protected>
            }
          />
          <Route
            path="/billing"
            element={
              <Protected>
                <Billing />
              </Protected>
            }
          />
        </Routes>
      </AuthContextProvider>
    </>
  );
};

export default App;
