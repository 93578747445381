import React, { useState, useEffect } from "react";
import Templates from "./Templates";
import Outline from "./Outline";
import Outline2 from "./Outline2";
import Outline3 from "./Outline3";
import Outline5 from "./Outline5";
import Outline6 from "./Outline6";
import Outline7 from "./Outline7";
import Outline8 from "./Outline8";
import Outline9 from "./Outline9";
import Outline10 from "./Outline10";
import Outline11 from "./Outline11";

import FineTune from "./FineTune";
import PexelSearch from "./PexelSearch";
import ImageAi from "./ImageAi";
import Images from "./Images";
import { Link, useNavigate } from "react-router-dom";
import SparkerMenu from "./SparkerMenu";

const LeftPanel = ({
  readyToWrite,
  check,
  setCheck,
  setSection1,
  section1,
  handleToggleClick,
  insertImage,
  setPromp2,
  promp2,
  autoImage,
  setAutoImage,
  data,
  panel,
  setPanel,
  subheadings,
  setSubheadings,
  templ,
  outline,
  sparker,
  images,
  subhs,
  tune,
  setOutline,
  setSparker,
  setImages,
  setSubhs,
  setTempl,
  setTune,
  db,
  setList,
  setContext,
  topic,
  setTopic,
  newName,
  setNewName,
  aisize,
  websize,
  wordCount,
  api,
  setApi,
  data2,
  setData2,
  data3,
  setData3,
  research,
  setResearch,
  research2,
  setResearch2,
  researchBracket2,
  writing,
  setWriting,
  creativity,
  setCreativity,
  linksnum,
  setLinksnum,
  num,
  setNum,
  bul,
  setBul,
  intro,
  setIntro,
  conc,
  setConc,
  subtopictag,
  setSubtopictag,
  subheadingtag,
  setSubheadingtag,
  aiImages,
  setAiImages,
  imageRef,
  calc,
  template,
  expectedWords,
  sparkF,
  availableTokens,
}) => {
  const navigate = useNavigate();

  // const sparkF = () => {
  //   setOutline(false);
  //   setSparker(true);

  //   setSubhs(false);
  //   setTempl(false);
  //   setTune(false);
  //   setImages(false);
  // };

  const outF = () => {
    setOutline(true);
    setSparker(false);

    setSubhs(false);
    setTempl(false);
    setTune(false);
    setImages(false);
  };

  const imgF = () => {
    setImages(true);
    setSparker(false);

    setOutline(false);
    setSubhs(false);
    setTempl(false);
    setTune(false);
  };

  const headF = () => {
    setOutline(false);
    setSparker(false);

    setSubhs(true);
    setTempl(false);
    setTune(false);
    setImages(false);
  };

  const tempF = () => {
    setOutline(false);
    setSparker(false);

    setSubhs(false);
    setTempl(true);
    setTune(false);
    setImages(false);
  };

  const tuneF = () => {
    setOutline(false);
    setSparker(false);

    setSubhs(false);
    setTempl(false);
    setTune(true);
    setImages(false);
  };

  useEffect(() => {
    if (template === "true") {
      imgF();
    }
  }, []);

  return (
    <>
      <div
        className="collapse"
        onClick={() => {
          setSection1(!section1);
        }}
      >
        ᐯ
      </div>
      <div className="left-btns">
        <button
          className={
            sparker ? "left-btn  sparker-btn2" : "left-btn  sparker-btn"
          }
          onClick={() => {
            sparkF();
          }}
        />
        <button
          className={
            templ ? "left-btn templates-btn2" : "left-btn templates-btn"
          }
          onClick={tempF}
        />
        {calc ? (
          <button
            className={
              outline ? "left-btn headings-btn2" : "left-btn headings-btn"
            }
            onClick={outF}
          />
        ) : (
          <button
            className={
              outline ? "left-btn outline-btn2" : "left-btn outline-btn"
            }
            onClick={outF}
          />
        )}
        {/* <button
          className={outline ? "left-btn outline-btn2" : "left-btn outline-btn"}
          onClick={outF}
        /> */}

        <button
          className={images ? "left-btn images-btn2" : "left-btn images-btn"}
          onClick={imgF}
        />

        <button
          className={tune ? "left-btn adjust-btn2" : "left-btn adjust-btn"}
          onClick={tuneF}
        />
        {/* <button
          className={sparker ? "toggle-btn2" : "toggle-btn"}
          onClick={handleToggleClick}
        /> */}
      </div>
      <div className={sparker ? "sparker-menu left-panel" : "hide"}>
        <SparkerMenu
          setPanel={setPanel}
          panel={panel}
          expectedWords={expectedWords}
          db={db}
          availableTokens={availableTokens}
        />
      </div>

      <div className={outline ? "outline left-panel" : "hide"}>
        {panel === 1 || panel === 4 ? (
          <Outline
            db={db}
            setList={setList}
            setContext={setContext}
            topic={topic}
            setTopic={setTopic}
            newName={newName}
            setNewName={setNewName}
            api={api}
            setApi={setApi}
            data2={data2}
            setData2={setData2}
            researchBracket2={researchBracket2}
            headF={headF}
            subhs={subhs}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            data={data}
      
            section1={section1}
            setSection1={setSection1}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 2 ? (
          <Outline2
            db={db}
            setList={setList}
            setContext={setContext}
            topic={topic}
            setTopic={setTopic}
            newName={newName}
            setNewName={setNewName}
            api={api}
            setApi={setApi}
            data3={data3}
            setData3={setData3}
            researchBracket2={researchBracket2}
            headF={headF}
            subhs={subhs}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            data={data}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 3 ? (
          <Outline3
            db={db}
            setList={setList}
            setContext={setContext}
            topic={topic}
            setTopic={setTopic}
            newName={newName}
            setNewName={setNewName}
            api={api}
            setApi={setApi}
            data2={data2}
            setData2={setData2}
            researchBracket2={researchBracket2}
            headF={headF}
            subhs={subhs}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            data={data}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 5 ? (
          <Outline5
            newName={newName}
            setNewName={setNewName}
            setPromp2={setPromp2}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 6 ? (
          <Outline6
            db={db}
            setList={setList}
            setContext={setContext}
            topic={topic}
            setTopic={setTopic}
            newName={newName}
            setNewName={setNewName}
            api={api}
            setApi={setApi}
            data2={data2}
            setData2={setData2}
            researchBracket2={researchBracket2}
            headF={headF}
            subhs={subhs}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            data={data}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 7 ? (
          <Outline7
            setPromp2={setPromp2}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 8 ? (
          <Outline8
            setPromp2={setPromp2}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 9 ? (
          <Outline9
            db={db}
            setList={setList}
            setContext={setContext}
            topic={topic}
            setTopic={setTopic}
            newName={newName}
            setNewName={setNewName}
            api={api}
            setApi={setApi}
            data2={data2}
            setData2={setData2}
            researchBracket2={researchBracket2}
            headF={headF}
            subhs={subhs}
            panel={panel}
            subheadings={subheadings}
            setSubheadings={setSubheadings}
            data={data}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 10 ? (
          <Outline10
            newName={newName}
            setNewName={setNewName}
            setPromp2={setPromp2}
            readyToWrite={readyToWrite}
          />
        ) : null}
        {panel === 11 ? (
          <Outline11
            newName={newName}
            setNewName={setNewName}
            setPromp2={setPromp2}
            readyToWrite={readyToWrite}
          />
        ) : null}
      </div>

      <div className={templ ? "templates left-panel" : "hide"}>
        <Templates setPanel={setPanel} panel={panel} outF={outF} />
      </div>
      <div className={images ? "images left-panel" : "hide"}>
        <Images
          setSection1={setSection1}
          section1={section1}
          images={images}
          setPanel={setPanel}
          autoImage={autoImage}
          setAutoImage={setAutoImage}
          insertImage={insertImage}
          aiImages={aiImages}
          setAiImages={setAiImages}
          imageRef={imageRef}
          db={db}
          template={template}
        />
      </div>
      <div className={tune ? "finetune left-panel" : "hide"}>
        <FineTune
          panel={panel}
          research={research}
          setResearch={setResearch}
          research2={research2}
          setResearch2={setResearch2}
          writing={writing}
          setWriting={setWriting}
          creativity={creativity}
          setCreativity={setCreativity}
          linksnum={linksnum}
          setLinksnum={setLinksnum}
          num={num}
          setNum={setNum}
          bul={bul}
          setBul={setBul}
          intro={intro}
          setIntro={setIntro}
          conc={conc}
          setConc={setConc}
          subtopictag={subtopictag}
          subheadingtag={subheadingtag}
          setSubtopictag={setSubtopictag}
          setSubheadingtag={setSubheadingtag}
          autoImage={autoImage}
          setAutoImage={setAutoImage}
        />
      </div>
    </>
  );
};

export default LeftPanel;
