import React, { useEffect } from "react";
import GoogleButton from "react-google-button";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const GoogleLogin = () => {
  const { googleSignIn, user } = UserAuth();

  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      alert(`something went wrong, please try again`);
    }
  };

  useEffect(() => {
    if (user && user.emailVerified) {
      navigate("/");
    } else if (user && !user.emailVerified) {
      navigate("/verify");
    }
  }, [user]);

  return (
    <div className="google-login" onClick={handleGoogleSignIn}>
      <img
        src="http://sparker.ai/wp-content/uploads/2023/02/7611770-1.png"
        width="33px"
        align="left"
        alt=""
      />
      <span>Continue with Google</span>
    </div>
  );
};

export default GoogleLogin;
