import React, { useState, useEffect } from "react";

const Outline7 = ({ panel, setPromp2, readyToWrite }) => {
  //---------------------------------//
  const [text, setText] = useState("");

  //--------------------------//
  // const [aida, setAida] = useState(true);
  // const [pas, setPas] = useState(false);
  // const [emot, setEmot] = useState(false);
  // const [proof, setProof] = useState(false);
  // const [story, setStory] = useState(false);
  // const [scarce, setScarce] = useState(false);

  //------------------------//
  const [frame, setFrame] = useState(1);

  const [plat, setPlat] = useState(1);

  //------------------------------//
  const handleChange = (event) => {
    setText(event.target.value);
  };
  //----------------------//

  // const handleA = () => {
  //   setAida(!aida);
  //   setPas(false)
  // }
  // const handleP = () => {
  //   setPas(!pas);
  //   setAida(false);
  // };
  //-------------------------//
  /////////
  let platform = "";

  if (plat === 1) {
    platform = "Google";
  }
  if (plat === 2) {
    platform = "Facebook";
  }
  if (plat === 3) {
    platform = "Instagram";
  }
  if (plat === 4) {
    platform = "Youtube";
  }
  if (plat === 5) {
    platform = "TikTok";
  }
  if (plat === 6) {
    platform = "all purpose";
  }

  //////////
  let framework = {};
  if (frame === 1) {
    framework = {
      main: `Write a detailed and persuasive Ad Copy for the product data given below. Write in HTML giving appropriate headings. Use the AIDA (Attention, Interest, Decision, Action) framework and write about 200-300 words (in HTML):`,
      end: `Detailed Ad Copy in HTML using the AIDA framework (in HTML), 200-300 words:`,
    };
  }
  if (frame === 2) {
    framework = {
      main: `Write a detailed and persuasive Ad Copy for the product data given below. Write in HTML giving appropriate headings. Use the PAS (Problem, Agitate, Solution) framework and write about 200-300 words (in HTML):`,
      end: `Detailed Ad Copy in HTML using the PAS framework (in HTML), 200-300 words:`,
    };
  }
  if (frame === 3) {
    framework = {
      main: `I want to appeal to customer emtions about this product / service. What are the customer's pain points. Think about the ideal target customer and why they would love our product. Write me a passage that touches the customer on an emotional level (writing in HTML giving appropriate headings if needed). Write about 200-300 words (in HTML):`,
      end: `Emotionally appealing passage (in HTML), 200-300 words:`,
    };
  }
  if (frame === 4) {
    framework = {
      main: `I want to give social proof in selling this product / service. I want customer's to subtly get the sense that this product has been like by numerous other people like them. Also celebrities or people in authroity like this product (add [celebrity name] to allow me to insert a celeb). Write me a passage about 200-300 words (in HTML), give subheadings if needed:`,
      end: `Passage giving social proof for this product (in HTML), 200-300 words:
`,
    };
  }
  if (frame === 5) {
    framework = {
      main: `I want to tell a persuasive story for how this product / service helped deal with a person's difficulties. Narrate a story (writing in HTML giving appropriate headings if needed). Write as if you were telling a story, focusing on pain points. Create a narrative people can relate to. Write about 200-300 words (in HTML):`,
      end: `Story telling narrative for the product (in HTML), 200-300 words:
`,
    };
  }
  if (frame === 6) {
    framework = {
      main: `I want to appeal to the scarcity of this product / service. I want customer's to subtly get the sense that this is a one of a kind product with limited availability and that they may be missing out. Write me a passage that subtly gives tocuhes customer on an emotional level arousing a fear of missing out (write in HTML giving appropriate headings if needed). Write about 200-300 words (in HTML):`,
      end: `Passage subtly appealing to the scarcity of this product (in HTML), 200-300 words:
`,
    };
  }
  /////////
  //--------------------------------//
  if (panel === 7) {
    let prompt = `${framework.main}\n\nProduct / Service Data:\n${text}\n\n${framework.end}`;
    setPromp2(prompt);
  } else {
  }
  //---------------------------//

  //--------------------------------//

  //-------------------------------//

  return (
    <>
      <div className="form-area">
        <div className="form-title">
          Briefly tell us about your product or service
        </div>
        <button
          disabled={text.length < 20}
          className="ready-to-write"
          onClick={readyToWrite}
        >
          ✓ Ready to Write
        </button>
        <textarea
          className="form-text"
          placeholder="Product name, brand name, USP, target audience, any brand guidelines."
          value={text}
          onChange={(event) => handleChange(event)}
        />
        <div className="framework">Framework</div>
        <div className="frameworks">
          <div
            className={frame === 1 ? "frame aida" : "frame2 aida2"}
            onClick={() => setFrame(1)}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/01/AIDA.png" />
          </div>
          <div
            className={frame === 2 ? "frame pas" : "frame2 pas2"}
            onClick={() => setFrame(2)}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/01/PAS.png" />
          </div>
        </div>
        {/* <div className="framework">+</div> */}
        <div className="frameworks">
          <div
            className={frame === 3 ? "frame emot" : "frame2 emot2"}
            onClick={() => setFrame(3)}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/01/Emots.png" />
          </div>
          <div
            className={frame === 4 ? "frame proof" : "frame2 proof2"}
            onClick={() => setFrame(4)}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/01/proof.png" />
          </div>
          <div
            className={frame === 5 ? "frame story" : "frame2 story2"}
            onClick={() => setFrame(5)}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/01/story.png" />
          </div>
          <div
            className={frame === 6 ? "frame scarce" : "frame2 scarce2"}
            onClick={() => setFrame(6)}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/01/scarce.png" />
          </div>
        </div>
      </div>
      <div className="framework">Platform</div>
      <div className="platforms">
        <div
          className={plat === 1 ? "plat google" : "plat2 google2"}
          onClick={() => setPlat(1)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/google.png" />
        </div>
        <div
          className={plat === 2 ? "plat facebook" : "plat2 facebook2"}
          onClick={() => setPlat(2)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/facebook.png" />
        </div>
        <div
          className={plat === 3 ? "plat instagram" : "plat2 instagram2"}
          onClick={() => setPlat(3)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/instagram.png" />
        </div>
        <div
          className={plat === 4 ? "plat youtube" : "plat2 youtube2"}
          onClick={() => setPlat(4)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/youtube.png" />
        </div>
        <div
          className={plat === 5 ? "plat tiktok" : "plat2 tiktok2"}
          onClick={() => setPlat(5)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/tiktok.png" />
        </div>
        <div
          className={plat === 6 ? "plat other" : "plat2 other2"}
          onClick={() => setPlat(6)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/other.png" />
        </div>
      </div>
    </>
  );
};

export default Outline7;
