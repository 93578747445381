import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";

const NotEnough = ({ db, notEnough, setNotEnough }) => {
  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const [menu, setMenu] = useState(false);

  ////------------------------------------/////

  const [activePlan, setActivePlan] = useState(null);
  const [promoUsed, setPromoUsed] = useState(false);

  const [num, setNum] = useState(true);

  const [topup, setTopup] = useState(false);

  ////----------------------------------//////

  const loadCheckout = async (plan) => {
    let priceId;
    let session;

    if (
      plan === "tokens-promo" ||
      plan === "tokens-5" ||
      plan === "tokens-10"
    ) {
      if (plan === "tokens-promo") {
        priceId = "price_1N2HQ7EapUO2qFdSBJuiZdQY";
      } else if (plan === "tokens-5") {
        priceId = "price_1N2HLrEapUO2qFdS17qomMof";
      } else if (plan === "tokens-10") {
        priceId = "price_1N2HOaEapUO2qFdSMTlJfjug";
      }

      session = {
        price: priceId,
        mode: "payment",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      };
    }

    const docRef = collection(db, `users/${uid}/checkout_sessions`);
    const checkoutDocRef = await addDoc(docRef, session);

    onSnapshot(checkoutDocRef, async (docSnapshot) => {
      const { error, sessionId } = docSnapshot.data();

      if (error) {
        alert(`An error occure: ${error.message}`);
        setTopup(false);
      }

      if (sessionId) {
        const stripe = await loadStripe(
          "pk_live_51Mhss2EapUO2qFdSWOxYgcwn6IMdZtLN3h884Dv4xIPobyWvMiTSpEk18KhiKM6SLQAUoOXV5RoUmsMpD8JWJOWo001Mv08J9g"
        );

        stripe.redirectToCheckout({ sessionId });
      }
    });
  };
  //--------------------//

  useEffect(() => {
    async function checkSubscriptionStatus() {
      try {
        const subscriptionRef = collection(db, `users/${userID}/subscriptions`);
        const snapshot = await getDocs(subscriptionRef);

        if (snapshot.empty) {
          setActivePlan(null); // subscription collection doesn't exist
        } else {
          let isActive = false;
          snapshot.forEach((doc) => {
            if (doc.data().status === "active") {
              isActive = true;
              setActivePlan(doc.data().items[0].price.product.name); // set activePlan based on active subscription
            }
          });
        }
      } catch (error) {}
    }

    async function checkUsedPromo() {
      const promoRef = doc(db, `users/${uid}/total-tokens/promo-tokens`);

      getDoc(promoRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const used = data.used || false; // set the variable to false if the field value is falsy
            setPromoUsed(used);
            // use the value of `used` here
          } else {
            const used = false;
            setPromoUsed(used);
            // handle the case where the document doesn't exist here
          }
        })
        .catch((error) => {});
    }

    checkSubscriptionStatus();
    checkUsedPromo();
  }, [userID]);

  //------------------//

  return (
    <>
      <div className={notEnough ? "not-enough" : "hide"}>
        <div
          className="all-templates close-message-not"
          onClick={() => {
            setNotEnough(false);
          }}
        >
          close
        </div>
        <div className="not-container">
          <h3>Sorry you don't have enough tokens</h3>
          <span>
            Please refer to the docs for mininum required tokens to start each
            operation.
          </span>
          {activePlan ? (
            <span>
              {" "}
              Active: <span className="right-text">{activePlan}</span>
            </span>
          ) : (
            <span>
              <Link to="/manage-subscription">Upgrade</Link> to get upto 40%
              more tokens per topup.{" "}
            </span>
          )}
        </div>
        <div className="writer-container">
          <div className="documents-wraper topups-wraper">
            <div className="documents">
              <div className="topup-item">
                <img
                  src="http://sparker.ai/wp-content/uploads/2023/08/top-up-5.png"
                  onClick={() => {
                    loadCheckout("tokens-5");
                    setTopup("topup-5");
                  }}
                />
                <div
                  className={
                    topup && topup === "topup-5"
                      ? "topups-loader loader-2"
                      : "hide"
                  }
                ></div>
              </div>

              <div className="topup-item">
                <img
                  src="http://sparker.ai/wp-content/uploads/2023/08/top-up-10.png"
                  onClick={() => {
                    loadCheckout("tokens-10");
                    setTopup("topup-10");
                  }}
                />
                <div
                  className={
                    topup && topup === "topup-10" ? "topups-loader" : "hide"
                  }
                ></div>
              </div>
            </div>

            <div className="star-text-topup">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotEnough;
