import React, { useState } from "react";

const ProofMessage = ({
  proofReader,
  promp5,
  setPromp5,
  proofMessage,
  setProofMessage,
}) => {
  return (
    <div className={proofMessage ? "proof-message" : "hide"}>
      <div
        className="all-templates close-message"
        onClick={() => {
          setProofMessage(false);
        }}
      >
        close
      </div>{" "}
      <textarea
        className="proof-input"
        type="text"
        value={promp5}
        onChange={(e) => setPromp5(e.target.value)}
        placeholder="Enter your message"
      />
      <button
        onClick={() => {
          proofReader();
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default ProofMessage;
