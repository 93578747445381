import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import GoogleLogin from "./GoogleLogin";
import Navbar from "../Components/Navbar";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgot, setForgot] = useState(false);

  const { signIn, user, forgotPassword } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // logic to submit form goes here
      if (!email || !password) {
        throw new Error("Email and password are required fields.");
      }
      await signIn(email, password);
    } catch (err) {
      setError("Invalid email or password. Please try again.");
    }
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    try {
      // logic to submit form goes here
      if (!email) {
        throw new Error("Please enter your email address.");
      }
      await forgotPassword(email);
    } catch (err) {
      setError("Invalid email address. Please try again.");
    }
  };

  return (
    <>
      <div className="login-background">
        <div className="login-page">
          <div className="login-area">
            <div className="login-top">
              <img
                src="http://sparker.ai/wp-content/uploads/2023/01/Sparker-new-logo-3-5.png"
                width="33px"
                align="center"
              />
              <h2>Login to Sparker AI</h2>
            </div>
            <GoogleLogin />

            {forgot ? (
              <>
                <div className="line-sep"></div>
                <span className="line-text">Forgot Password</span>

                <form className="login-form" onSubmit={handleForgot}>
                  {error && <div className="error4">{error}</div>}
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <button className="form-btn" type="submit">
                    Reset Password
                  </button>
                </form>
                <div
                  className="forgot"
                  onClick={() => {
                    setForgot(false);
                  }}
                >
                  ← back to sign in
                </div>
              </>
            ) : (
              <>
                <div className="line-sep"></div>
                <span className="line-text">Or Sign in with Email</span>

                <form className="login-form" onSubmit={handleSubmit}>
                  {error && <div className="error4">{error}</div>}
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder="Password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button className="form-btn" type="submit">
                    Login
                  </button>
                </form>

                <div
                  className="forgot"
                  onClick={() => {
                    setForgot(true);
                  }}
                >
                  Forgot Password?
                </div>
              </>
            )}
            <div className="line-sep2"></div>
            <div className="account-yet">
              Don't have an account yet?{" "}
              <Link to="/signup">Create free account</Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
