import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";

const SurveyForm = ({ db, userID, setWizard }) => {
  const [showForm, setShowForm] = useState();
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherAnswer, setOtherAnswer] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const checkExistingDocument = async () => {
      const surveyCollection = collection(db, `users/${userID}/survey`);
      const surveyDoc = doc(surveyCollection, "survey_doc");

      const docSnapshot = await getDoc(surveyDoc);
      if (docSnapshot.exists()) {
        setShowForm(false);
      } else {
        setShowForm(true);
      }
    };

    checkExistingDocument();
  }, []);

  const handleAnswer1Change = (event) => {
    setAnswer1(event.target.value);
  };

  const handleAnswer2Change = (event) => {
    const selectedAnswer = event.target.value;
    setAnswer2(selectedAnswer);

    if (selectedAnswer === "other") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
      setOtherAnswer("");
    }
  };

  const handleOtherAnswerChange = (event) => {
    setOtherAnswer(event.target.value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!answer1) {
      newErrors.answer1 = "Please select an option for Question 1.";
    }

    if (!answer2) {
      newErrors.answer2 = "Please select an option for Question 2.";
    } else if (answer2 === "other") {
      if (!otherAnswer) {
        newErrors.otherAnswer = "Please specify your answer for Question 2.";
      } else if (otherAnswer.length < 4 || otherAnswer.length > 30) {
        newErrors.otherAnswer =
          "Answer for Question 2 should be between 4 and 30 characters.";
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!validateForm()) {
  //     return;
  //   }

  //   const surveyCollection = collection(db, `users/${userID}/survey`);
  //   const surveyDoc = doc(surveyCollection, "survey_doc");

  //   const surveyData = {
  //     app_interest: answer1,
  //     hear_about_us: answer2 === "other" ? otherAnswer : answer2,
  //   };

  //   await setDoc(surveyDoc, surveyData);
  //   setShowForm(false);
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const submissionTime = new Date(); // Get the current date and time

    const surveyCollection = collection(db, `users/${userID}/survey`);
    const surveyDoc = doc(surveyCollection, "survey_doc");

    const surveyData = {
      app_interest: answer1,
      hear_about_us: answer2 === "other" ? otherAnswer : answer2,
      submission_time: submissionTime, // Convert to ISO string for Firestore
    };

    await setDoc(surveyDoc, surveyData);
    setShowForm(false);
    setTimeout(() => {
      setWizard(true);
    }, 2000);
  };

  if (!showForm) {
    return "";
  }

  return (
    <form className="survey-form" onSubmit={handleSubmit}>
      <div className="survey-box">
        <h2>
          Answer these questions and get your <span>$3</span> free signup credit
        </h2>
        <div className="survey-item">
          <h4>1. Which Feature are you most interested in using ?</h4>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer1"
              value="Article Writer"
              checked={answer1 === "Article Writer"}
              onChange={handleAnswer1Change}
            />
            <span>Article Writer</span>
          </label>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer1"
              value="Text-to-Video"
              checked={answer1 === "Text-to-Video"}
              onChange={handleAnswer1Change}
            />
            <span>Text-to-Video</span>
          </label>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer1"
              value="Both"
              checked={answer1 === "Both"}
              onChange={handleAnswer1Change}
            />
            <span>Both</span>
          </label>

          {errors.answer1 && (
            <span className="survey-errors">{errors.answer1}</span>
          )}
        </div>

        <div className="survey-item">
          <h4>2. How did your hear about Sparker AI ?</h4>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer2"
              value="Google"
              checked={answer2 === "Google"}
              onChange={handleAnswer2Change}
            />
            <span>Google</span>
          </label>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer2"
              value="Wordpress"
              checked={answer2 === "Wordpress"}
              onChange={handleAnswer2Change}
            />
            <span>Wordpress</span>
          </label>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer2"
              value="Fiverr"
              checked={answer2 === "Fiverr"}
              onChange={handleAnswer2Change}
            />
            <span>Fiverr</span>
          </label>
          <label>
            <input
              className="square-radio"
              type="radio"
              name="answer2"
              value="Instagram"
              checked={answer2 === "Instagram"}
              onChange={handleAnswer2Change}
            />
            <span>Instagram</span>
          </label>
          <div className="survey-other">
            <label>
              <input
                className="square-radio"
                type="radio"
                name="answer2"
                value="other"
                checked={answer2 === "other"}
                onChange={handleAnswer2Change}
              />
              <span>Other</span>
            </label>
            {showOtherInput && (
              <>
                <input
                  className="other-input"
                  type="text"
                  placeholder="Please specify"
                  value={otherAnswer}
                  onChange={handleOtherAnswerChange}
                />
                {errors.otherAnswer && (
                  <span className="survey-errors">{errors.otherAnswer}</span>
                )}
              </>
            )}
          </div>
          {errors.answer2 && (
            <span className="survey-errors">{errors.answer2}</span>
          )}
        </div>

        <button className="survey-submit" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default SurveyForm;
