import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

const VoiceList = ({ setSelectedVoice, selectedVoice }) => {
  const [voiceList, setVoiceList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selection, setSelection] = useState();

  useEffect(() => {
    const fetchVoiceList = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "google-voices"));
        const voiceData = querySnapshot.docs.map((doc) => doc.data());
        setVoiceList(voiceData);
      } catch (error) {
        console.error("Error fetching music list: ", error);
      }
    };

    fetchVoiceList();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredVoiceList = voiceList.filter((voice) => {
    const { name, language } = voice;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      name.toLowerCase().includes(lowerCaseSearchQuery) ||
      language.toLowerCase().includes(lowerCaseSearchQuery)
    );
  });

  return (
    <div className="music-list">
      <input
        className="gen-input7"
        type="text"
        value={searchQuery}
        onChange={handleSearchInputChange}
        placeholder="Search voice"
      />
      <div className="filter-tabs4">
        <div className="audio-list">
          {filteredVoiceList.map((voice, i) => (
            <div
              key={voice.id}
              className={
                selection === i ? "music-item selection" : "music-item"
              }
              onClick={() => {
                setSelection(i);
                setSelectedVoice(voice);
              }}
            >
              <span>{voice.name}</span>
              {/* <div className="audio-wrapper">
            <audio controls src={voice.url[0]} controlsList="nodownload" />
          </div> */}
            </div>
          ))}
        </div>
        <div className="audio-wrapper">
          {selectedVoice ? (
            <audio
              controls
              src={selectedVoice.url[0]}
              controlsList="nodownload"
            />
          ) : (
            "Select a voice"
          )}
        </div>
      </div>
    </div>
  );
};

export default VoiceList;
