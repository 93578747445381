import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  doc,
  collectionGroup,
  getDocs as getCollectionDocs,
} from "firebase/firestore";

const WizardDocuments = ({ db, userID, setSelectedDoc, selectedDoc }) => {
  const [allDocs, setAllDocs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Function to fetch all documents from the 'users/${userID}/documents' collection
    const fetchAllDocuments = async () => {
      try {
        const userDocumentsRef = collection(db, `users/${userID}/documents`);
        const userDocumentsSnapshot = await getDocs(userDocumentsRef);

        // Extracting data from the 'html' field of the first document in 'combined' collection
        const documentDataPromises = userDocumentsSnapshot.docs.map(
          async (docSnapshot) => {
            const combinedRef = collection(
              db,
              `users/${userID}/documents/${docSnapshot.id}/combined`
            );
            const combinedSnapshot = await getDocs(query(combinedRef)); // Use query to get the first document in the collection
            if (!combinedSnapshot.empty) {
              const firstCombinedDoc = combinedSnapshot.docs[0];
              const html = firstCombinedDoc.get("html");
              const cleanHtml =
                html.replace(/<[^>]*>/g, "").slice(0, 40) + `..`; // Remove HTML tags and keep first 20 characters
              return {
                id: docSnapshot.id,
                html: cleanHtml,
              };
            }
            return null;
          }
        );

        // Resolving all the promises and updating the state with 'allDocs' array
        const documentDataArray = await Promise.all(documentDataPromises);
        setAllDocs(documentDataArray.filter((data) => data !== null));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    // Fetch all documents when the component mounts
    fetchAllDocuments();
  }, [db, userID]);

  // Function to handle search query changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter the documents based on the search query
  // const filteredDocs = allDocs.filter((data) =>
  //   data.html.includes(searchQuery)
  // );
  const filteredDocs = allDocs.filter((data) =>
    data.html.toLowerCase().includes(searchQuery.toLowerCase())
  );

  console.log(`selected doc id is: ${selectedDoc}`);

  return (
    <>
      <div>
        {/* Search input box */}
        <input
          className="gen-input6"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search articles..."
        />
      </div>
      {filteredDocs.length > 0 && filteredDocs ? (
        <>
          <div className="wiz-docs-container">
            {/* You can render the 'allDocs' state here */}
            {filteredDocs.map((data, index) => (
              <div
                onClick={() => {
                  setSelectedDoc(data.id);
                }}
                className={
                  selectedDoc === data.id
                    ? "wiz-doc-each selected"
                    : "wiz-doc-each"
                }
                key={index}
              >
                {/* <div>Document ID: {data.id}</div> */}
                <div>{data.html}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="choose-niche">
          <span>You don't have any articles</span>
        </div>
      )}
    </>
  );
};

export default WizardDocuments;
