import React, { useState, useEffect, useRef } from "react";
import VoiceList from "../VideoComponents/VoiceList";

import SocialVideos from "./SocialVideos";
import Accordion from "./Accordion";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
// import VoiceList from "../VideoComponents/VoiceList";

import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import WizardDocuments from "./WizardDocuments";

const SocialVideoContainer = ({ db, handleArticle, setRunning, availableTokens }) => {
  const [functionTrigger, setFunctionTrigger] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState();
  const [docIDs, setDocIDs] = useState();
  const [num, setNum] = useState();

  const [updates, setUpdates] = useState([
    // `please wait..`,
    // `please wait..`,
    // `please wait..`,
  ]);
  const [updates2, setUpdates2] = useState();

  const [selectedDoc, setSelectedDoc] = useState();

  let mainID = "06KxiU3CScJ5wgoIDUB1";

  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  /////---------Remove HTML-----/////
  function removeHtmlTags(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  }

  ////--------From AI4-----------////
  const fromAI4 = async (text) => {
    console.log(`fromAI4 started`);

    let promp = `Make a numbered list of 3 different but interesting short paragraphs in your own words using this text. Each of the short paragraphs should be good to post on social media with a text-to-speech voiceover. When making the paragrpahs choose wording which has a virality factor for social media but don't add any hastags. Also, if writing numbers use whole numbers only. Here is the text ----- ${text} ---- 3 interesting short paragraphs to use in social media posts`;
    let resp;
    try {
      console.log(`starting the fetch for fromAI`);
      console.log(`userID inside try is:`);
      console.log(userID);
      const response = await fetch(
        `https://us-central1-cruncher-ai-app.cloudfunctions.net/fromAI4?u=${userID}&i=${selectedDoc}&promp=${promp}`,
        {
          mode: "cors",
        }
      );
      const data = await response.json();
      if (data.model === "chat") {
        resp = data.data.choices[0].message.content;
      } else if (data.model === "davinci3" || data.model === "davinci2") {
        resp = data.data.choices[0].text;
      }
    } catch (error) {
      console.log(error);

      alert(`something went wrong`);
    }

    return resp;
  };

  /////---------------------////////

  const create3Docs = async () => {
    if (!selectedDoc) {
      return alert(`please select an article to convert`);
    }

    if (!selectedVoice) {
      return alert(`please select a voice for the voice over`);
    }

    let html;
    let cleanText;
    const combinedRef = collection(
      db,
      `users/${userID}/documents/${selectedDoc}/combined`
    );

    setUpdates2(`gathering interesting facts from main article..`);
    setRunning(true);

    console.log(`get html trigerred`);

    try {
      console.log(`inside try`);

      const querySnapshot = await getDocs(combinedRef);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        html = data.html;

        console.log(html);

        cleanText = removeHtmlTags(html);

        console.log(cleanText);

        // Use the 'html' data as needed
      });
    } catch (error) {
      // Handle any errors that occur
    }

    let paragraphs = await fromAI4(cleanText);
    console.log(paragraphs);

    // Split the string into an array of strings without the numbers
    // const stringArray = paragraphs.split(/\d+\.\s+/);

    const stringArray = paragraphs.split(/\n\d+\.\s/).filter(Boolean);

    // Filter out any empty strings
    const filteredArray = stringArray
      .filter((str) => str.trim() !== "")
      .map((str) =>
        str
          .replace(/\n/g, "")
          .replace(/"/g, "")
          .replace(/^\d+\.\s*/, "")
      );
    console.log(filteredArray);

    const docIDs = [];

    for (let i = 0; i < filteredArray.length; i++) {
      setUpdates2(`creating temp documents..`);
      setRunning(true);

      // Add a new document to the 'documents' collection
      const newDocumentRef = await addDoc(
        collection(db, `users/${userID}/documents/`),
        {}
      );

      // Push the new document ID into the 'docIDs' array
      docIDs.push(newDocumentRef.id);

      // Using the ID of the newly created document, add a new document to the 'combined' collection
      const combinedRef = collection(
        db,
        `users/${userID}/documents/${newDocumentRef.id}/combined`
      );
      await addDoc(combinedRef, {
        index: i,
        html: filteredArray[i],
        string: "",
        completion: [{}],
      });
    }

    console.log("All Document IDs: ", docIDs);

    setDocIDs(docIDs);
    setRunning(false);

    setTimeout(() => {
      setFunctionTrigger(true);
    }, 1500); // 1000 milliseconds = 1 second
  };

  console.log(updates);

  console.log(`function trigger is: ${functionTrigger}`);

  console.log("All use State Document IDs: ", docIDs);

  const [expandedTab, setExpandedTab] = useState(null);

  const handleTabClick = (index) => {
    setExpandedTab((prevExpandedTab) =>
      prevExpandedTab === index ? null : index
    );
  };

  return (
    <div>
      <div>
        <div className="wiz-tab" onClick={() => handleTabClick(0)}>
          {expandedTab === 0 ? "▼ " : "► "}Select an Article
        </div>
        {expandedTab === 0 && (
          <div>
            <div
              className="back-all-niches"
              onClick={() => {
                handleArticle();
                // handleOptionClick("Write an Article");
              }}
            >
              + New Article
            </div>
            <div className="wizard-docs">
              {" "}
              <WizardDocuments
                userID={userID}
                db={db}
                selectedDoc={selectedDoc}
                setSelectedDoc={setSelectedDoc}
              />
            </div>{" "}
          </div>
        )}
      </div>
      <div>
        <div className="wiz-tab" onClick={() => handleTabClick(1)}>
          {expandedTab === 1 ? "▼ " : "► "}Select a Voiceover
        </div>
        {expandedTab === 1 && (
          <div>
            <VoiceList
              setSelectedVoice={setSelectedVoice}
              selectedVoice={selectedVoice}
            />
          </div>
        )}
      </div>

      <div>
        <div className="wiz-tab" onClick={() => handleTabClick(2)}>
          {expandedTab === 2 ? "▼ " : "► "}Additional Settings (optional)
        </div>
        {expandedTab === 2 && (
          <div>
            <div className="article-section3">
              <div className="toggle-buttons">
                <div className="toggle-box">
                  <div className="toggle-text">
                    Only Generate Video Preview{" "}
                  </div>
                  <div className="toggle-area">
                    <span>OFF</span>
                    <div className="toggle-button">
                      <input
                        type="checkbox"
                        id="toggle1"
                        name="num"
                        checked={num}
                        onChange={() => setNum(!num)}
                      />{" "}
                      <label htmlFor="toggle1" />
                    </div>
                    <span>ON</span>
                  </div>
                </div>
                {num && (
                  <span className="increase-costs">
                    You will be able to edit the AI generated Video but will
                    need to Download it from within the Video Editing Console.
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <button
        className="write-btn5"
        onClick={async () => {
          create3Docs();
        }}
      >
        Create Social Media Videos
      </button>

      {docIDs ? (
        docIDs.map((id, index) => (
          <div className="social-video-each" key={id}>
            <SocialVideos
              db={db}
              id={id}
              num={num}
              functionTrigger={functionTrigger}
              selectedVoice={selectedVoice}
              setRunning={setRunning}
              availableTokens={availableTokens}
              // setUpdates={(update) => {
              //   const newUpdates = [...updates];
              //   newUpdates[index] = update;
              //   setUpdates(newUpdates);
              // }}
              // updates={updates[index]}
            />
          </div>
        ))
      ) : (
        <div>
          {" "}
          {updates2 ? (
            <div className="social-progress">
              <div className="social-loader2"></div>
              <div className="social-updates">{updates2}</div>
            </div>
          ) : (
            <></>
          )}{" "}
        </div>
      )}
    </div>
  );
};

export default SocialVideoContainer;
