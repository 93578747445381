import React from 'react'
import PexelSearch from './PexelSearch'

const Images = ({ updateImageUrl, updateImageArray }) => {
  return (
    <>
      <PexelSearch
        updateImageUrl={updateImageUrl}
        updateImageArray={updateImageArray}
      />
    </>
  );
};

export default Images