import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import Navbar from "../Components/Navbar";

const Verify = () => {
  const { verifyEmail, user } = UserAuth();
  const [menu, setMenu] = useState(false);
  const [error, setError] = useState("");
  const [sent, setSent] = useState("");

  const navigate = useNavigate();
  let userID = "";
  if (user) {
    userID = user.uid;
  }


  const handleVerify = async () => {
    await verifyEmail(user);
    setSent("new link has been sent!");
  };

  const handleCheck = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (user.emailVerified === true) {
      navigate("/");
      setError("");
    } else {
      setError("Couldn't confirm verification. Please try again.");
    }
  }, [user]);

  return (
    <>
      {user.emailVerified === true ? (
        navigate("/")
      ) : userID ? (
        <>
          <div className="login-background">
            {/* <Navbar menu={menu} setMenu={setMenu} /> */}
            {/* <Navbar Link={Link} /> */}
            <div className="login-page">
              <div className="verify-area">
                <div className="verify-text">
                  Please check your emails and click our verification link to
                  continue
                </div>
                <div className="sent">{sent}</div>

                <div className="form-btn2" onClick={handleVerify}>
                  Resend verification link
                </div>
                <div className="error5">{error}</div>
                <div className="form-btn" onClick={handleCheck}>
                  I have verified{" "}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loading-loader"></div>
      )}
    </>
  );
};

export default Verify;
