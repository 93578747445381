import React, { useState, useEffect, useRef } from "react";
import ImageAi from "./ImageAi";
import PexelSearch from "./PexelSearch";
import { UserAuth } from "../context/AuthContext";

import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  query,
  orderBy,
  doc,
} from "firebase/firestore";

const Images = ({
  section1,
  setSection1,
  images,
  insertImage,
  setPanel,
  autoImage,
  setAutoImage,
  aiImages,
  setAiImages,
  imageRef,
  db,
  template,
}) => {
  const { user } = UserAuth();
  let uid = user.uid;
  ////States///////
  const [prompt, setPrompt] = useState("");
  const [image, setImage] = useState("");
  const [oldImages, setOldImages] = useState([]);
  const [isHovering, setIsHovering] = useState(false);

  const [ai, setAi] = useState(true);
  const [stock, setStock] = useState(false);

  const initialRender = useRef(true);

  /////tab functions///////
  const aiTab = () => {
    setAi(true);
    setStock(false);
  };

  const stockTab = () => {
    setAi(false);
    setStock(true);
  };
  /////////////

  const aiImage = async () => {
    if (prompt.length < 5) {
      alert("Please add a more descriptive prompt");
    } else {
      try {
        // Make a request to the Firebase function to fetch data from the OpenAI API
        const response = await fetch(
          `https://us-central1-cruncher-ai-app.cloudfunctions.net/aiImage?promp=${prompt}`
        );
        const data = await response.json();
        let url = data.data[0].url;

        setImage(url);
      } catch (error) {
        alert(
          `There was an error process your request, please try again after some time.`
        );
        console.error(error);
      }
    }
  };

  //////////
  const deleteImage = async (url) => {
    const querySnapshot1 = await getDocs(imageRef);

    //////-----GET AIRETURNS DOCS------//////
    querySnapshot1.forEach((data) => {
      let docRef = doc(imageRef, data.id);

      if (data.data().url === url) {
        deleteDoc(docRef);
      }
    });
  };

  ///////Get USers AI Images///////////

  useEffect(() => {
    let isMounted = true;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // Create the snapshot listener
      const unsubscribe = onSnapshot(imageRef, (snapshot) => {
        let data1 = [];
        snapshot.docs.forEach((doc) => {
          if (doc.id !== "featured") {
            data1.push({ ...doc.data(), id: doc.id });
          }
        });
        if (isMounted) {
          setOldImages(data1);
        }
      });

      // Return the unsubscribe function to detach the listener
      return () => {
        isMounted = false;
        unsubscribe();
      };
    }
  }, [images]);
  ////////////////////////////////

  useEffect(() => {
    if (template === "true") {
      setAiImages(true);
    }
  }, []);

  return (
    <div className="panel-images">
      {/* <div className="gen-top"> */}

      <div className="out-tabs">
        <div className={ai ? "tab-focus" : "tab"} onClick={aiTab}>
          AI Images
        </div>{" "}
        <div className={stock ? "tab-focus" : "tab"} onClick={stockTab}>
          {" "}
          Stock Images
        </div>
      </div>
      {/* </div> */}

      <div className={ai ? "sub" : "hide"}>
        <button
          className="prompt-btn"
          onClick={() => {
            setAiImages(true)
            setSection1(!section1);
          }}
        >
          <img
            src="http://sparker.ai/wp-content/uploads/2023/03/popup.png"
            width="15px"
            align="left"
            alt=""
          />
          <span>Enter prompt</span>
        </button>
        <div className="saved-images">saved images</div>
        <div className="image-list">
          {oldImages.length > 0 &&
            oldImages.map((data, i) => {
              return (
                <div
                  className="image-item"
                  key={i}
                  id={i}
                  onMouseEnter={() => setIsHovering(i)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <img
                    src={`${data?.hostedUrl}`}
                    width="100%"
                    height="100%"
                    className="ai-image"
                    // alt={prompt}
                    onClick={() => insertImage(data?.hostedUrl)}
                  />
                  {isHovering === i && (
                    <div className="ai-overlay2">
                      <img
                        className="ai-insert"
                        src="http://sparker.ai/wp-content/uploads/2023/03/icons2.png"
                        width="20px"
                        onClick={() => {
                          insertImage(data?.url);
                        }}
                      />

                      <img
                        className="ai-delete"
                        src="http://sparker.ai/wp-content/uploads/2023/03/icons2-2.png"
                        width="20px"
                        onClick={() => {
                          deleteImage(data?.url);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className={stock ? "sub" : "hide"}>
        <PexelSearch
          setPanel={setPanel}
          insertImage={insertImage}
          autoImage={autoImage}
          setAutoImage={setAutoImage}
        />
      </div>
    </div>
  );
};

export default Images;
