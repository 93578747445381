import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const SubHeadings3 = ({
  websize,
  aisize,
  wordCount,
  setNewName,
  subheadings,
  setSubheadings,
  data,
  panel,
  newName,
  readyToWrite,
}) => {
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;
  //---------------------------------//

  let maintopic = "";
  let prompt = "";

  if (data && data.mainTopic) {
    maintopic = data.mainTopic.value;
  }

  if (panel === 3) {
    prompt = `I'm writing a step by step tutorial on ${maintopic}. Give me a numbered list of headings which represent steps on ${maintopic}. Write atleast 10 numbered headings and preferrably more:`;
  } else if (panel === 6) {
    prompt = `I'm writing a product review on ${maintopic}. Give me a numbered list of headings for this review. Write atleast 10 numbered headings and preferrably more:`;
  }

  const subheadingsAI = async (text) => {
    if (maintopic === "") {
      return alert("Please add some info in the outline to get suggestions");
    } else {
      let resp;
      setLoading(true);
      try {
        // Make a request to the Firebase function to fetch data from the OpenAI API
        const response = await fetch(
          `https://us-central1-cruncher-ai-app.cloudfunctions.net/fromAI?promp=${prompt}&u=${uid}&i=${id}`,
          {
            mode: "cors",
          }
        );
        const data = await response.json();

        if (data.model === "chat") {
          resp = data.data.choices[0].message.content;
        } else if (data.model === "davinci3" || data.model === "davinci2") {
          resp = data.data.choices[0].text;
        }

        // resp = data.choices[0].text;
        setLoading(false);
      } catch (error) {
        alert(`something went wrong`);
        setLoading(false);
      }
      // return response;

      let data3 = resp.split("\n");

      data3.splice(0, 1);
      for (var i = 0; i < data3.length; i++) {
        data3[i] = data3[i].replace(/^\d+\.\s/, "");
      }

      const obj = [];
      data3.forEach((elem, i) => {
        if (i > 0) {
          obj[i] = {
            value: elem,
          };
        }
      });

      const filteredArray = obj.filter(
        (item) => item !== null && item.value !== ""
      );

      let obj1 = obj.filter((item) => item && item.value);

      setSubheadings(obj1);
    }
  };

  useEffect(() => {
    if (subheadings.length > 99) {
      const numItemsToRemove = subheadings.length - 99;
      setSubheadings((prevData) => prevData.slice(0, -numItemsToRemove));
    }
  }, [subheadings]);
  //------------------------------//
  const handleChange = (index, event) => {
    const values = [...subheadings];
    values[index].value = event.target.value;
    setSubheadings(values);
  };

  // const handleAddField = () => {
  //   setSubheadings([...subheadings, { value: "" }]);
  // };
  const handleAddField = () => {
    if (subheadings.length > 9) {
      alert("Maximum item limit reached");
      return;
    }
    setSubheadings([...subheadings, { value: "" }]);
  };

  const handleRemoveField = (index) => {
    if (subheadings.length > 1) {
      const values = [...subheadings];
      values.splice(index, 1);
      setSubheadings(values);
    }
  };

  //--------------------------------//
  const handleMoveUp = (index) => {
    if (index > 0) {
      const newSubheadings = [...subheadings];
      newSubheadings.splice(index - 1, 0, newSubheadings.splice(index, 1)[0]);
      setSubheadings(newSubheadings);
    }
  };

  const handleMoveDown = (index) => {
    if (index < subheadings.length - 1) {
      const newSubheadings = [...subheadings];
      newSubheadings.splice(index + 1, 0, newSubheadings.splice(index, 1)[0]);
      setSubheadings(newSubheadings);
    }
  };
  //---------------------------//

  const clearList = () => {
    setSubheadings([{ value: "" }]);
  };

  //--------------------------------//

  //-------------------------------//

  return (
    <div>
      <div className={loading === true ? "overlay2" : "hide"}>
        <div className="container">
          <span className="one"></span>
          <span className="two"></span>
          <span className="three"></span>
          <span className="four"></span>
        </div>
        <div className="five">thinking..</div>
      </div>
      <div className="list-scroll">
        <div className="gen-area">
          <button className="sug-sub" onClick={subheadingsAI}>
            {" "}
            Suggest Subheadings
          </button>

          <div
            className="tool-tip"
            data-tooltip="Enter a concise topic heading which will be used by our AI to generate a list of suitable subheadings."
          >
            ?
          </div>
          <button
            disabled={maintopic.length < 6}
            className="ready-to-write"
            onClick={readyToWrite}
          >
            ✓ Ready to Write
          </button>
        </div>
        {subheadings.map((subheading, index) => (
          <div className="list-color2" key={index}>
            <div className="list-area">
              <div className="list-item">
                <div onClick={() => handleMoveUp(index)}>ᐱ</div>

                <div onClick={() => handleMoveDown(index)}>ᐯ</div>
              </div>
              <textarea
                className="list-text"
                value={subheading.value}
                onChange={(event) => handleChange(index, event)}
              />

              <button
                className="remove-item"
                type="button"
                onClick={() => handleRemoveField(index)}
              >
                x
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="outline-btm-btns">
        <button className="add-item-btn" type="button" onClick={handleAddField}>
          Add field
        </button>
        <button
          className="clear-list-btn"
          onClick={() => {
            clearList();
          }}
        >
          Clear List
        </button>
      </div>
    </div>
  );
};

export default SubHeadings3;
