import React, { useState, useEffect, useRef } from "react";
import KeywordDisplay from "./KeywordDisplay";
import { useParams, Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  doc,
  getDoc,
  collection,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import Navbar from "./Navbar";

const Niches = ({ db }) => {
  const { id } = useParams();

  const { user } = UserAuth();

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const [menu, setMenu] = useState(false);

  const [suggestions, setSuggestions] = useState(false);

  const [manual, setManual] = useState(false);

  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState(""); // New state variable for filter text

  const [nichesData, setNichesData] = useState(null);
  const [filteredKeywordsCount, setFilteredKeywordsCount] = useState(0);
  const [mostCommonKeywords, setMostCommonKeywords] = useState([]); // New state for most common keywords

  const [editingName, setEditingName] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null); // Selected keyword details

  const nameInputRef = useRef(null);
  const keywordsInputRef = useRef(null);

  function generateRandomId() {
    const length = 7;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  useEffect(() => {
    // Fetch the niche data from Firestore
    const fetchNichesData = async () => {
      try {
        const nicheSnapshot = await getDoc(
          doc(db, `users/${userID}/niches`, id)
        );
        if (nicheSnapshot.exists()) {
          setNichesData(nicheSnapshot.data());
        } else {
          console.log("Niche document does not exist.");
        }
      } catch (error) {
        console.error("Error fetching niche data:", error);
      }
    };

    fetchNichesData();
  }, [db, userID, id, manual, suggestions]);

  const [keywords, setKeywords] = useState();

  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const toggleSelectKeyword = (keyword) => {
    setSelectedKeywords((prevSelected) =>
      prevSelected.some((kw) => kw.keyword === keyword)
        ? prevSelected.filter((kw) => kw.keyword !== keyword)
        : [
            ...prevSelected,
            { keyword, status: "unassigned", method: `suggestions` },
          ]
    );
  };

  // Function to handle status update when a keyword is clicked

  //   const handleKeywordClick = async (keywordId, currentStatus) => {
  //     let newStatus;
  //     switch (currentStatus) {
  //       case "unassigned":
  //         newStatus = "pending";
  //         break;
  //       case "pending":
  //         newStatus = "completed";
  //         break;
  //       case "completed":
  //         newStatus = "rejected";
  //         break;
  //       case "rejected":
  //         newStatus = "unassigned";
  //         break;
  //       default:
  //         newStatus = "unassigned";
  //     }

  //     try {
  //       // Update the status in Firestore
  //       await updateDoc(
  //         doc(db, `users/${userID}/niches/${id}`),
  //         {
  //           [`keywords.${keywordId}.status`]: newStatus,
  //         },
  //         { merge: true }
  //       );

  //       // Update the state locally to reflect the change
  //       setNichesData((prevData) => ({
  //         ...prevData,
  //         keywords: prevData.keywords.map((keyword, index) =>
  //           index === keywordId ? { ...keyword, status: newStatus } : keyword
  //         ),
  //       }));
  //     } catch (error) {
  //       console.error("Error updating keyword status:", error);
  //     }
  //   };

  //   const toggleSelectKeyword2 = (keyword) => {
  //     setNichesData((prevData) => {
  //       const updatedKeywords = prevData.keywords.map((kw) => {
  //         if (kw.keyword === keyword) {
  //           let classNumberMatch = kw.status.match(/selected-keyword(\d+)/);
  //           let classNumber = 1;
  //           if (classNumberMatch) {
  //             classNumber = parseInt(classNumberMatch[1]);
  //             classNumber = (classNumber % 4) + 1;
  //           }
  //           const nextStatus = `selected-keyword${classNumber}`;
  //           kw.status = nextStatus;
  //         }
  //         return kw;
  //       });
  //       return { ...prevData, keywords: updatedKeywords };
  //     });
  //   };

  console.log(selectedKeywords);

  const handleAddSelected = async () => {
    const allKeywordsObjects = selectedKeywords.map((keyword) => ({
      keyword: keyword.keyword,
      status: "unassigned",
      method: `suggestions`,
      time: new Date().toISOString(),
      id: generateRandomId(),
    }));

    try {
      const nicheRef = doc(db, `users/${userID}/niches`, id);
      const nicheSnapshot = await getDoc(nicheRef);
      const existingKeywords = nicheSnapshot.data()?.keywords || [];

      // Combine the existing keywords with the newly added keywords
      const allKeywords = [...existingKeywords, ...allKeywordsObjects];

      // Update the 'keywords' field in the Firestore document
      await updateDoc(nicheRef, {
        keywords: allKeywords,
      });
      console.log("All keywords added to Firestore successfully!");
    } catch (error) {
      console.error("Error adding all keywords to Firestore:", error);
    }
    // Set the selected keyword details
    setSuggestions(false);
  };

  const addAll = async () => {
    const allKeywordsObjects = keywords.map((keyword) => ({
      keyword,
      status: "unassigned",
      method: `suggestions`,
      time: new Date().toISOString(),
      id: generateRandomId(),
    }));

    try {
      const nicheRef = doc(db, `users/${userID}/niches`, id);
      const nicheSnapshot = await getDoc(nicheRef);
      const existingKeywords = nicheSnapshot.data()?.keywords || [];

      // Combine the existing keywords with the newly added keywords
      const allKeywords = [...existingKeywords, ...allKeywordsObjects];

      // Update the 'keywords' field in the Firestore document
      await updateDoc(nicheRef, {
        keywords: allKeywords,
      });
      console.log("All keywords added to Firestore successfully!");
    } catch (error) {
      console.error("Error adding all keywords to Firestore:", error);
    }
    // Set the selected keyword details
    setSuggestions(false);
  };

  const [inputValue, setInputValue] = useState("");

  const flattenObject = (obj) => {
    let result = [];
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        result.push(obj[key]);
      } else if (Array.isArray(obj[key])) {
        result = result.concat(obj[key]);
      } else {
        result = result.concat(flattenObject(obj[key]));
      }
    }

    console.log(result);
    return result;
  };

  const keywordSuggestions = async (search) => {
    setKeywords();
    setLoading(true);
    console.log(`search Net started`);

    let response;
    try {
      response = await fetch(
        `https://us-central1-cruncher-ai-app.cloudfunctions.net/keywords?search=${search}&u=${userID}`,
        {
          mode: "cors",
        }
      );
    } catch (error) {
      alert(`something went wrong`);
    }

    const data = await response.json();

    const keywordData = JSON.parse(data);

    console.log(data);

    console.log(keywordData);

    const data1 = flattenObject(keywordData);

    setLoading(false);

    return setKeywords(data1);
  };

  const handleSubmit = () => {
    keywordSuggestions(inputValue);
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setNichesData((prevData) => ({ ...prevData, name: newName }));
  };

  const handleNameBlur = async () => {
    try {
      // Save the edited name to Firestore
      const nicheRef = doc(db, `users/${userID}/niches`, id);
      await updateDoc(nicheRef, {
        name: nichesData.name,
      });
      console.log("Niche name updated in Firestore successfully!");
    } catch (error) {
      console.error("Error updating niche name in Firestore:", error);
    }
  };

  const handleNameClick = () => {
    setEditingName(true);
  };

  const handleNameKeyUp = (event) => {
    if (event.key === "Enter") {
      // When the Enter key is pressed, save the edited name
      nameInputRef.current.blur();
    }
  };

  const handleAddKeywords = async () => {
    const keywordsInputValue = keywordsInputRef.current.value;
    const keywordsArray = keywordsInputValue
      .split(/[\n,]+/)
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword !== "");

    const newKeywords = keywordsArray.map((keyword) => ({
      keyword,
      status: "unassigned",
      method: "manual",
      time: new Date().toISOString(),
      id: generateRandomId(),
    }));
    // setSelectedKeywords((prevSelected) => [...prevSelected, ...newKeywords]);

    try {
      // Get the current keywords in the Firestore document
      const nicheRef = doc(db, `users/${userID}/niches`, id);
      const nicheSnapshot = await getDoc(nicheRef);
      const existingKeywords = nicheSnapshot.data()?.keywords || [];

      // Combine the existing keywords with the newly added keywords
      const allKeywords = [...existingKeywords, ...newKeywords];

      // Update the 'keywords' field in the Firestore document
      await updateDoc(nicheRef, {
        keywords: allKeywords,
      });

      console.log("Keywords added to Firestore successfully!");
    } catch (error) {
      console.error("Error adding keywords to Firestore:", error);
    }

    // Clear the input box
    keywordsInputRef.current.value = "";
    setManual(false);
  };
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const calculateKeywordFrequency = () => {
    const keywordFrequency = {};
    const specificWords = ["how", "where", "what", "why", "vs", "best", "top"];
    nichesData.keywords.forEach((keywordObj) => {
      const keyword = keywordObj.keyword.toLowerCase();
      const words = keyword.split(/\s+/); // Split the keyword into words
      words.forEach((word) => {
        if (
          word.length >= 3 &&
          word !== "the" &&
          word !== "and" &&
          (specificWords.includes(word) || word.match(/^(how|where|what|why)/))
        ) {
          keywordFrequency[word] = (keywordFrequency[word] || 0) + 1;
        }
      });
    });

    // Sort keywords by frequency in descending order
    const sortedKeywords = Object.keys(keywordFrequency).sort(
      (a, b) => keywordFrequency[b] - keywordFrequency[a]
    );

    // Include the top 5 words based on frequency and add any specificWords that are present
    const topWords = sortedKeywords.slice(0, 5);
    specificWords.forEach((specificWord) => {
      if (!topWords.includes(specificWord)) {
        topWords.push(specificWord);
      }
    });

    return topWords;
  };

  const handleCommonKeywordClick = (commonKeyword) => {
    setFilterText(commonKeyword);
  };

  useEffect(() => {
    // Update the filtered keywords count whenever the filter text or keywords data changes
    if (nichesData && nichesData.keywords) {
      const count = nichesData?.keywords?.filter((keyword) =>
        keyword.keyword.toLowerCase().includes(filterText.toLowerCase())
      ).length;
      setFilteredKeywordsCount(count);

      // Calculate the most common keywords and set them in state
      const mostCommonKeywords = calculateKeywordFrequency();
      setMostCommonKeywords(mostCommonKeywords);
    }
  }, [filterText, nichesData]);

  //////////-------------SORT KEYWORDS----------////////////
  const [sortOption, setSortOption] = useState("time"); // Default sorting option
  const [sortDirection, setSortDirection] = useState("asc"); // Default sorting direction
  const [showStatus, setShowStatus] = useState("all"); // Default show status option

  // Function to handle the dropdown selection for sorting options
  const handleSortOptionChange = (event) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);
  };

  // Function to handle the dropdown selection for sorting direction
  const handleSortDirectionChange = (event) => {
    const selectedDirection = event.target.value;
    setSortDirection(selectedDirection);
  };

  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  // Function to handle the dropdown selection for show status option
  const handleShowStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setShowStatus(selectedStatus);
  };

  const statusOrder = {
    unassigned: 1,
    pending: 2,
    completed: 3,
    rejected: 4,
  };
  // Sorting function based on the current sorting option and direction
  const sortKeywords = () => {
    let sortedKeywords = nichesData?.keywords?.slice(); // Make a copy to avoid mutation
    switch (sortOption) {
      case "alphabetical":
        sortedKeywords?.sort((a, b) =>
          sortDirection === "asc"
            ? a.keyword?.localeCompare(b.keyword)
            : b.keyword?.localeCompare(a.keyword)
        );
        break;
      case "length":
        sortedKeywords?.sort((a, b) =>
          sortDirection === "asc"
            ? a.keyword.length - b.keyword.length
            : b.keyword.length - a.keyword.length
        );
        break;
      case "time":
        sortedKeywords?.sort((a, b) =>
          sortDirection === "asc"
            ? new Date(a.time) - new Date(b.time)
            : new Date(b.time) - new Date(a.time)
        );
        break;
      case "method":
        sortedKeywords?.sort((a, b) =>
          sortDirection === "asc"
            ? a.method?.localeCompare(b.method)
            : b.method?.localeCompare(a.method)
        );
        break;
      case "status":
        // const statusOrder = {
        //   unassigned: 1,
        //   pending: 2,
        //   completed: 3,
        //   rejected: 4,
        // };
        sortedKeywords?.sort((a, b) =>
          sortDirection === "asc"
            ? statusOrder[a.status] - statusOrder[b.status]
            : statusOrder[b.status] - statusOrder[a.status]
        );
        break;
      default:
        // Default to sorting by status if no valid option is selected
        sortedKeywords?.sort((a, b) =>
          sortDirection === "asc"
            ? statusOrder[a.status] - statusOrder[b.status]
            : statusOrder[b.status] - statusOrder[a.status]
        );
    }
    return sortedKeywords;
  };

  // Filtering function based on the show status option
  const filterKeywordsByStatus = (keywords) => {
    if (showStatus === "all") {
      return keywords;
    } else {
      return keywords.filter((keyword) => keyword.status === showStatus);
    }
  };

  // Apply filtering and sorting based on selected options
  const sortedAndFilteredKeywords = filterKeywordsByStatus(sortKeywords());

  // Function to handle status update when a keyword is clicked
  //   const handleKeywordClick = async (keywordId, currentStatus) => {
  //     let newStatus;
  //     switch (currentStatus) {
  //       case "unassigned":
  //         newStatus = "pending";
  //         break;
  //       case "pending":
  //         newStatus = "completed";
  //         break;
  //       case "completed":
  //         newStatus = "rejected";
  //         break;
  //       case "rejected":
  //         newStatus = "unassigned";
  //         break;
  //       default:
  //         newStatus = "unassigned";
  //     }

  //     try {
  //       // Fetch the entire niches data
  //       const nicheSnapshot = await getDoc(doc(db, `users/${userID}/niches`, id));
  //       if (nicheSnapshot.exists()) {
  //         const nichesData = nicheSnapshot.data();

  //         // Update the specific keyword's status
  //         nichesData.keywords[keywordId].status = newStatus;

  //         // Update the keywords array in Firestore
  //         await updateDoc(doc(db, `users/${userID}/niches/${id}`), {
  //           keywords: nichesData.keywords,
  //         });

  //         // Update the state locally to reflect the change
  //         setNichesData({ ...nichesData });
  //       } else {
  //         console.log("Niche document does not exist.");
  //       }
  //     } catch (error) {
  //       console.error("Error updating keyword status:", error);
  //     }
  //   };

  const handleKeywordClick = async (keywordId, currentStatus, keyword) => {
    let newStatus;
    switch (currentStatus) {
      case "unassigned":
        newStatus = "pending";
        break;
      case "pending":
        newStatus = "completed";
        break;
      case "completed":
        newStatus = "rejected";
        break;
      case "rejected":
        newStatus = "unassigned";
        break;
      default:
        newStatus = "unassigned";
    }

    try {
      // Fetch the entire niches data
      const nicheSnapshot = await getDoc(doc(db, `users/${userID}/niches`, id));
      if (nicheSnapshot.exists()) {
        const nichesData = nicheSnapshot.data();

        // Find the keyword with the matching keywordId
        const updatedKeywords = nichesData.keywords.map((keyword) =>
          keyword.id === keywordId ? { ...keyword, status: newStatus } : keyword
        );

        // Update the keywords array in Firestore
        await updateDoc(doc(db, `users/${userID}/niches/${id}`), {
          keywords: updatedKeywords,
        });

        // Update the state locally to reflect the change
        setNichesData({ ...nichesData, keywords: updatedKeywords });
      } else {
        console.log("Niche document does not exist.");
      }
    } catch (error) {
      console.error("Error updating keyword status:", error);
    }
    setSelectedKeyword({
      keyword: keyword.keyword,
      id: keyword.id,
      time: new Date(keyword.time).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use 24-hour format
      }),
      method: keyword.method,
      status: newStatus,
    });
  };

  return (
    <>
      <Navbar db={db} menu={menu} setMenu={setMenu} Link={Link} />
      <div className="niche-folder">
        <div className="niche-header-container">
          {nichesData && (
            <div className="niche-header">
              {" "}
              <span className="niche-h2">Niche:</span>{" "}
              <span className="niche-span">
                <input
                  ref={nameInputRef}
                  type="text"
                  value={nichesData.name}
                  readOnly={!editingName}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  onClick={handleNameClick}
                  onKeyUp={handleNameKeyUp}
                />{" "}
              </span>
            </div>
          )}
          <div className="flex">
            <div
              className="add-suggestions"
              onClick={() => {
                setSuggestions(true);
              }}
            >
              Get Keyword Suggestions
            </div>
            <div
              className="add-suggestions"
              onClick={() => {
                setManual(true);
              }}
            >
              Add Keywords Manually
            </div>
          </div>
        </div>
        <div className="filter-tabs">
          <input
            className="filter-keywords"
            type="text"
            value={filterText}
            onChange={handleFilterChange}
            placeholder="Filter keywords"
          />
          <div className="sort-keywords">
            <label>
              Show
              <select value={showStatus} onChange={handleShowStatusChange}>
                <option value="all">All</option>
                <option value="unassigned">Unassigned</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="rejected">Rejected</option>
              </select>
            </label>
            <label>
              Sort By
              <select value={sortOption} onChange={handleSortOptionChange}>
                <option value="unassigned">unassigned</option>
                <option value="alphabetical">Alphabetical</option>
                <option value="length">Length</option>
                <option value="time">Time</option>
                <option value="method">Method</option>
                <option value="status">Status</option>
              </select>
            </label>
            {/* <label>
            Sort Direction:
            <select value={sortDirection} onChange={handleSortDirectionChange}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </label> */}
            <span>
              <span style={{ cursor: "pointer" }} onClick={toggleSortDirection}>
                {sortDirection === "asc" ? "▲" : "▼"}
              </span>
            </span>
          </div>
          {/* <div>
          {mostCommonKeywords.map((commonKeyword, index) => (
            <span
              key={index}
              className="common-keyword"
              onClick={() => handleCommonKeywordClick(commonKeyword)}
            >
              {commonKeyword}
            </span>
          ))}
        </div> */}
        </div>
        <div className="suggestions-results2">
          total keywords: {filteredKeywordsCount}
        </div>

        {/* <div className="keywords-container">
        {nichesData &&
          nichesData?.keywords &&
          nichesData?.keywords
            .filter((keyword) =>
              keyword?.keyword.toLowerCase().includes(filterText.toLowerCase())
            )
            .map((keyword, index) => (
              <div
                className={`keyword ${keyword?.status}`}
                key={index}
                onClick={() => handleKeywordClick(index, keyword.status)}
              >
                {keyword?.keyword}
              </div>
            ))}
      </div> */}

        <div className="keywords-container">
          {sortedAndFilteredKeywords?.map((keyword, index) => (
            <div
              className={`keyword ${keyword.status}`}
              key={index}
              onClick={() =>
                handleKeywordClick(keyword.id, keyword.status, keyword)
              }
            >
              {keyword.keyword}
            </div>
          ))}
        </div>
        {selectedKeyword && (
          <div className="selected-keyword-details">
            <div className="selected-keyword-name">
              {selectedKeyword.keyword}
            </div>
            <div>
              <b>Added:</b> {selectedKeyword.time}
            </div>
            <div>
              <b>Method:</b> {selectedKeyword.method}
            </div>
            <div>
              <b>Status:</b> {selectedKeyword.status}
            </div>
          </div>
        )}
        {/* <div className="keywords-container">
        {nichesData &&
          nichesData.keywords &&
          nichesData.keywords.map((keyword, index) => (
            <div
              className={`keyword-suggestions ${
                selectedKeywords.some((kw) => kw.keyword === keyword.keyword)
                  ? "selected-keyword"
                  : ""
              }`}
              key={index}
              onClick={() => toggleSelectKeyword(keyword.keyword)}
            >
              {keyword.keyword}
            </div>
          ))}
      </div> */}

        <div className={manual ? "suggestions-box-container" : "hide"}>
          <div
            className="close"
            onClick={() => {
              setManual(false);
            }}
          >
            close
          </div>
          <textarea
            ref={keywordsInputRef}
            placeholder="Add keywords (comma-separated or on new lines)"
          />
          <button className="add-selected" onClick={handleAddKeywords}>
            Add Keywords
          </button>
        </div>

        <div className={suggestions ? "suggestions-box-container" : "hide"}>
          <div
            className="close"
            onClick={() => {
              setSuggestions(false);
            }}
          >
            close
          </div>
          <div className="suggestions-box">
            <div style={{ display: "flex" }}>
              <div className="suggestions-search-container">
                <input
                  className="suggestions-search"
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Enter keyword"
                />
                <button className="get-suggestions" onClick={handleSubmit}>
                  Get Suggestions
                </button>
              </div>
            </div>
            <div
              className={
                keywords && keywords.length > 0 ? "suggestions-results" : "hide"
              }
            >
              {" "}
              total keywords: <span>{keywords && keywords.length}</span>
            </div>
            <div
              className={
                selectedKeywords && selectedKeywords.length > 0
                  ? "suggestions-results"
                  : "hide"
              }
            >
              {" "}
              selected keywords:{" "}
              <span>{selectedKeywords && selectedKeywords.length}</span>
            </div>
            {keywords ? (
              <div className="keywords-container">
                {keywords &&
                  keywords.map((keyword, index) => (
                    <div
                      className={`keyword-suggestions ${
                        selectedKeywords.some((kw) => kw.keyword === keyword)
                          ? "selected-keyword"
                          : ""
                      }`}
                      key={index}
                      onClick={() => toggleSelectKeyword(keyword)}
                    >
                      {keyword}
                    </div>
                  ))}
              </div>
            ) : loading ? (
              <div className="social-progress">
                <div className="social-loader"></div>
                <div className="social-updates">
                  fetching keyword suggestions from search engines..
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={keywords ? "add-keywords-container" : "hide"}>
              <button className="add-selected" onClick={handleAddSelected}>
                Add Selected
              </button>
              <button className="add-selected" onClick={addAll}>
                Add All
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Niches;
