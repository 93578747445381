import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from "firebase/storage";


//still need to hide api in env obj//

const firebaseConfig = {
  apiKey: "AIzaSyAwjZazuOmS8UY4G4Z6ejmNcLPAyhspKEQ",
  authDomain: "cruncher-ai-app.firebaseapp.com",
  projectId: "cruncher-ai-app",
  storageBucket: "cruncher-ai-app.appspot.com",
  messagingSenderId: "102754476585",
  appId: "1:102754476585:web:14e4460d4aef3081cbf238",
  measurementId: "G-ETXV8CKHV8"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);

// export ​​const auth = getAuth(app);
