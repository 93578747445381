import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";

import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import html2canvas from "html2canvas";
// import { toPng } from "html-to-image";
import domtoimage from "dom-to-image";

import { storage } from "../firebase-config";

import RichEditor from "./RichEditor";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  updateMetadata,
  getDownloadURL,
} from "firebase/storage";

import EditorButtons from "./EditorButtons";
import LeftPanel from "./LeftPanel";
import { deleteApp } from "firebase/app";
import Conclusion from "./Conclusion";
import Intro from "./Intro";
import CheckRefresh from "./CheckRefresh";
import ProgressMeta from "./ProgressMeta";
import ImageAi from "./ImageAi";
import MyVideo from "../remotion/MyVideo";
import MobileProgress from "./MobileProgress";
const TextArea = ({ db }) => {
  const { id, template } = useParams();
  let ntemplate;
  if (template === "true") {
    ntemplate = 1;
  } else {
    ntemplate = Number(template);
  }
  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const navigate = useNavigate();

  //--------------------useSTATES-------------------//
  const [screenSize, setScreenSize] = useState(false);

  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [research22, setResearch22] = useState();
  const [progress22, setProgress22] = useState(0);

  const [perclass2, setPerclass2] = useState(false);
  const [saved, setSaved] = useState(false);

  const [percent, setPercent] = useState(0);

  const [panel, setPanel] = useState(ntemplate);
  const [data2, setData2] = useState([{}]);
  const [data3, setData3] = useState([{}, {}]);
  const [api, setApi] = useState({});
  const [list, setList] = useState([]);
  const [context, setContext] = useState([]);
  const [topic, setTopic] = useState("");
  const [allApi, setAllApi] = useState([]);
  const [data5, setData5] = useState([{ completion: [{ insert: "\n" }] }]);
  const [delta, setDelta] = useState();
  const [divert, setDivert] = useState(false);
  const [editord, setEditorD] = useState();
  const [editorh, setEditorH] = useState();

  const [write, setWrite] = useState(false);
  const [check, setCheck] = useState(false);

  const [value, setValue] = useState();
  const [string, setString] = useState([]);
  const [subheadings, setSubheadings] = useState([{ value: "" }]);
  const [newName, setNewName] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [outline, setOutline] = useState(true);
  const [sparker, setSparker] = useState(false);

  const [images, setImages] = useState(false);
  const [subhs, setSubhs] = useState(false);
  const [templ, setTempl] = useState(false);
  const [tune, setTune] = useState(false);
  //----------------------------------//
  const [strings, setStrings] = useState([]);
  const stringRef = useRef();
  //--------Slider Tune States--------//
  const [research, setResearch] = useState(20);
  const [research2, setResearch2] = useState(20);
  const [writing, setWriting] = useState(30);
  const [creativity, setCreativity] = useState(75);
  const [linksnum, setLinksnum] = useState(0);
  //--------Toggle Button STATES------//
  const [num, setNum] = useState(false);
  const [bul, setBul] = useState(false);
  const [intro, setIntro] = useState(false);
  const [conc, setConc] = useState(false);
  //----Heading Tag States-----------//
  const [subtopictag, setSubtopictag] = useState("h2");
  const [subheadingtag, setSubheadingtag] = useState("h3");
  //-------------------------------//
  const [autoImage, setAutoImage] = useState(false);
  const [promp2, setPromp2] = useState("");
  const [expected, setExpected] = useState();

  const [rExpected, setrExpected] = useState();
  const [rComplete, setrComplete] = useState(false);
  const [wComplete, setwComplete] = useState(false);

  const [aiImages, setAiImages] = useState(false);
  const [imagePrompt, setImagePrompt] = useState("");

  //------------------------------//
  const [progress, setProgress] = useState(0);
  const [error2, setError2] = useState(false);

  //----------------------------------//

  const [leftPopup, setLeftPopup] = useState(false);
  const [leftPopupMessage, setLeftPopupMessage] = useState("");

  //--------------------------------//
  const [expectedWords, setExpectedWords] = useState();
  const [notEnough, setNotEnough] = useState(false);

  //----------------------------------//

  //---------------------useREFs------------------//
  const editorRef = useRef();
  const editorRef2 = useRef();

  const aisize = useRef();
  const websize = useRef();
  const initialRender = useRef(true);
  const initialRender2 = useRef(true);
  const initialRender4 = useRef(true);
  const quill = useRef(null);
  const count = useRef(0);
  //------------------DATABASE REF---------------//
  const returnsRef = collection(db, `users/${uid}/documents/${id}/aireturns`);
  const imageRef = collection(db, `users/${uid}/documents/${id}/aipics`);
  const errorsRef = collection(db, `users/${uid}/documents/${id}/errors`);

  const serpRef = collection(db, `users/${uid}/documents/${id}/webdata`);
  const weblist = collection(db, `users/${uid}/documents/${id}/weblist`);
  const dupliRef = collection(db, `users/${uid}/documents/${id}/duplicheck`);
  const subTopicRef = collection(db, `users/${uid}/documents/${id}/subTopic`);
  const combinedRef = collection(db, `users/${uid}/documents/${id}/combined`);
  //-------------------QUERY-------------------//
  // const q = query(returnsRef, orderBy("time"));
  const combi = query(combinedRef, orderBy("index"));
  //  const combi = combinedRef;
  //------------------------------------//
  const imagesRef = ref(storage, `images/${uid}-${id}-featured2.png`);

  const featuredRef = doc(db, `users/${uid}/documents/${id}/aipics/featured`);
  const metaRef = doc(db, `users/${uid}/documents/${id}/meta/meta`);

  /////////////////////
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 960) {
  //       // window.location.href = "https://sparker.ai/screen-size-2";
  //       setScreenSize(true);
  //     } else {
  //       setScreenSize(false);
  //     }
  //   };

  //   handleResize(); // check on initial load
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [window]);

  //////////////////////////

  const sparkF = () => {
    setOutline(false);
    setSparker(true);

    setSubhs(false);
    setTempl(false);
    setTune(false);
    setImages(false);
  };

  const outlineF = () => {
    setOutline(true);
    setSparker(false);

    setSubhs(false);
    setTempl(false);
    setTune(false);
    setImages(false);
  };
  ////////////////////

  //////////Available Tokens/////////
  const [availableTokens, setAvailableTokens] = useState();

  const tokensRef = collection(db, `users/${userID}/tokens/`);

  useEffect(() => {
    async function getAvailableTokens() {
      const querySnapshot = await getDocs(tokensRef);

      let sum = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.tokens) {
          sum += data.tokens;
        }
      });
      const availableTokens = (sum / 2000).toFixed(2);

      setAvailableTokens(availableTokens);
    }

    getAvailableTokens();
  }, [userID, tokensRef]);

  //-------------set AI Art template------------//

  //---------------Screen shot------------//

  const captureScreenshot = async () => {
    const element = document.querySelector(".ql-editor");

    // Set the width and height of the canvas to capture a specific portion of the element
    const width = 700;
    // const height = element.offsetHeight * 0.5;
    const height = 748.46;

    // Set the left and top position of the canvas to capture the desired portion of the element
    const left = element.offsetLeft + (700 - width) * 0.08;
    const top = element.offsetTop;

    const canvas = await html2canvas(element, {
      useCORS: true,
      width,
      height,
      left,
      top,
      scale: 0.5,
    });

    const dataURL = canvas.toDataURL();

    const base64String = dataURL.split(",")[1];

    // Upload the file
    const snapshot = await uploadString(imagesRef, base64String, "base64");

    // Get the download URL of the uploaded file
    const downloadURL = await getDownloadURL(snapshot.ref);

    await setDoc(featuredRef, {
      url: downloadURL,
    });
  };

  //------------set Doc Meta------------------------//

  const setTitle = async () => {
    const html = quill.current.getEditor().root.innerHTML;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    let title = "";

    const traverse = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent.trim();
        if (text !== "") {
          if (text.length <= 25) {
            title = text;
          } else {
            title = text.substr(0, 25) + "...";
          }
          return true; // stop traversal
        }
      } else {
        for (const child of node.childNodes) {
          if (traverse(child)) {
            return true; // stop traversal
          }
        }
      }
      return false; // continue traversal
    };

    traverse(doc.body);

    await setDoc(metaRef, {
      title: title,
      last_opened: Date.now(),
    });
  };

  useEffect(() => {
    if (userID && userID.length > 0) {
      setTimeout(() => {
        setTitle();
      }, 2000);
    }

    setPanel(999);
    setTimeout(() => {
      if (template > 0) {
        setPanel(Number(template));
      } else {
        setPanel(1);
      }
    }, 2000);
  }, [userID]);
  //-------------------------------------------------------------------------------//
  ///Check if progress Meta required////
  // const [calc, setCalc] = useState(false);
  let calc;
  if (panel === 1 || panel === 2 || panel === 3 || panel === 4 || panel === 9) {
    calc = true;
  } else {
    calc = false;
  }

  //----------------------------------//

  const [isSection1Expanded, setIsSection1Expanded] = useState(true);

  const handleToggleClick = () => {
    setIsSection1Expanded(!isSection1Expanded);
  };
  // let expected = 0;
  // let rExpected = 0;

  //---------------Save Function------------//

  const saveFunction = async () => {
    captureScreenshot();
    setTitle();
    // setTime();
    const editorContent = quill.current.getEditor().getContents().ops;
    const html = quill.current.getEditor().root.innerHTML;

    let ids = [];
    const querySnapshot1 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/combined`)
    );
    querySnapshot1.forEach((doc) => {
      ids.push(doc.id);
    });
    for (let i = 0; i < ids.length; i++) {
      const docRef = doc(db, `users/${uid}/documents/${id}/combined`, ids[i]);
      deleteDoc(docRef);
    }

    addDoc(collection(db, `users/${uid}/documents/${id}/combined`), {
      completion: editorContent,
      html: html,
      index: 0,
      string: strings.join(", "),
      saved: true,
    });

    setSaved(true);
  };

  //----------------COUNT COLORED WORDS for PLAIGARISM-------//
  function countColoredWords() {
    // Get the Quill instance

    const editorText = quill.current.getEditor().getText();
    if (editorText) {
      let totalChars = editorText.length;

      const html = quill.current.getEditor().root.innerHTML;

      // Use a regular expression to extract the words from the HTML
      const strings = html.match(/<span[^>]*>([^<]*)<\/span>/g);

      // let totalChars = 0;
      let targetChars = 0;

      // Iterate over each string in the array
      if (strings) {
        for (let i = 0; i < strings.length; i++) {
          // Use a regular expression to extract the text between the span tags
          const regex = /<span[^>]*>(.*?)<\/span>/g;
          const match = regex.exec(strings[i]);
          if (match !== null) {
            const text = match[1];
            const backgroundColorRegex =
              /background-color: rgb\((255, 0, 0, 0.5)|(255, 255, 0, 0.5)\)/g;
            if (backgroundColorRegex.test(strings[i])) {
              // Count the characters between the span tags when the background color is rgb(252, 228, 220)
              targetChars += text.length;
            }
            // Count the total characters between the span tags
            // totalChars += text.length;
          }
        }
      }

      // Calculate the percentage of characters between the span tags with background-color: rgb(252, 228, 220)
      const percentage = (targetChars / totalChars) * 100;

      setPercent(percentage.toFixed(1));
    }
  }

  //---------------------------------------------------//
  //--------------Sync with Fine Tune Values------------------//
  ///////////////////DATA OBJ////////////////
  let index = 1;

  let data;
  if (panel === 1 || panel === 4 || panel === 9) {
    let index = 0;
    data = {
      mainTopic: { value: newName, index: 0 },
      subTopics: data2.map((subTopic) => ({
        index: (index += 2), // increment index by 2
        value: subTopic.text,
        subHeadings: subheadings.map((subHeading) => ({
          index: (index += 2), // increment index by 2
          value: subHeading.value,
        })),
      })),
    };
  } else if (panel === 2) {
    data = {
      mainTopic: { value: newName, index: 0 },
      subTopics: data3.map((subTopic) => ({
        index: index++,
        value: subTopic.text,
        subHeadings: subheadings.map((subHeading) => ({
          index: index++,
          value: subHeading.value,
        })),
      })),
    };
  } else if (panel === 3 || panel === 6) {
    data = {
      mainTopic: { value: newName, index: 0 },
      subHeadings: subheadings,
    };
  } else {
    data = {};
  }

  ///////////////////////////FINE CONTROLS//////////////////
  useEffect(() => {
    if (panel === 1) {
      // setIntro(true);
      // setConc(true);
    } else if (panel === 2) {
    } else if (panel === 3) {
    } else if (panel === 4) {
      // setNum(true);
      // setIntro(true);
      // setConc(true);
    } else if (panel === 5) {
    } else if (panel === 6) {
    } else if (panel === 7) {
    } else if (panel === 8) {
    } else if (panel === 9) {
    } else if (panel === 10) {
    } else if (panel === 11) {
    } else if (panel === 12) {
    }
  }, [panel]);

  ////////////////////
  let bullet;

  if (!bul) {
    bullet = "do NOT write in bullet points";
  } else {
    bullet = "write in HTML bullet points";
  }
  ///////////////
  let researchBracket;

  if (research < 3) {
    researchBracket = "3";
  } else if (research < 10) {
    researchBracket = "5";
  } else if (research < 15) {
    researchBracket = "8";
  } else if (research < 45) {
    researchBracket = "10";
  } else if (research < 55) {
    researchBracket = "12";
  } else if (research < 65) {
    researchBracket = "14";
  } else if (research < 75) {
    researchBracket = "16";
  } else if (research < 85) {
    researchBracket = "18";
  } else {
    researchBracket = "20";
  }

  //////////////////
  let researchBracket2;

  if (research2 < 3) {
    researchBracket2 = "3";
  } else if (research2 < 10) {
    researchBracket2 = "5";
  } else if (research2 < 15) {
    researchBracket2 = "8";
  } else if (research2 < 45) {
    researchBracket2 = "10";
  } else if (research2 < 55) {
    researchBracket2 = "12";
  } else if (research2 < 65) {
    researchBracket2 = "14";
  } else if (research2 < 75) {
    researchBracket2 = "16";
  } else if (research2 < 85) {
    researchBracket2 = "18";
  } else {
    researchBracket2 = "20";
  }

  //////////////////////
  let writeBracket;
  let maxWords;
  let tokens;

  if (writing < 21) {
    writeBracket = "120 words";
    maxWords = 120;
  } else if (writing === 100) {
    writeBracket = "300 words";
    maxWords = 300;
  } else {
    maxWords = 40 + (Number(writing) * (300 - 20)) / 100;
    writeBracket = `${Math.ceil(maxWords)} words`;
  }

  if (linksnum === 0) {
    tokens = Math.round(maxWords * 1.8);
  } else if (linksnum === 1 || linksnum === 2) {
    tokens = Math.round(maxWords * 1.8) + 200;
  } else if (linksnum === 3 || linksnum === 4) {
    tokens = Math.round(maxWords * 1.8) + 300;
  }

  ////////

  ////////////////////

  let promp = [];
  let maintopic;
  let subArray = [];
  let subtopics = data2.map((obj) => obj.text);

  if (panel === 2 || panel === 3 || panel === 6) {
    subArray = subheadings
      .filter((item) => item && item.value)
      .map((item) => item.value);

    if (subArray.length === 0) {
      subArray.push("");
    }

    maintopic = data.mainTopic.value;

    for (let i = 0; i < subArray.length; i++) {
      if (panel === 3) {
        let urlPart;

        if (linksnum > 0) {
          urlPart = `I've also given ${linksnum} urls at the end, add these as contextual text links in the body of the draft. The urls are NOT sources or references so DON'T add them as sources at the bottom of the draft.`;
        } else {
          urlPart = "";
        }
        promp.push({
          start: `I want to write a draft which must be in html syntax to use in a html editor. I am writing a tutorial on ${maintopic}. As part of this I'm focusing on writing the step ${subArray[i]}. I've collected some information on this step below. Using this information and your own knowledge write in HTML the step ${subArray[i]} giving it a ${subheadingtag} heading at the top and adding two sentences per paragraph.${bullet}. Wrap paragraphs in <p> tags and each paragraph should ONLY have 2 sentences each. ${urlPart} Write around ${writeBracket}.\n\nInformation on ${subArray[i]}`,
          end: `Write the draft, ${writeBracket}. This must be in html syntax:`,
        });
      }
      if (panel === 6) {
        let urlPart;

        if (linksnum > 0) {
          urlPart = `I've also given ${linksnum} urls at the end, add these as contextual text links in the body of the draft. The urls are NOT sources or references so DON'T add them as sources at the bottom of the draft.`;
        } else {
          urlPart = "";
        }
        promp.push({
          start: `I want to write a draft which must be in html syntax to use in a html editor. I am reviewing ${maintopic}. I'm reviewing it as an independent and neutral reviewer. Currently I'm working on the subheading '${subArray[i]}'. I have written a poor quality draft of ideas. Don't repeat my ideas verbatim and turn this into a high quality and structured draft (in html) giving it a ${subheadingtag} heading at the top and adding two sentences per paragraph. Don't write about other products. ${bullet}. Wrap paragraphs in <p> tags and each paragraph should ONLY have 2 sentences each. ${urlPart} Write around ${writeBracket}.\n\nInformation on ${subArray[i]}`,
          end: `Write the draft, ${writeBracket}.  This must be in html syntax:`,
        });
      }
    }
  }
  if (panel === 9) {
    for (let i = 0; i < subtopics.length; i++) {
      promp.push({
        start: `I want to write a draft which must be in html syntax to use in a html editor. I am writing a Video Script on ${newName}. Currently I'm focusing on a section of the script on '${subtopics[i]}' I've collected some data for this below, using this data  write the script in HTML and adding two sentences per paragraph. The script should be conversationl, be in the present tense and have some humour in it. Write in HTML starting with a h2 topic heading at the top , followed by the actual script. In the script follow a style of <b>Narrator:</b> a short section of what the narrator should say, followed by <b>Graphics:</b> which should suggest some relevant visual aids to use in the video. Add <br><br> tags after the top heading and also in between each Narrator section and Graphics section to clearly separat them. Don't add any <br> tags anywhere else:\n\nData:`,
        end: `Write the Video Script for '${subtopics[i]}'. This must be in html syntax:`,
      });
    }
  }

  // else if (research < 40) {
  //   researchBracket = `${research - 10}`;
  // } else if (research < 50) {
  //   researchBracket = `${research - 5}`;
  // } else if (research) {
  //   researchBracket = `${research}`;
  // }

  ////////////////////
  //--------------SNAPSHOT DATABASE-------------//
  useEffect(() => {
    let isMounted = true;

    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      // Create the snapshot listener
      const unsubscribe = onSnapshot(combi, (snapshot) => {
        let data1 = [];
        snapshot.docs.map((doc) => data1.push({ ...doc.data(), id: doc.id }));
        aisize.current = snapshot.size;
        let strings1 = data1.filter((d) => d.string).map((d) => d.string);
        if (isMounted) {
          setData5(data1);
          setStrings(strings1);
        }
      });

      // Return the unsubscribe function to detach the listener
      return () => {
        isMounted = false;
        unsubscribe();
      };
    }
  }, [panel]);
  //-----------------------------------------//

  //--------------MERGE INTO DELTA----------//
  useEffect(() => {
    if (data5 && data5[0] && data5[0].string && data5[0].string.length > 10) {
      const stringValues = data5.map((item) => item.string);
      setString(stringValues.join(""));
    }

    let mergedArr = [];
    let hasNotdel = false;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      data5.forEach((obj) => {
        if (obj.hasOwnProperty("notdel")) {
          hasNotdel = true;
        }
      });

      let largeArray = []; // this will hold the final array of objects
      if (num) {
        let i = 1;
        for (const obj of data5) {
          if (obj.heading) {
            // largeArray.push({insert: '1'})
            largeArray = [
              ...largeArray,
              { insert: `\n\n\n${i}. ` },
              ...obj.completion,
            ];

            i++;
          } else {
            largeArray = largeArray.concat(obj.completion);
          }
        }
      } else {
        for (const obj of data5) {
          if (obj.heading) {
            // largeArray.push({insert: '1'})
            largeArray = [
              ...largeArray,
              { insert: `\n\n\n` },
              ...obj.completion,
            ];
          } else {
            largeArray = largeArray.concat(obj.completion);
          }
        }
      }

      setDelta(largeArray);
    }
  }, [data5, num]);
  //-------------------------------------------------//

  //-------------Sync editorRef-------------//
  if (
    editord &&
    editord.ops &&
    // editor.ops.length > 1 &&
    editorRef.current !== editord.ops
  ) {
    editorRef.current = editord.ops;
  }
  if (
    editorh &&
    // editor.ops.length > 1 &&
    editorRef2.current !== editorh
  ) {
    editorRef2.current = editorh;
  }

  //-------------addIt Function-------------//
  //---------------------------------------//
  useEffect(() => {
    let isMounted = true;

    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (saved) {
        setTimeout(() => {
          setSaved(false);
        }, 2000);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [saved]);
  //------------------------------------//

  //---------------------------//
  const insertImage = (photo) => {
    const range = quill.current.getEditor().getSelection(true);

    quill.current.getEditor().insertEmbed(range.index, "image", photo);
  };
  //---------------------------//
  const [section1, setSection1] = useState(false);
  const [section2, setSection2] = useState(false);

  const [sparkBtn3Class, setSparkBtn3Class] = useState("hide");
  const [editBtnClass, setEditBtnClass] = useState("hide");
  const [writeBtnClass, setWriteBtnClass] = useState("hide");
  const [progressBtnClass, setProgressBtnClass] = useState("hide");
  const [homeBtnClass, setHomeBtnClass] = useState("hide");
  const [mobProgClass, setMobProgClass] = useState("hide");

  const [section1Style, setSection1Style] = useState();
  const [section2Style, setSection2Style] = useState();

  const [section1Class, setSection1Class] = useState("section1");
  const [section2Class, setSection2Class] = useState("section2");

  useEffect(() => {
    if (window.innerWidth < 960) {
      setSparkBtn3Class("sparker-btn3");
      setEditBtnClass("editor-main-button");
      setWriteBtnClass("write-icon");
      setProgressBtnClass("progress-icon");
      setHomeBtnClass(`home-btn-icon`);

      if (section2) {
        setMobProgClass("progress-message");
      } else {
        setMobProgClass("hide");
      }

      if (section1 === true) {
        setSection1Style({
          transform: "translateY(0%)",
        });
        setSection1Class(`section1`);
        outlineF();
        setSection2(false);
        setSection2Class("hide");
      }

      if (section1 === false) {
        setSection1Style({
          transform: "translateY(100%)",
        });

        setSection2Class("section2");

        setTimeout(() => {
          setSection1Class(`hide`);
        }, 400);
      }
    }
  }, [section1, section2]);

  //------------------//

  const [ready, setReady] = useState(false);

  const readyToWrite = () => {
    console.log(`ready to write function triggered`);
    setSection1(false);
    // setCheck(true);
    setTimeout(() => {
      setCheck(true);
    }, 400);
  };

  // useEffect(() => {
  //   if (ready === true) {
  //     console.log(`ready triggere setCheck`);

  //     setTimeout(() => {
  //       setCheck(true);
  //     }, 400);
  //   }
  // }, [ready]);

  const [showConfetti, setShowConfetti] = useState(false);
  let research344 = (Number(research22) / Number(rExpected)) * 100;

  useEffect(() => {
    if (wComplete) {
      setShowConfetti(true);

      const confettiTimeout = setTimeout(() => {
        setShowConfetti(false);
      }, 5000); // Duration of confetti display (3 seconds)

      return () => clearTimeout(confettiTimeout);
    }
  }, [wComplete]);

  return (
    <>
      {showConfetti && <Confetti className="confetti" />}

      {screenSize ? (
        <div className="screen-size">
          <h2>Your Screen size is too small.</h2> Most mobile phone screens are
          too small to display the app. For a better experience please use the
          app on your laptop, tablet or desktop. We also advise that you
          maximise your borwser window when using the app.
        </div>
      ) : (
        ""
      )}
      {uid ? (
        <>
          {/* <div className="r">Research: {websize.current}</div> */}
          {/* <div className="w">Writing: {aisize.current}</div> */}

          <div className="main">
            <Conclusion
              conc={conc}
              db={db}
              combinedRef={combinedRef}
              combi={combi}
              panel={panel}
              data={data}
              data2={data2}
              data3={data3}
              newName={newName}
              subArray={subArray}
              intro={intro}
              initialRender2={initialRender2}
              subheadingtag={subheadingtag}
              subtopictag={subtopictag}
              setWrite={setWrite}
              write={write}
              setLoading2={setLoading2}
              loading2={loading2}
              autoImage={autoImage}
              editord={editord}
              editorh={editorh}
              strings={strings}
              editorRef={editorRef}
              editorRef2={editorRef2}
              saveFunction={saveFunction}
              countColoredWords={countColoredWords}
              research22={research22}
              setResearch22={setResearch22}
              expected={expected}
              setExpected={setExpected}
              rExpected={rExpected}
              setrExpected={setrExpected}
              progress22={progress22}
              setProgress22={setProgress22}
              writeBracket={writeBracket}
              setExpectedWords={setExpectedWords}
            />

            <button
              className={progressBtnClass}
              // style={
              //   section2
              //     ? { backgroundColor: "#00b395" }
              //     : { backgroundColor: "white" }
              // }
              onClick={() => {
                setSection2(!section2);
              }}
            />
            <button
              className={homeBtnClass}
              onClick={() => {
                navigate("/");
              }}
            />

            <button
              className={sparkBtn3Class}
              onClick={() => {
                setSection1(!section1);
              }}
            />

            <div className={mobProgClass}>
              {section2 && (
                <MobileProgress
                  db={db}
                  wordCount={wordCount}
                  research22={research22}
                  aisize={aisize}
                  percent={percent}
                  expected={expected}
                  rExpected={rExpected}
                  rComplete={rComplete}
                  wComplete={wComplete}
                  setrComplete={setrComplete}
                  setwComplete={setwComplete}
                  write={write}
                  calc={calc}
                  setLoading2={setLoading2}
                  setWrite={setWrite}
                  section2={section2}
                />
              )}
            </div>

            <div className={section1Class} style={section1Style}>
              <LeftPanel
                readyToWrite={readyToWrite}
                check={check}
                setCheck={setCheck}
                setSection1={setSection1}
                section1={section1}
                handleToggleClick={handleToggleClick}
                autoImage={autoImage}
                setAutoImage={setAutoImage}
                insertImage={insertImage}
                data={data}
                panel={panel}
                setPanel={setPanel}
                subheadings={subheadings}
                setSubheadings={setSubheadings}
                sparker={sparker}
                setSparker={setSparker}
                templ={templ}
                images={images}
                outline={outline}
                subhs={subhs}
                tune={tune}
                setOutline={setOutline}
                setImages={setImages}
                setSubhs={setSubhs}
                setTempl={setTempl}
                setTune={setTune}
                db={db}
                setList={setList}
                setContext={setContext}
                topic={topic}
                setTopic={setTopic}
                newName={newName}
                setNewName={setNewName}
                aisize={aisize}
                websize={websize}
                wordCount={wordCount}
                api={api}
                setApi={setApi}
                data2={data2}
                setData2={setData2}
                data3={data3}
                setData3={setData3}
                research={research}
                setResearch={setResearch}
                research2={research2}
                researchBracket2={researchBracket2}
                setResearch2={setResearch2}
                writing={writing}
                setWriting={setWriting}
                creativity={creativity}
                setCreativity={setCreativity}
                linksnum={linksnum}
                setLinksnum={setLinksnum}
                num={num}
                setNum={setNum}
                bul={bul}
                setBul={setBul}
                intro={intro}
                setIntro={setIntro}
                conc={conc}
                setConc={setConc}
                subtopictag={subtopictag}
                subheadingtag={subheadingtag}
                setSubtopictag={setSubtopictag}
                setSubheadingtag={setSubheadingtag}
                setPromp2={setPromp2}
                promp2={promp2}
                aiImages={aiImages}
                setAiImages={setAiImages}
                imageRef={imageRef}
                calc={calc}
                template={template}
                expectedWords={expectedWords}
                sparkF={sparkF}
                availableTokens={availableTokens}
                outlineF={outlineF}
              />
            </div>
            <div className={section2Class} style={section2Style}>
              <RichEditor
                panel={panel}
                combi={combi}
                setPanel={setPanel}
                data={data}
                data3={data3}
                quill={quill}
                value={value}
                setValue={setValue}
                delta={delta}
                setEditorD={setEditorD}
                setEditorH={setEditorH}
                setDivert={setDivert}
                db={db}
                returnsRef={returnsRef}
                write={write}
                string={string}
                wordCount={wordCount}
                setWordCount={setWordCount}
                list={list}
                context={context}
                topic={topic}
                subheadings={subheadings}
                setWrite={setWrite}
                count={count}
                weblist={weblist}
                writeBracket={writeBracket}
                tokens={tokens}
                researchBracket={researchBracket}
                creativity={creativity}
                bullet={bullet}
                subheadingtag={subheadingtag}
                subtopictag={subtopictag}
                linksnum={linksnum}
                bul={bul}
                promp={promp}
                promp2={promp2}
                subArray={subArray}
                intro={intro}
                autoImage={autoImage}
                newName={newName}
                setLoading2={setLoading2}
                loading3={loading3}
                loading2={loading2}
                subtopics={subtopics}
                perclass2={perclass2}
                setPerclass2={setPerclass2}
                setPercent={setPercent}
                percent={percent}
                saved={saved}
                research22={research22}
                aisize={aisize}
                expected={expected}
                rExpected={rExpected}
                rComplete={rComplete}
                setrComplete={setrComplete}
                wComplete={wComplete}
                setwComplete={setwComplete}
                aiImages={aiImages}
                setAiImages={setAiImages}
                insertImage={insertImage}
                imageRef={imageRef}
                setImagePrompt={setImagePrompt}
                imagePrompt={imagePrompt}
                calc={calc}
                saveFunction={saveFunction}
                progress={progress}
                setProgress={setProgress}
                errorsRef={errorsRef}
                error2={error2}
                setError2={setError2}
                captureScreenshot={captureScreenshot}
                setSparker={setSparker}
                sparkF={sparkF}
                availableTokens={availableTokens}
                expectedWords={expectedWords}
                notEnough={notEnough}
                setNotEnough={setNotEnough}
                leftPopup={leftPopup}
                leftPopupMessage={leftPopupMessage}
                setLeftPopup={setLeftPopup}
                setLeftPopupMessage={setLeftPopupMessage}
                outlineF={outlineF}
                setSection1={setSection1}
                section1={section1}
                check={check}
                setCheck={setCheck}
                setSection2={setSection2}
              />
            </div>

            <div className="section3">
              <ProgressMeta
                wordCount={wordCount}
                research22={research22}
                aisize={aisize}
                percent={percent}
                expected={expected}
                rExpected={rExpected}
                rComplete={rComplete}
                wComplete={wComplete}
                setrComplete={setrComplete}
                setwComplete={setwComplete}
                write={write}
                calc={calc}
                setLoading2={setLoading2}
                setWrite={setWrite}
              />
              <EditorButtons
                className="edit-buttons"
                db={db}
                list={list}
                context={context}
                topic={topic}
                subheadings={subheadings}
                weblist={weblist}
                setData5={setData5}
                aisize={aisize}
                websize={websize}
                setDivert={setDivert}
                setWrite={setWrite}
                delta={delta}
                setValue={setValue}
                quill={quill}
                string={string}
                strings={strings}
                dupliRef={dupliRef}
                count={count}
                serpRef={serpRef}
                returnsRef={returnsRef}
                write={write}
                setLoading3={setLoading3}
                loading3={loading3}
                editorRef2={editorRef2}
                combi={combi}
                percent={percent}
                setPercent={setPercent}
                setPerclass2={setPerclass2}
                setSaved={setSaved}
                saved={saved}
                combinedRef={combinedRef}
                panel={panel}
                countColoredWords={countColoredWords}
                setrComplete={setrComplete}
                setwComplete={setwComplete}
                setAiImages={setAiImages}
                setImagePrompt={setImagePrompt}
                setError2={setError2}
                captureScreenshot={captureScreenshot}
                setTitle={setTitle}
                progress22={progress22}
                setProgress22={setProgress22}
                availableTokens={availableTokens}
                expectedWords={expectedWords}
                notEnough={notEnough}
                setNotEnough={setNotEnough}
                setLeftPopup={setLeftPopup}
                check={check}
                setCheck={setCheck}
                setSection2={setSection2}
              />
            </div>

            <Intro
              db={db}
              data2={data2}
              data3={data3}
              combi={combi}
              panel={panel}
              newName={newName}
              subArray={subArray}
              intro={intro}
              initialRender2={initialRender2}
              subheadingtag={subheadingtag}
              subtopictag={subtopictag}
              setWrite={setWrite}
              write={write}
            />
            <CheckRefresh setPanel={setPanel} panel={panel} />
          </div>
          {/* <div className="main">
        <div className="section1"></div>
        <div className="section2">
          <div className="top-bar"></div>
          <div className="middle-content"></div>
        </div>
        <div className="section3"></div>
      </div> */}
        </>
      ) : (
        <div className="loading-loader"></div>
      )}
    </>
  );
};

export default TextArea;
