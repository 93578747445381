import { Player } from "@remotion/player";
import { AbsoluteFill } from "remotion";
import React, { useCallback } from "react";
import Template1 from "../remotion/Template1";

const PlayerContainer = ({
  duration,
  musicUrl,
  playerRef,
  updatedData,
  sceneDurations,
  music,
  resolution,
}) => {
  // const renderLoading = useCallback(({ height, width }) => {
  //   return (
  //     <AbsoluteFill style={{ backgroundColor: "yellow" }}>
  //       Loading player ({height}x{width})
  //     </AbsoluteFill>
  //   );
  // }, []);

  return (
    <>
      <Player
        ref={playerRef}
        component={Template1}
        durationInFrames={duration}
        compositionWidth={resolution.width}
        compositionHeight={resolution.height}
        // renderLoading={renderLoading}
        fps={25}
        controls
        loop
        inputProps={{
          titleText: "Hello World",
          musicUrl,
          updatedData,
          sceneDurations,
          music,
        }}
      />{" "}
    </>
  );
};

export default PlayerContainer;
