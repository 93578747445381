import React, { useRef, useState } from "react";

const CopyToClipboardBox = ({ text }) => {
  const textRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand("copy");
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500); // Reset the state after 1.5 seconds
    }
  };

  return (
    <div className="copy-to-clipboard">
      <textarea ref={textRef} value={text} readOnly />
      <button onClick={copyToClipboard}>{isCopied ? "Copied" : "Copy"}</button>
    </div>
  );
};

export default CopyToClipboardBox;
