import React, { useState } from "react";
import GoogleVoiceUpload from "./GoogVoiceUpload";
import VoiceList from "./VoiceList";

const Voices = ({ voices, setSelectedVoice, selectedVoice }) => {
  return (
    <div>
      <VoiceList
        voices={voices}
        setSelectedVoice={setSelectedVoice}
        selectedVoice={selectedVoice}
      />
    </div>
  );
};

export default Voices;
