import React from 'react'
import PexelVideos from './PexelVideos'

const Videos = ({
  updateVideoUrl,
  updateHdVideos,
  setBulkCheck,
  handleCheckErrors,
}) => {
  return (
    <>
      <PexelVideos
        updateVideoUrl={updateVideoUrl}
        updateHdVideos={updateHdVideos}
        setBulkCheck={setBulkCheck}
        handleCheckErrors={handleCheckErrors}
      />
    </>
  );
};

export default Videos