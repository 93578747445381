import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";

const WriteMessage = ({ writer, check, setCheck, setSparker, sparkF }) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;
  //--------------------//


  //------------------//

  return (
    <>
      <div className={check ? "editor-message" : "hide"}>
        <div
          className="all-templates close-message"
          onClick={() => {
            setCheck(false);
          }}
        >
          close
        </div>
        <div className="check-container">
          <h3>Have you checked everything ?</h3>
          <span>
            Please check the menu panel and make sure you have enough tokens
            before proceeding.
          </span>
        </div>
        <div className="writer-container">
          <button
            className="write-btn"
            // className={write ? "no-write" : "write-btn"}
            onClick={() => {
              writer();
              setCheck(false);
            }}
          >
            <img
              src="http://sparker.ai/wp-content/uploads/2023/01/write-spark-120-×-120-px.png"
              width="15px"
              align="left"
              alt=""
            />
            <span>Start writing</span>
          </button>{" "}
        </div>
      </div>
    </>
  );
};

export default WriteMessage;
