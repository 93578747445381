import React from "react";

const ScenesReel = ({
  sequences,
  handleDivClick,
  viewportRef,
  selectedDiv,
  images,
  updatedData,
  errorIndexes,
}) => {
  const handleScrollLeft = () => {
    viewportRef.current.scrollBy({
      left: -600, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    viewportRef.current.scrollBy({
      left: 600, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };
  console.log(errorIndexes)

  return (
    <div className="scenes-reel">
      {" "}
      <div className="scroll-button scroll-left" onClick={handleScrollLeft}>
        <span>&lt;</span>
      </div>
      <div className="viewport" ref={viewportRef}>
        {updatedData &&
          updatedData[0] &&
          updatedData
            .sort((a, b) => a.index - b.index)
            .map((duration, index) => {
              const hasError = errorIndexes.includes(index +1);
              const buttonClassName = `scene-btn ${
                selectedDiv === index ? "selected" : ""
              } ${hasError ? "index-error" : ""}`;

              return (
                <div
                  className={buttonClassName}
                  key={index}
                  onClick={() => {
                    handleDivClick(index);
                  }}
                  style={{
                    backgroundImage: updatedData[index]?.videoImage
                      ? `url(${updatedData[index]?.videoImage})`
                      : updatedData[index]?.imageUrl
                      ? `url(${updatedData[index].imageUrl})`
                      : "",
                    backgroundColor: updatedData[index]?.backgroundColor
                      ? updatedData[index].backgroundColor
                      : "white",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    zIndex: -1,
                  }}
                >
                  <div className="reel-number" key={`${index}+a`}>
                    {index + 1}
                  </div>
                  {hasError && <div className="error-text">Error loading clip..</div>}
                </div>
              );
            })}

        {/* {updatedData &&
          updatedData[0] &&
          updatedData
            .sort((a, b) => a.index - b.index)
            .map((duration, index) => (
              <div
                className={`scene-btn ${
                  selectedDiv === index ? "selected" : ""
                }`}
                key={index}
                onClick={() => {
                  handleDivClick(index);
                }}
                style={{
                  backgroundImage: updatedData[index]?.videoImage
                    ? `url(${updatedData[index]?.videoImage})`
                    : updatedData[index]?.imageUrl
                    ? `url(${updatedData[index].imageUrl})`
                    : "",
                  backgroundColor: updatedData[index]?.backgroundColor
                    ? updatedData[index].backgroundColor
                    : "white",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: -1,
                }}
              >
                <div className="reel-number" key={`${index}+a`}>
                  {index + 1}
                </div>
              </div>
            ))} */}
      </div>
      <div className="scroll-button scroll-right" onClick={handleScrollRight}>
        <span>&gt;</span>
      </div>
    </div>
  );
};

export default ScenesReel;
