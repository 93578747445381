import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  doc,
  deleteDoc,
} from "firebase/firestore";

function AllImages({
  docsRef,
  db,
  userID,
  setZoom,
  zoomImg,
  setChange,
  change,
  searchQuery,
}) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      const imagesArray = [];

      const docsRef = collection(db, `users/${userID}/documents`);

      const q = query(docsRef, limit(10000));

      const querySnapshot = await getDocs(q);


      querySnapshot.forEach(async (documentDoc) => {
        const aipicsQuerySnapshot = await getDocs(
          query(
            collection(db, `users/${userID}/documents/${documentDoc.id}/aipics`)
          )
        );

        aipicsQuerySnapshot.forEach((aipicDoc) => {
          if (aipicDoc.id === "featured") {
            return;
          }
          imagesArray.push({ id: aipicDoc.id, ...aipicDoc.data() });
        });
      });

      setImages(imagesArray);
    }

    fetchImages();
  }, [userID, change]);

  ///////--------------------------------------//////////

  let filteredImages =
    searchQuery === ""
      ? images
      : images.filter((document) => {
          if (document.promp) {
            return document.promp
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }
          return false;
        });




  return (
    <>
      <div className="documents">
        {filteredImages.length > 0 ? filteredImages.map((image, i) => (
          <div
            className="document"
            key={i}
            onClick={(e) => {
              e.stopPropagation();

              zoomImg(image);
              setZoom(true);
            }}
          >
            {image.hostedUrl && <img src={image.hostedUrl} />}
          </div>
        )): <div className="no-docs">No images found. All your saved images will appear here.</div>}
      </div>
    </>
  );
}

export default AllImages;
