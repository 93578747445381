import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  setDoc,
  query,
  orderBy,
} from "firebase/firestore";
import EditorMessage from "./WriteMessage";
import WriteMessage from "./WriteMessage";
import StopMessage from "./StopMessage";

const WriteButton = ({
  panel,
  setPanel,
  wordCount,
  setWordCount,
  list,
  context,
  subheadings,
  topic,
  setWrite,
  count,
  weblist,
  write,
  setDivert,
  countHeaders,
  quill,
  addDoc,
  db,
  data,
  data3,
  researchBracket,
  writeBracket,
  tokens,
  creativity,
  bullet,
  subtopictag,
  subheadingtag,
  linksnum,
  bul,
  promp,
  subArray,
  intro,
  autoImage,
  promp2,
  newName,
  setLoading2,
  subtopics,
  setPercent,
  setrComplete,
  setwComplete,
  progress,
  setProgress,
  setSparker,
  sparkF,
  availableTokens,
  expectedWords,
  notEnough,
  setNotEnough,
  setLeftPopupMessage,
  setLeftPopup,
  outlineF,
  setSection1,
  section1,
  check,
  setCheck,
  setSection2,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  const [stop, setStop] = useState(false);

  const [suggest, setSuggest] = useState(false);

  //////check if not enough to start/////////
  // useEffect(() => {
  //   //
  //   if (expectedWords > availableTokens * 1.4) {
  //     setNotEnough(true);
  //   } else {
  //     setNotEnough(false);
  //   }
  // }, [expectedWords, availableTokens]);

  //////////-----------------///////////

  let links;

  // if(subArray.length === 0){
  // subArray.push("");
  // }

  if (linksnum === "0" || !linksnum) {
    links = { prompt: "", linksnum: "" };
  } else if (linksnum > 0 && !bul) {
    links = {
      prompt: `I've also given ${linksnum} urls below, add these as contextual html links in the body of the draft.`,
      linksnum: linksnum,
    };
  } else if (linksnum > 0 && bul) {
    links = {
      prompt: `I've also given ${linksnum} urls below, add these as contextual html links in the body of the draft.`,
      linksnum: linksnum,
    };
  }

  let creativity1 = creativity / 100;

  //-----------Database Refs-------------//

  const combinedRef = collection(db, `users/${uid}/documents/${id}/combined`);
  const subdataRef = collection(db, `users/${uid}/documents/${id}/subdata`);
  const subdataRef2 = collection(db, `users/${uid}/documents/${id}/subdata2`);
  const subdataRef3 = collection(db, `users/${uid}/documents/${id}/subdata3`);
  const concRef = collection(db, `users/${uid}/documents/${id}/conclusion`);
  const stopRef = doc(db, `users/${uid}/documents/${id}/stop/start-stop`);

  //------Stop function------//
  const stopFunction = async () => {
    await setDoc(stopRef, { stop: true });
    setLoading2(false);
    setWrite(false);
  };

  //---------Writer Function-----//

  const writer = async () => {
    if (expectedWords > availableTokens * 1.4) {
      return setNotEnough(true);
    }

    setLoading2(true);
    setWrite(true);

    setProgress(0);
    setPercent(0);
    setrComplete(false);
    setwComplete(false);

    await setDoc(stopRef, { stop: false });

    //////////////////

    let obj2;

    let subData;
    /////////////////////
    if (panel === 1 || panel === 4) {
      let promp = {};
      let tokens2;
      data.subTopics.forEach(async (subTopic, i) => {
        obj2 = {
          index: subTopic.index,
          completion: [
            { insert: subTopic.value },
            { insert: "\n", attributes: { header: `${subtopictag}` } },
          ],
          heading: true,
          html: `<h2>${subTopic.value}</h2>`,
        };
        await addDoc(combinedRef, obj2);
      });

      subData = {
        list: data.subTopics.reduce((subHeadings, subTopic) => {
          subTopic.subHeadings.forEach((subHeading) => {
            let index = subHeading.index;

            let new_subheadingtag = "";
            if (subHeading.value === "" || !subHeading.value) {
              new_subheadingtag = "and DO NOT give it any heading";
            } else {
              new_subheadingtag = `giving it ONLY a single ${subheadingtag} subheading of ${subHeading.value}`;
            }

            promp = {
              start: `I want to write a draft which must be in html syntax to use in a html editor. I am writng about ${data.mainTopic.value}, specifically a piece on ${subTopic.value} ${subHeading.value}. I have written an unstructured draft below. Don't repeat ideas verbatim and turn this into a high quality structured draft adding html tags (with only 2 sentence for every <p> tag). ${bullet} describing the ${subHeading.value} using the draft below. Make sure to give plenty of space by adding <p> tags (with 2 sentences each). Write about ${writeBracket} in html ${new_subheadingtag} at the top.${links.prompt}\n\nMy Unstructured text Draft:`,
              end: `Write the high quality structured draft, ${writeBracket}. This must be in html syntax:\n`,
            };
            subHeadings.push({
              index: index || 2,
              promp: promp || "",
              maintopic: data.mainTopic?.value || "",
              subheading: subHeading?.value || "",
              subtopic: subTopic?.value || "",
              researchBracket: researchBracket || "",
              writeBracket: writeBracket || "",
              tokens: tokens || "",
              creativity: creativity1 || "",
              bullet: bullet || "",
              subheadingtag: new_subheadingtag || "",
              links: links || "",
              topic: topic || "",
              auto: autoImage || "",
            });
          });
          return subHeadings;
        }, []),
      };

      if (subData.list.length < 1) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);

        return setLeftPopupMessage(
          "Please add some h2 subheadings or press the 'Generate Outline' button"
        );
      } else if (
        subData.list[0].subtopic === undefined ||
        subData.list[0].subtopic === ""
      ) {
        setLeftPopup(true);
        outlineF();

        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add some h2 subheadings or press the 'Generate Outline' button"
        );
      }

      await addDoc(subdataRef, subData);
    }
    //////////////////
    else if (panel === 2) {
      let subtopics2 = data3.map((obj) => obj.text).slice(0, 4); //at maximum only first for vs are compared;

      let new_subheadingtag = "";
      if (!subArray || subArray.length === 0) {
        new_subheadingtag = "and DO NOT give it any heading";
      } else {
        new_subheadingtag = `giving it ONLY a single ${subheadingtag} subheading`;
      }

      let subData = {
        index: 0,
        maintopic: newName || "",
        subheading: subArray || "",
        subtopic: subtopics2 || "",
        researchBracket: researchBracket || "",
        writeBracket: writeBracket || "",
        tokens: tokens || "",
        creativity: creativity1 || "",
        bullet: bullet || "",
        subheadingtag: new_subheadingtag || "",
        links: links || "",
        topic: topic || "",
        auto: autoImage || "",
      };

      if (subData.subtopic.length < 2) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add at least 2 items to compare or press the 'Generate Ideas' button"
        );
      } else if (
        subData.subtopic[0] === undefined ||
        subData.subtopic[0] === ""
      ) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add at least 2 items to compare or press the 'Generate Ideas' button"
        );
      }

      await addDoc(subdataRef2, subData);
    }
    /////////////////
    else if (panel === 3 || panel === 6) {
      subData = {
        maintopic: newName || "",
        subheadings: subArray || "",
        promp: promp || "",
        researchBracket: researchBracket || "",
        writeBracket: writeBracket || "",
        tokens: tokens || "",
        creativity: creativity1 || "",
        subheadingtag: subheadingtag || "",
        links: links || "",
        topic: topic || "",
        intro: intro || "",
        auto: autoImage || "",
      };

      if (subData.subheadings.length < 1) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add some subheadings or press the 'Suggest Subheadings' button"
        );
      } else if (
        subData.subheadings[0] === undefined ||
        subData.subheadings[0] === ""
      ) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add some subheadings or press the 'Suggest Subheadings' button"
        );
      }
      await addDoc(subdataRef3, subData);
    }
    ///////////////////
    else if (panel === 9) {
      subData = {
        maintopic: newName || "",
        subheadings: subtopics || "",
        promp: promp || "",
        researchBracket: researchBracket || "",
        writeBracket: writeBracket || "",
        tokens: tokens + 800 || "",
        creativity: creativity1 || "",
        subheadingtag: subheadingtag || "",
        links: links || "",
        topic: topic || "",
        intro: intro || "",
        auto: autoImage || "",
      };

      if (subData.subheadings.length < 1) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add a video script outline or press the 'Generate Outline' button"
        );
      } else if (
        subData.subheadings[0] === undefined ||
        subData.subheadings[0] === ""
      ) {
        setLeftPopup(true);
        outlineF();
        setLoading2(false);
        setWrite(false);
        setProgress(0);
        return setLeftPopupMessage(
          "Please add a video script outline or press the 'Generate Outline' button"
        );
      }
      await addDoc(subdataRef3, subData);
    }
    /////////////////
    else if (panel === 7 || panel === 8) {
      subData = {
        index: 1,
        promp: promp2 || "",
        text: "",
        tokens: 1500,
        creativity: 0.8,
      };
      await addDoc(concRef, subData);
    }
    //////////////////
    else if (panel === 5 || panel === 10 || panel === 11) {
      subData = {
        index: 3,
        promp: promp2 || "",
        text: "",
        tokens: 2500,
        creativity: 0.8,
      };

      await addDoc(concRef, subData);
    }

    /////////ADD MAIN HEADING///////////
    if (
      panel === 1 ||
      panel === 2 ||
      panel === 3 ||
      panel === 4 ||
      panel === 5 ||
      panel === 6 ||
      panel === 8 ||
      panel === 9
    ) {
      let mObj = {
        index: 0,
        completion: [
          { insert: newName },
          { insert: "\n", attributes: { header: "h1" } },
        ],
        html: `<h1>${newName}</h1>`,
      };
      await addDoc(combinedRef, mObj);
    }

    setTimeout(() => {
      setSection2(true);
      // Other function code...
    }, 1000);
  };

  //----------------------------------------//

  const calculateButton = () => {
    if (window.innerWidth < 960) {
      return (
        <button
          disabled={write}
          className="write-icon"
          onClick={() => {
            if (notEnough) {
              return alert(
                `You don't have enough tokens, please top up or upgrade plan.`
              );
            }
            if (
              (panel === 1 ||
                panel === 2 ||
                panel === 3 ||
                panel === 4 ||
                panel === 5 ||
                panel === 6 ||
                panel === 9) &&
              newName.length < 3
            ) {
              setSection1(!section1);
              return alert(
                `please write a topic heading and generate an outline first.`
              );
            }
            setCheck(true);
            setSparker(true);
            sparkF();
          }}
        />
      );
    } else {
      return (
        <button
          disabled={write}
          className="write-btn"
          // className={write ? "no-write" : "write-btn"}
          onClick={() => {
            if (notEnough) {
              return alert(
                `You don't have enough tokens, please top up or upgrade plan.`
              );
            }
            if (
              (panel === 1 ||
                panel === 2 ||
                panel === 3 ||
                panel === 4 ||
                panel === 5 ||
                panel === 6 ||
                panel === 9) &&
              newName.length < 3
            ) {
              return alert(
                `please write a topic headining and generate (or write) an outline first.`
              );
            }
            setCheck(true);
            setSparker(true);
            sparkF();
          }}
        >
          <img
            src="http://sparker.ai/wp-content/uploads/2023/01/write-spark-120-×-120-px.png"
            width="15px"
            align="left"
            alt=""
          />
          <span>Start writing</span>
        </button>
      );
    }
  };

  const calculateButton2 = () => {
    if (window.innerWidth < 960) {
      return (
        <button
          className="write-icon2"
          onClick={() => {
            setStop(true);
          }}
        >
          {" "}
          <div className="write-loader"></div>
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            setStop(true);
          }}
          className="write-btn"
        >
          <div className="write-loader"></div>

          <span>Stop Writing</span>
        </button>
      );
    }
  };

  return (
    <>
      <WriteMessage
        writer={writer}
        check={check}
        setCheck={setCheck}
        setSparker={setSparker}
        sparkF={sparkF}
      />
      <StopMessage stopFunction={stopFunction} stop={stop} setStop={setStop} />
      {write ? calculateButton2() : calculateButton()}
    </>
  );
};

export default WriteButton;
