export function countHeaders(htmlString) {
  const container = document.createElement("div");
  container.innerHTML = htmlString;
  const headings = container.querySelectorAll("h1, h2, h3, h4, h5, h6");

  if (headings && headings.length > 0) {
    let highestHeading = headings[0];
    for (let i = 1; i < headings.length; i++) {
      if (headings[i].tagName.charAt(1) < highestHeading.tagName.charAt(1)) {
        highestHeading = headings[i];
      }
    }
    const openingTag = highestHeading.outerHTML.match(/<h\d+>/)[0];
    const pattern = new RegExp(`${openingTag}\\s*\\d+\\.`, "g");
    const matches = htmlString.match(pattern);
    const count = matches ? matches.length : 0;
    return count;
  } else {
    return 0;
  }
}

// Initialize a variable to store the highest level of header seen so far
//   let htmlString = html.toString();
//   console.log(htmlString);
//   const pattern = /<h1>\d+\./g;
//   const matches = htmlString.match(pattern);
//   // Return the length of the array of matches
//   return matches.length;

// let htmlString = htmlString1.toString();
//   let result = 0;
//   let regex = /<h1>\d+\./;
//   let matches = htmlString.match(regex);
//   if (matches !== null) {
//     result = matches.length;
//   }
//   return result;

//   let headerCount = 0;

//   // Initialize a variable to store the highest level of header seen so far
//   let highestHeaderLevel = 9;

//   // Iterate through the ops array
//   delta.ops.forEach((op) => {
//     // Check if the op has an attributes property and a header attribute
//     if (op.attributes && op.attributes.hasOwnProperty("header")) {
//       // Update the highestHeaderLevel variable if the header level of this op is higher than the current highestHeaderLevel
//       highestHeaderLevel = Math.min(highestHeaderLevel, op.attributes.header);
//     }
//   });

// //   console.log(highestHeaderLevel);
//   // Iterate through the ops array again
//   delta.ops.forEach((op, index) => {
//     // get the insert value of the previous op in the array
//     let prevInsert = delta.ops[index - 1] ? delta.ops[index - 1].insert : null;
//     // Check if the op has an attributes property, a header attribute, and if its header level is equal to the highest header level
//     if (
//       (op.attributes &&
//         op.attributes.hasOwnProperty("header") &&
//         op.attributes.header === highestHeaderLevel &&
//         prevInsert.startsWith("\n") &&
//         /[0-9]\./.test(prevInsert.slice(1))) ||
//       /^[0-9]\./.test(prevInsert)
//     ) {
//       // Increment the headerCount variable
//       headerCount++;
//     }
//   });

// //   console.log(headerCount);
//   // Return the headerCount
//   return headerCount;

<div class="plans-container2">
  <div class="documents-wraper plans-wraper">
    <div class="documents">
      <div class="plan starter">
        <div class="plan-style">
          <h3>Starter</h3>
        </div>

        <div class="plan-details">
          <div class="price">
            <div class="price-number">
              <sup>$</sup>
              <span>15</span>
              <sub>/month</sub>
            </div>
          </div>
        </div>
        <div class="free-credit">
          {" "}
          <span>+ $5</span> free credit every month
        </div>

        <div class="price-total">
          total = <span>$20</span> credit every month
        </div>

        <div class="button-container">
          <button>
            <span>Start with $3 free cerdit</span>
          </button>
        </div>
      </div>

      <div class="plan publisher">
        <div class="plan-style">
          <h3>Publisher</h3>
        </div>

        <div class="plan-details">
          <div class="price">
            <div class="price-number">
              <sup>$</sup>
              <span>35</span>
              <sub>/month</sub>
            </div>
          </div>
        </div>

        <div class="free-credit">
          {" "}
          <span>+ $15</span> free credit every month
        </div>

        <div class="price-total">
          total = <span>$50</span> credit every month
        </div>

        <div class="button-container">
          <button>
            <span>Start with $3 free cerdit</span>
          </button>
        </div>
      </div>

      <div class="plan growth">
        <div class="plan-style">
          <h3>Growth</h3>
        </div>

        <div class="plan-details">
          <div class="price">
            <div class="price-number">
              <sup>$</sup>
              <span>65</span>
              <sub>/month</sub>
            </div>
          </div>
        </div>

        <div class="free-credit">
          {" "}
          <span>+ $35</span> free credit every month
        </div>
        <div class="price-total">
          total = <span>$100</span> credit every month
        </div>
        <div class="button-container">
          <button>
            <span>Start with $3 free cerdit</span>
          </button>
        </div>
      </div>
      <div class="plan2">
        <div class="plan-style">
          <h3>Enteprise</h3>
        </div>

        <div class="plan-details">
          <div class="price">
            <div class="price-number2">
              <span>Let's talk.</span>
            </div>
          </div>
        </div>

        <div class="button-container">
          <button
            onClick={() => {
              const url = new URL(
                `https://sparker.ai/contact-us/`,
                window.location.href
              );
              window.open(url.toString(), "_blank");
            }}
          >
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="powered-by-stripe">
    <img src="https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/Stripe_(company)-Powered-by-Stripe-Outline-Logo.wine.svg" />
  </div>

  <div class="costs-flex">
    <div class="costs-text">
      <span>Writing:</span> $0.0005 per word{" "}
    </div>
    <div class="costs-text">
      <span>Video:</span> $0.01 per second
    </div>
    <div class="costs-text">
      <span>Keywords:</span> $0.05 per search{" "}
    </div>
  </div>
  <div class="star-text">All features are included with every plan.</div>
</div>;
