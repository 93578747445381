import React, { useState } from "react";

const SceneButtons = ({selectedDiv3, setSelectedDiv3}) => {

  const handleDivClick = (index) => {
    setSelectedDiv3(index);
  };

  return (
    <div className="top-buttons-container2">
      <div
        className={`top-button ${selectedDiv3 === 0 ? "selected2" : ""}`}
        onClick={() => handleDivClick(0)}
      >
        Text
      </div>
      <div
        className={`top-button ${selectedDiv3 === 1 ? "selected2" : ""}`}
        onClick={() => handleDivClick(1)}
      >
        Background
      </div>
     
      <div
        className={`top-button ${selectedDiv3 === 2 ? "selected2" : ""}`}
        onClick={() => handleDivClick(2)}
      >
        Audio
      </div>
    </div>
  );
};

export default SceneButtons;
