import React, { useEffect, useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
const Intro = ({
  combi,
  db,
  subArray,
  intro,
  panel,
  initialRender2,
  subheadingtag,
  subtopictag,
  newName,
  data2,
  data3,
  write,
  setWrite,
}) => {
   const { id } = useParams();
   const { user } = UserAuth();
   let uid = user.uid;
  const introRef = collection(db, `users/${uid}/documents/${id}/intro`);
  //---------------------------------------//
  let maintopic = newName;
  let promp;
  let obj = {};
  let subtopics = [];
  if ((panel === 1 || panel === 4) && data2 && data2[0]) {
    subtopics = data2.map(function (item) {
      return item.text;
    });
  } else if (panel === 2 && data3 && data3[0]) {
    subtopics = data3.map(function (item) {
      return item.text;
    });
  }

  if (panel === 1 || panel === 4) {
    promp = `I'm writing an article on ${maintopic} which will cover ${subtopics.join(
      ", "
    )} with subheadings of ${subArray.join(
      ", "
    )}. Write (in HTML) a detailed Introduction to this article using a ${subtopictag} "Introduction" heading at the top:\n\n`;
  } else if (panel === 2) {
    promp = `I'm writing a comparison on ${maintopic} which will cover ${subtopics.join(
      ", "
    )} and focus on the ${subArray.join(
      ", "
    )} of each. Write (in HTML) a detailed Introduction to this comparison using a ${subtopictag} "Introduction" heading at the top:\n\n`;
  } else if (panel === 3) {
    promp = `I'm writing a tutorial on ${maintopic} which will cover the steps ${subArray.join(
      ", "
    )}. Write (in HTML) a detailed Introduction to this tutorial using a ${subtopictag} "Introduction" heading at the top:\n\n`;
  }
  

  obj = {
    index: 1,
    promp: promp,
  };

  //--------------------------------//

  useEffect(() => {

    // if (initialRender2.current) {
    //   initialRender2.current = false;
    // } else {

    if (panel === 1 || panel === 2 || panel === 3 || panel === 4  ){

    if (intro && write && maintopic != "" && subArray[0] != "") {
      addDoc(introRef, obj);
    }

    }
  }, [write]);

  //--------------------------------------//
  return <></>;
};

export default Intro;
