import React, { useState } from "react";

const SceneAudio = ({ music, updateMusicVolume, updateVoiceVolume }) => {
  const [voiceVolume, setVoiceVolume] = useState(100);
  const [musicVolume, setMusicVolume] = useState(30);

  const handleVoiceVolume = (event) => {

    const volume = event.target.value;;
    const value = volume / 100;

    setVoiceVolume(volume);
    updateVoiceVolume(value);
  };

  const handleMusicVolume = (event) => {

    const volume = event.target.value;
    const value = volume / 100;

    setMusicVolume(volume);
    updateMusicVolume(value);
  };

  return (
    <div>
      {music?.musicName && (
        <div className="text-item scene-bg-audio">
          <span>Background Music</span>
          <div className="scene-bg-image">
            <div className="bg-music">{music?.musicName}</div>
            <div
              className="delete-music"
              // onClick={() => {
              //   removeField(currentIndex, "imageUrl");
              // }}
            >
              X
            </div>
          </div>
        </div>
      )}

      <div className="text-item">
        <span>Voice Volume</span>
        <input
          type="range"
          min={0}
          max={100}
          value={voiceVolume}
          onChange={handleVoiceVolume}
        />
      </div>
      <div className="text-item">
        <span>Music Volume</span>
        <input
          type="range"
          min={0}
          max={100}
          value={musicVolume}
          onChange={handleMusicVolume}
        />
      </div>
    </div>
  );
};

export default SceneAudio;
