import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

import ClearMessage from "./ClearMessage";
import ProofMessage from "./ProofMessage";

const EditorButtons = ({
  db,
  list,
  context,
  topic,
  subheadings,
  weblist,
  setData5,
  aisize,
  websize,
  setDivert,
  setWrite,
  write,
  delta,
  setValue,
  quill,
  string,
  strings,
  dupliRef,
  count,
  serpRef,
  returnsRef,
  setLoading3,
  loading3,
  loading2,
  editorRef2,
  combi,

  setPerclass2,
  setSaved,
  saved,
  percent,
  setPercent,
  combinedRef,
  panel,
  countColoredWords,
  setrComplete,
  setwComplete,
  setImagePrompt,
  setAiImages,
  setError2,
  setTitle,
  captureScreenshot,
  setProgress22,
  availableTokens,
  expectedWords,
  notEnough,
  setNotEnough,
  setLeftPopup,
  setSection2,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  //-------------------------------------------//
  const [dupliprog, setDupliprog] = useState(false);
  const [reword1, setReword1] = useState(false);
  const [reword2, setReword2] = useState(false);
  const [create, setCreate] = useState(false);

  const [elab, setElab] = useState(false);
  const [save, setSave] = useState(false);
  const [copied, setCopied] = useState(false);
  const initialRender = useRef(true);
  const [disable, setDisable] = useState(false);

  const [docID2, setDocID2] = useState();

  const [html, setHtml] = useState("");

  const [size, setSize] = useState(0);

  const [clearMessage, setClearMessage] = useState(false);
  const [proofMessage, setProofMessage] = useState(false);

  //--------------------------//

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [expanded, setExpanded] = useState(false);

  const expandButton = () => {
    setExpanded(!expanded);
  };
  //-----------Stop Function-------------//

  //----------------------------------------//

  //---------------CLEARALL FUNCTION--------------//
  let delarr = [];
  let delarr2 = [];
  let delarr3 = [];
  let delarr4 = [];
  let delarr5 = [];

  const clearAll = async () => {
    const querySnapshot1 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/combined`)
    );
    const querySnapshot2 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/subdata`)
    );
    const querySnapshot3 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/subsearch`)
    );
    const querySnapshot4 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/subsearch2`)
    );
    const querySnapshot5 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/subsearch3`)
    );

    //////-----GET AIRETURNS DOCS------//////
    querySnapshot1.forEach((doc) => {
      delarr.push(doc.id);
    });
    for (let i = 0; i < delarr.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/combined`,
        delarr[i]
      );
      deleteDoc(docRef);
    }
    aisize.current = 0;
    //////-----GET WEBDATA DOCS------//////
    querySnapshot2.forEach((doc) => {
      delarr2.push(doc.id);
    });
    for (let i2 = 0; i2 < delarr2.length; i2++) {
      const docRef2 = doc(
        db,
        `users/${uid}/documents/${id}/subdata`,
        delarr2[i2]
      );
      deleteDoc(docRef2);
    }
    websize.current = 0;
    //////-----GET WEBLIST DOCS------//////
    querySnapshot3.forEach((doc) => {
      delarr3.push(doc.id);
    });
    for (let i3 = 0; i3 < delarr3.length; i3++) {
      const docRef3 = doc(
        db,
        `users/${uid}/documents/${id}/subsearch`,
        delarr3[i3]
      );
      deleteDoc(docRef3);
    }

    querySnapshot4.forEach((doc) => {
      delarr4.push(doc.id);
    });
    for (let i = 0; i < delarr4.length; i++) {
      const docRef4 = doc(
        db,
        `users/${uid}/documents/${id}/subsearch2`,
        delarr4[i]
      );
      deleteDoc(docRef4);
    }
    querySnapshot5.forEach((doc) => {
      delarr5.push(doc.id);
    });
    for (let i = 0; i < delarr5.length; i++) {
      const docRef5 = doc(
        db,
        `users/${uid}/documents/${id}/subsearch3`,
        delarr5[i]
      );
      deleteDoc(docRef5);
    }
    ////////////----------------------------///////////////
    setData5([{ completion: [{ insert: "\n" }] }]);

    setLeftPopup(false);
    setPercent(0);
    setWrite(false);
    setrComplete(false);
    setwComplete(false);
    setValue("");
    setLoading3(false);
    setProgress22(0);
  };
  //-------------------------------------------------//

  //---------------CLEArDUPLI FUNCTION--------------//

  const clearDupli = async () => {
    let delarr6 = [];
    let delarr8 = [];

    const querySnapshot1 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/combined`)
    );
    const querySnapshot3 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/duplicheck`)
    );
    //////-----GET AIRETURNS DOCS------//////
    querySnapshot1.forEach((doc) => {
      delarr6.push(doc.id);
    });
    for (let i = 0; i < delarr6.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/combined`,
        delarr6[i]
      );
      deleteDoc(docRef);
    }
    //////-----GET DUPLICHECK DOCS------//////
    querySnapshot3.forEach((doc) => {
      delarr8.push(doc.id);
    });
    for (let i = 0; i < delarr8.length; i++) {
      const docRef = doc(
        db,
        `users/${uid}/documents/${id}/duplicheck`,
        delarr8[i]
      );
      deleteDoc(docRef);
    }
  };

  //-------------------------------------------------//

  //---------------------PARAPHRASE API---------------------//

  //----------------------------------------------------------//

  //----------------------FROMAI FUNCTION--------------------//

  const fromAI = async (promp) => {
    if (availableTokens < 500) {
      return setNotEnough(true);
    }
    try {
      // Make a request to the Firebase function to fetch data from the OpenAI API
      const response = await fetch(
        `https://us-central1-cruncher-ai-app.cloudfunctions.net/fromAI?promp=${promp}&u=${uid}&i=${id}`,
        {
          mode: "cors",
        }
      );
      const data = await response.json();

      if (data.error) {
        return null;
      } else {
        if (data.model === "chat") {
          return data.data.choices[0].message.content;
        } else if (data.model === "davinci3" || data.model === "davinci2") {
          return data.data.choices[0].text;
        }
      }

      // return data.choices[0].text;
    } catch (error) {
      setError2(true);
      setReword1(false);
      setReword2(false);
      setElab(false);
      setCreate(false);
    }
    // return response;
  };
  //----------------------------------------------------------//

  const createPrompt = async () => {
    const range = quill.current.getEditor().getSelection();
    const text = quill.current.getEditor().getText(range);
    if (text.length < 20) {
      setAiImages(true);
      return alert(
        `please select at least 20 characters for the AI to generate a prompt.`
      );
    }

    if (availableTokens < 1000) {
      return setNotEnough(true);
    }
    setCreate(true);

    if (range && text.length > 15) {
      const promp = `I want you to create a prompt to give to DALL-E. DALL-E is an AI image generator which can create great images from good prompt design. However, it's biggest flaw is that it doesn't generate text or numbers very well. So any prompt we give to it should not include reference to numbers or text. The prompt shouldn't be very abstract, it should be very direct.\n\nUsing this knowledge I would like you to write a great prompt to give to DALL-E which would generate an awesome image that is very relevant to the paragraph I'm going to give below. Here is the paragraph:\n\n${text}\n\nPlease only reply with the prompt as it will go directly into DALL-E. Here is the Prompt to give to DALL-E:`;

      const imagePromp = await fromAI(promp);

      setImagePrompt(imagePromp);
    }

    setAiImages(true);
    setCreate(false);
  };

  //--------------Paraphrase Dublicate AI Content-------------//
  // const paraphrase = async () => {
  //   await saveFunction();

  //   setReword1(true);
  //   let objectArray = quill.current.getEditor().getContents().ops;

  //   for (let i = 0; i < objectArray.length; i++) {
  //     const object = objectArray[i];
  //     if (
  //       object.insert &&
  //       object.attributes &&
  //       object.attributes.background &&
  //       (object.attributes.background === "rgba(255, 255, 0, 0.5)" ||
  //         object.attributes.background === "rgba(255, 0, 0, 0.5)" ||
  //         object.attributes.background === "rgb(255, 0, 0,)" ||
  //         object.attributes.background === "rgb(255, 255, 0,)" ||
  //         object.attributes.background === "#ffff00")
  //     ) {
  //       const { insert } = object;

  //       let promp = `Paraphrase this:\n${insert}\nParaphrased:\n`;

  //       console.log(`fromAI called for ${i} times`)

  //       const paraphrasedText = await fromAI(promp);

  //       object.insert = paraphrasedText;

  //       object.attributes.background = "#c9ffe5";

  //       // Call the function to reset the background color after 5 seconds
  //       setTimeout(() => {
  //         if (object.attributes.background === "#c9ffe5") {
  //           object.attributes.background = "#ffffff";
  //           setValue(objectArray);
  //         }
  //       }, 5000);
  //     }
  //     setValue(objectArray);
  //   }
  //   setReword1(false);
  //   setTimeout(function () {
  //     countColoredWords();
  //   }, 1000);

  //   setSection2(true);
  // };

  const paraphrase = async () => {
    await saveFunction();

    setReword1(true);
    let objectArray = quill.current.getEditor().getContents().ops;

    for (let i = 0; i < objectArray.length; i++) {
      const object = objectArray[i];
      if (
        object.insert &&
        object.attributes &&
        object.attributes.background &&
        (object.attributes.background === "rgba(255, 255, 0, 0.5)" ||
          object.attributes.background === "rgba(255, 0, 0, 0.5)" ||
          object.attributes.background === "rgb(255, 0, 0,)" ||
          object.attributes.background === "rgb(255, 255, 0,)" ||
          object.attributes.background === "#ffff00")
      ) {
        const { insert } = object;

        let promp = `Paraphrase this:\n${insert}\nParaphrased:\n`;

        console.log(`fromAI called for ${i} times`);

        // Delay the fromAI call by 0.5 seconds
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const paraphrasedText = await fromAI(promp);

        object.insert = paraphrasedText;
        object.attributes.background = "#c9ffe5";

        // Call the function to reset the background color after 5 seconds
        setTimeout(() => {
          if (object.attributes.background === "#c9ffe5") {
            object.attributes.background = "#ffffff";
            setValue(objectArray);
          }
        }, 5000);
      }
      setValue(objectArray);
    }

    setReword1(false);
    setTimeout(function () {
      countColoredWords();
    }, 1000);

    setSection2(true);
  };

  //------------------------------------------------------//

  //---------------Paraphrase Selected Text---------------------//
  // const paraSelection = async (range, text) => {
  //   await saveFunction();

  //   setReword1(true);
  //   // const range = quill.current.getEditor().getSelection();
  //   // const text = quill.current.getEditor().getText(range);
  //   // await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
  //   let paraphrase;

  //   try {
  //     let promp = `Paraphrase this:\n${text}\nParaphrased:\n`;

  //     paraphrase = await fromAI(promp);
  //   } catch (error) {
  //     alert(`Server is busy please try again after some time ${error}`);
  //   }

  //   if (paraphrase) {
  //     quill.current.getEditor().deleteText(range.index, range.length);
  //     quill.current.getEditor().insertText(range.index, paraphrase, {
  //       background: "#c9ffe5",
  //     });
  //   }

  //   setTimeout(function () {
  //     countColoredWords();
  //   }, 1000);
  //   setReword1(false);
  // };
  const paraSelection = async (range, text) => {
    await saveFunction();

    setReword1(true);

    let paraphrase;

    try {
      let prompt = `Paraphrase this:\n${text}\nParaphrased:\n`;

      paraphrase = await fromAI(prompt);
    } catch (error) {
      alert(`Server is busy, please try again after some time: ${error}`);
    }

    if (paraphrase) {
      quill.current.getEditor().deleteText(range.index, range.length);
      quill.current.getEditor().insertText(range.index, paraphrase, {
        background: "#c9ffe5",
      });

      // Call the function to reset the background color after 5 seconds
      setTimeout(() => {
        const delta = quill.current.getEditor().getContents();
        const ops = delta.ops;

        for (let i = 0; i < ops.length; i++) {
          const object = ops[i];
          if (
            object.insert &&
            object.attributes &&
            object.attributes.background === "#c9ffe5"
          ) {
            object.attributes.background = "#ffffff";
          }
        }

        quill.current.getEditor().setContents(delta);
      }, 5000);
    }

    setTimeout(function () {
      countColoredWords();
    }, 1000);
    setReword1(false);
  };

  //-------------------------------------------------------//

  const para = async () => {
    await saveFunction();

    ///first check if selection///
    const range = quill.current.getEditor().getSelection();
    const text = quill.current.getEditor().getText(range);
    /////////
    if (range && text.length > 2) {
      await paraSelection(range, text);
    } else {
      let objectArray = quill.current.getEditor().getContents().ops;
      if (objectArray) {
        await paraphrase();
      } else {
        return null;
      }
    }
  };
  //---------------AI Selected Text---------------------//
  const aiSelection = async () => {
    const range = quill.current.getEditor().getSelection();
    const text = quill.current.getEditor().getText(range);

    if (text.length < 20) {
      return alert(`please select at least 20 characters`);
    }

    if (availableTokens < 500) {
      return setNotEnough(true);
    }
    // await saveFunction();
    setElab(true);

    let promp = `Elaborate on this:\n${text}\nElaboration:\n`;
    let aiResp;
    let error = false;

    try {
      aiResp = await fromAI(promp);

      if (!aiResp) {
        return null;
      }
    } catch {
      error = true;
    }

    if (!error && aiResp) {
      quill.current.getEditor().deleteText(range.index, range.length);
      quill.current
        .getEditor()
        .insertText(range.index, aiResp, { background: "#c9ffe5" });

      setElab(false);
    } else {
      return null;
    }

    // Delete the original
  };

  //----------------------GET HTML from Combined-------------------------//

  useEffect(() => {
    let isMounted = true;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      //////-----SNAPSHOT AIRETURNS------//////
      onSnapshot(combi, (snapshot) => {
        setSize(snapshot.size);
        let data1 = [];
        snapshot.docs.map((doc) => data1.push(doc.data().html));
        //  aisize.current = snapshot.size;
        let html = data1.join("");
        if (isMounted) {
          setHtml(html);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [save, loading3]);
  ////////////////////////////
  useEffect(() => {
    let isMounted = true;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // if (dupliprog && size === 1) {
      // setLoading3(false);
      // setPerclass2(true);
      setTimeout(function () {
        countColoredWords();
      }, 1000); // }
    }
    return () => {
      isMounted = false;
    };
  }, [aisize.current]);

  //---------------Dupli Check Again---------------------//

  let functionInProgress = false;

  const DupliCheck = async () => {
    if (functionInProgress) {
      return;
    }
    if (
      panel === 5 ||
      panel === 7 ||
      panel === 8 ||
      panel === 9 ||
      panel === 10 ||
      panel === 11
    ) {
      alert("Plagiarism Checker is not required for this template");
    } else {
      let html2;
      onSnapshot(combi, (snapshot) => {
        let data1 = [];
        snapshot.docs.map((doc) => data1.push(doc.data().html));
        //  aisize.current = snapshot.size;
        html2 = data1.join("");
      });

      setDupliprog(true);
      setLoading3(true);

      functionInProgress = true;
      await clearDupli();

      let obj = {
        index: 0,
        reference: strings.join(". "),
        html: html2,
      };
      await addDoc(dupliRef, obj);
      functionInProgress = false;
    }
  };
  //--------------------COPY FUNCTION-----------------------------//
  const copyFunction = useCallback(() => {
    const quillEditor = quill.current.editor;
    const range = quillEditor.getSelection();

    // Select all the text if no text is selected
    if (range.length === 0) {
      quillEditor.setSelection(0, quillEditor.getLength());
    }
    document.execCommand("copy");

    setCopied(true);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (copied) {
        setTimeout(() => {
          setCopied(false);
        }, 500);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [copied]);

  //---------------------SAVE FUNCTION-----------------------------//

  const saveFunction = async () => {
    captureScreenshot();
    setTitle();
    const editorContent = quill.current.getEditor().getContents().ops;
    const html = quill.current.getEditor().root.innerHTML;

    setSave(true);
    let ids = [];
    const querySnapshot1 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/combined`)
    );
    querySnapshot1.forEach((doc) => {
      ids.push(doc.id);
    });
    for (let i = 0; i < ids.length; i++) {
      const docRef = doc(db, `users/${uid}/documents/${id}/combined`, ids[i]);
      deleteDoc(docRef);
    }

    const newDocRef2 = await addDoc(
      collection(db, `users/${uid}/documents/${id}/combined`),
      {
        completion: editorContent,
        html: html,
        index: 0,
        string: strings.join(", "),
        saved: true,
      }
    );

    const docID2 = newDocRef2.id;

    setDocID2(docID2);

    setSave(false);
    setSaved(true);
  };

  useEffect(() => {
    if (window.innerWidth > 959) {
      const textareaElement = document.querySelector(".editor-buttons");
      textareaElement.style.position = "fixed";

      return () => {
        textareaElement.style.position = "static";
      };
    }
  }, []);
  //-------------------------------------------------//

  // const [keyboardHeight, setKeyboardHeight] = useState();

  // useEffect(() => {
  //    if (window.innerWidth < 960){
  //   const handleResize = () => {
  //     const windowHeight = window.innerHeight;
  //     const viewportHeight = window.visualViewport.height;
  //     const newKeyboardHeight = Math.max(0, windowHeight - viewportHeight);
  //     setKeyboardHeight({bottom: newKeyboardHeight});
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }
  // }, []);

  //---------Run Proof Reading-----///
  const [promp5, setPromp5] = useState("");

  const proofReader = async () => {
    ///-----Mimic Save function first----///
    const editorContent = quill.current.getEditor().getContents().ops;
    const html = quill.current.getEditor().root.innerHTML;

    setSave(true);
    let ids = [];
    const querySnapshot1 = await getDocs(
      collection(db, `users/${uid}/documents/${id}/combined`)
    );
    querySnapshot1.forEach((doc) => {
      ids.push(doc.id);
    });
    for (let i = 0; i < ids.length; i++) {
      const docRef = doc(db, `users/${uid}/documents/${id}/combined`, ids[i]);
      deleteDoc(docRef);
    }

    const newDocRef2 = await addDoc(
      collection(db, `users/${uid}/documents/${id}/combined`),
      {
        completion: editorContent,
        html: html,
        index: 0,
        string: strings.join(", "),
        saved: true,
      }
    );

    const docID2 = newDocRef2.id;

    setSave(false);
    setSaved(true);
    let promp6 = `${promp5}`;
    const proofReadRef = collection(
      db,
      `users/${uid}/documents/${id}/proof-read`
    );

    await addDoc(proofReadRef, {
      promp: promp6,
      docid2: docID2,
    });
  };

  console.log(promp5);

  return (
    <>
      <ProofMessage
        promp5={promp5}
        setPromp5={setPromp5}
        proofReader={proofReader}
        setProofMessage={setProofMessage}
        proofMessage={proofMessage}
      />
      <ClearMessage
        clearMessage={clearMessage}
        setClearMessage={setClearMessage}
        clearAll={clearAll}
      />{" "}
      <div className={saved ? "saved" : "hide"}>
        <div>saved ✓</div>
      </div>
      <div className={copied ? "saved" : "hide"}>
        <div>copied ✓</div>
      </div>
      <div className="editor-buttons-wraper">
        <div className="editor-buttons-container">
          <div className="editor-buttons">
            <button
              className={save ? "no-write edit-btn" : "save-btn edit-btn"}
              disabled={save}
              onClick={saveFunction}
            ></button>
            <button
              className={copied ? "no-write edit-btn" : "copy-btn edit-btn"}
              disabled={copied}
              onClick={copyFunction}
            ></button>
            <button
              className="clear-btn edit-btn"
              onClick={() => setProofMessage(true)}
            ></button>
            <button
              className={reword1 ? "no-write edit-btn" : "reword edit-btn"}
              disabled={reword1}
              onClick={para}
            ></button>
            <button
              className={create ? "no-write edit-btn" : "create edit-btn"}
              disabled={create}
              onClick={createPrompt}
            ></button>
            <button
              className={elab ? "no-write edit-btn" : "elab-btn edit-btn"}
              disabled={elab}
              onClick={aiSelection}
            ></button>
            <button
              className="clear-btn edit-btn"
              onClick={() => setClearMessage(true)}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorButtons;
