import { useState, useEffect, useRef } from "react";

function Movable2({ children }) {
  const [isDragging, setIsDragging] = useState(false);
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const [translation, setTranslation] = useState({ x: 0, y: 0 });

  const ref = useRef(null);

  useEffect(() => {
    if (isDragging) {
      function handleMouseMove(event) {
        const { clientX, clientY } = event;
        const deltaX = clientX - origin.x;
        const deltaY = clientY - origin.y;
        setTranslation({ x: deltaX, y: deltaY });
      }

      function handleMouseUp() {
        setIsDragging(false);
        setOrigin({ x: 0, y: 0 });
      }

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDragging, origin]);

  function handleMouseDown(event) {
    const { clientX, clientY } = event;
    const element = ref.current;
    const { x, y } = element.getBoundingClientRect();
    setOrigin({ x: clientX - x, y: clientY - y });
    setIsDragging(true);
  }

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        transform: `translate(${translation.x}px, ${translation.y}px)`,
      }}
      onMouseDown={handleMouseDown}
    >
      {children}
    </div>
  );
}

export default Movable2;