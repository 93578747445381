import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import { clipboard } from "quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import MiniToolbar from "./MiniToolbar";

import { countHeaders } from "../Functions/countHeading";
import Movable2 from "./Movable";
import ImageAi from "./ImageAi";

import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

import {
  onSnapshot,
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

import WriteButton from "./WriteButton";
import CopyDownload from "./CopyDownload";
import ErrorMessage from "./ErrorMessage";
import Screenshot from "./Screenshot";
import NotEnough from "./NotEnough";
import LeftPopup from "./LeftPopup";

const RichEditor = ({
  panel,
  combi,
  setPanel,
  data,
  data3,
  quill,
  value,
  setValue,
  delta,
  setEditorD,
  setEditorH,
  setDivert,
  db,
  returnsRef,
  write,
  string,
  wordCount,
  setWordCount,
  list,
  context,
  subheadings,
  topic,
  setWrite,
  count,
  weblist,
  researchBracket,
  writeBracket,
  tokens,
  creativity,
  bullet,
  subtopictag,
  subheadingtag,
  linksnum,
  bul,
  promp,
  promp2,
  subArray,
  intro,
  autoImage,
  newName,
  setLoading2,
  loading2,
  loading3,
  subtopics,

  setPerclass2,
  perclass2,
  percent,
  setPercent,
  saved,
  research22,
  aisize,
  rExected,
  rComplete,
  wComplete,
  setrComplete,
  setwComplete,
  aiImages,
  setAiImages,
  insertImage,
  imageRef,
  imagePrompt,
  setImagePrompt,
  calc,
  saveFunction,
  progress,
  setProgress,
  errorsRef,
  error2,
  setError2,
  captureScreenshot,
  setSparker,
  sparkF,
  availableTokens,
  expectedWords,
  notEnough,
  setNotEnough,
  leftPopup,
  setLeftPopup,
  leftPopupMessage,
  setLeftPopupMessage,
  outlineF,
  setSection1,
  section1,
  check,
  setCheck,
  setSection2,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  //--------------------USESTATES--------------------//
  const [selection, setSelection] = useState(null);
  const initialRender = useRef(true);

  const [countBgColor, setCountBgColor] = useState("white");
  const [plagBgColor, setPlagBgColor] = useState("white");

  const [loadText, setLoadText] = useState("researching..");

  //------------------------------------------//
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //--------------------USEREFS--------------------//
  const initialRender3 = useRef(true);
  //------------------------------------------//

  //------------------USEEFFECTS------------------------//

  // const [imgUrl, setImgUrl] = useState();

  // useEffect(() => {
  //   const func = async () => {
  //     const storage = getStorage();
  //     const reference = ref(
  //       storage,
  //       `/images/10d1Q8wpR1jjp4WGlUxg-WYz19aV0MwRZxP2YQzXN.png`
  //     );

  //     await getDownloadURL(reference).then((x) => {
  //       setImgUrl(x);
  //     });
  //   };

  //   func();
  // }, []);

  // let value2 = `<img src=${imgUrl} />`;

  // Use a useEffect hook to update the word count whenever the value of the editor changes
  useEffect(() => {
    const editorText = quill.current.getEditor().getText();
    const words = editorText.split(" ");
    setWordCount(words.length - 1);
  }, [value]);

  useEffect(() => {
    setEditorD(quill.current.getEditor().getContents());
    setEditorH(quill.current.getEditor().root.innerHTML);
  }, [value]);

  //////-----/////

  //------------------On Selection Function-----------------//
  const handleSelectionChange = (range, source) => {
    setSelection(range);
  };
  //-------------------------------------------------//

  //-----------------OnBlur and Save Function-------------//

  //-------------------------------------------------------//

  //---------------------------------------//

  //-----------SNAPSHOT DATABASE---------//

  useEffect(() => {
    let isMounted = true;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      //////-----SNAPSHOT AIRETURNS------//////
      onSnapshot(combi, (snapshot) => {
        let data1 = [];
        let data2 = [];
        snapshot.docs.map((doc) => data1.push(doc.data().html));
        snapshot.docs.map((doc) => data2.push(doc.data().error));

        // let hasError = data2.some((str) => str?.length > 1);
        let theError = "";
        let hasError = data2.some((str) => {
          if (str?.length > 1) {
            theError = str;
            return true; // exit the loop once a string with length greater than 1 is found
          }
          return false;
        });
        let html = data1.join("");
        if (isMounted) {
          setValue(html);

          if (hasError) {
            setErrorMessage(theError);

            setError(true);
          }
        }
      });
      // }
    }
    return () => {
      isMounted = false;
    };
  }, [aisize.current]);

  //--------------------------------------------//
  // function closeFunction() {
  //   setPerclass2(false);
  // }

  useEffect(() => {
    setCountBgColor("yellow");
    const timer = setTimeout(() => {
      setCountBgColor("white");
    }, 300);
    return () => clearTimeout(timer);
  }, [wordCount]);

  useEffect(() => {
    setPlagBgColor("yellow");
    const timer = setTimeout(() => {
      setPlagBgColor("white");
    }, 300);
    return () => clearTimeout(timer);
  }, [percent]);
  //--------------------------------------//
  return (
    <>
      <LeftPopup
        leftPopup={leftPopup}
        leftPopupMessage={leftPopupMessage}
        setLeftPopup={setLeftPopup}
      />
      <ErrorMessage
        errorsRef={errorsRef}
        combi={combi}
        setWrite={setWrite}
        loading2={loading2}
        setLoading2={setLoading2}
        error={error}
        setError={setError}
        error2={error2}
        setError2={setError2}
        errorMessage={errorMessage}
        db={db}
      />
      <div className="t-tools">
        {window.innerWidth < 960 ? <MiniToolbar /> : <EditorToolbar />}
        {/* <EditorToolbar /> */}

        {/* <button onClick={captureScreenshot}>Screen Shot</button> */}
        {/* <button onClick={setTitle}>Title</button> */}

        {/* <Screenshot /> */}
        <WriteButton
          panel={panel}
          setPanel={setPanel}
          data={data}
          data3={data3}
          setWrite={setWrite}
          quill={quill}
          count={count}
          countHeaders={countHeaders}
          setDivert={setDivert}
          context={context}
          topic={topic}
          list={list}
          subheadings={subheadings}
          addDoc={addDoc}
          weblist={weblist}
          write={write}
          db={db}
          writeBracket={writeBracket}
          tokens={tokens}
          researchBracket={researchBracket}
          creativity={creativity}
          bullet={bullet}
          subheadingtag={subheadingtag}
          subtopictag={subtopictag}
          linksnum={linksnum}
          bul={bul}
          promp={promp}
          promp2={promp2}
          subArray={subArray}
          intro={intro}
          autoImage={autoImage}
          newName={newName}
          setLoading2={setLoading2}
          subtopics={subtopics}
          setPercent={setPercent}
          setrComplete={setrComplete}
          setwComplete={setwComplete}
          progress={progress}
          setProgress={setProgress}
          setSparker={setSparker}
          sparkF={sparkF}
          availableTokens={availableTokens}
          expectedWords={expectedWords}
          notEnough={notEnough}
          setNotEnough={setNotEnough}
          setLeftPopupMessage={setLeftPopupMessage}
          setLeftPopup={setLeftPopup}
          outlineF={outlineF}
          setSection1={setSection1}
          section1={section1}
          check={check}
          setCheck={setCheck}
          setSection2={setSection2}
        />
        <div className="t-meta">
          <div className="meta-each">
            word count{" "}
            <span
              style={{
                backgroundColor: countBgColor,
                transition: "background-color 100ms ease-in-out",
              }}
            >
              {wordCount}{" "}
            </span>
          </div>
          <div className={calc ? "meta-each" : "hide"}>
            plagiarism{" "}
            <span
              style={{
                backgroundColor: plagBgColor,
                transition: "background-color 100ms ease-in-out",
              }}
            >
              {percent} %
            </span>
          </div>
        </div>
      </div>
      <div className="editor">
        <ImageAi
          aiImages={aiImages}
          setAiImages={setAiImages}
          insertImage={insertImage}
          imageRef={imageRef}
          db={db}
          imagePrompt={imagePrompt}
          setImagePrompt={setImagePrompt}
          saveFunction={saveFunction}
          availableTokens={availableTokens}
          expectedWords={expectedWords}
          notEnough={notEnough}
          setNotEnough={setNotEnough}
        />
        <NotEnough notEnough={notEnough} setNotEnough={setNotEnough} db={db} />
        <ReactQuill
          ref={quill}
          modules={modules}
          formats={formats}
          theme="snow"
          style={{
            fontFamily:
              'Trebuchet, "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
            fontWeight: 400,
            letterSpacing: "0.2px",
            fontSize: "20px",
          }}
          onChange={setValue}
          value={value}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </>
  );
};

export default RichEditor;
