import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Protected = ({ children }) => {
  const { user } = UserAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" />;
  } else if (
    user &&
    user.emailVerified === false &&
    location.pathname !== "/verify"
  ) {
    return <Navigate to="/verify" />;
  }

  return children;
};

export default Protected;
