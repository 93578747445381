import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import "../Remotion.css";
import "../Font.css";
import { getAudioDurationInSeconds } from "@remotion/media-utils";

import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import Template1 from "../Components/Template1";
import VideoErrors from "../VideoComponents/VideoErrors";
import PlayerContainer from "../Components/PlayerContainer";
import Voices from "../VideoComponents/Voices";
import ScenesReel from "../VideoComponents/ScenesReel";
import TopButtons from "../VideoComponents/TopButtons";
import Images from "../VideoComponents/Images";
import Videos from "../VideoComponents/Videos";
import Music from "../VideoComponents/Music";
import CurrentScene from "../VideoComponents/CurrentScene";
// import DownloadVideo from "../VideoComponents/DownloadVideo";
// import GeneratePreview from "../VideoComponents/GeneratePreview";
import ErrorMessage from "../VideoComponents/ErrorMessage";
import VideoPlan from "../VideoComponents/VideoPlan";

const Video = ({ db }) => {
  const { id } = useParams();

  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  //////////Available Tokens/////////
  const [availableTokens, setAvailableTokens] = useState();

  const tokensRef = collection(db, `users/${userID}/tokens/`);

  useEffect(() => {
    if (userID && tokensRef) {
      async function getAvailableTokens() {
        const querySnapshot = await getDocs(tokensRef);

        let sum = 0;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.tokens) {
            sum += data.tokens;
          }
        });
        const availableTokens = sum;

        setAvailableTokens(availableTokens);
      }

      getAvailableTokens();
    }
  }, [userID, tokensRef]);

  ///// Select Voice ////

  const [selectedVoice, setSelectedVoice] = useState();

  const [title, setTitle] = useState("Untitled");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [resolution, setResolution] = useState({
    width: 1920,
    height: 1080,
    res: "hd",
  });
  const [selectedResolution, setSelectedResolution] = useState(1);
  const [download, setDownload] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);

  const [progDone, setProgDone] = useState();
  const [timeout, setTimeout] = useState();

  const [applyTrig, setApplyTrig] = useState(false);

  const [progress, setProgress] = useState(0);
  const [showBar, setShowBar] = useState(false);

  const [init, setInit] = useState(false);

  const [downloadUrl, setDownloadUrl] = useState();
  const [framesRendered, setFramesRendered] = useState();
  const [framesEncoded, setFramesEncoded] = useState();

  //////Get Duration and other data for Player/////
  const [sequences, setSequences] = useState();
  const [changes, setChanges] = useState([]);
  const [updatedData, setUpdatedData] = useState();
  const [images, setImages] = useState();
  const [music, setMusic] = useState({
    musicName: "Space Atmospheric Background",
    musicUrl:
      "https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/music/space-atmospheric-background-124841.mp3",
  });
  const [voices, setVoices] = useState();

  // const [audioDurations, setAudioDurations] = useState([]);
  const [duration, setDuration] = useState(1);
  const [sceneDurations, setSceneDurations] = useState([]);
  const [preview, setPreview] = useState(false);
  const [checkErrors, setCheckErrors] = useState(false);
  const [renderFinished, setRenderFinished] = useState(true);
  const [Previewprogress, setPreviewprogress] = useState(0);
  const [selectedDiv2, setSelectedDiv2] = useState(0);
  const [selectedDiv3, setSelectedDiv3] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fontFamily, setFontFamily] = useState("Open Sans");

  const [cleanText, setCleanText] = useState();

  const combinedRef = collection(db, `users/${uid}/documents/${id}/combined`);

  /////////---------------------------//////////
  useEffect(() => {
    if (combinedRef) {
      const fetchCleanText = async () => {
        const q = query(combinedRef);
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const firstDoc = snapshot.docs[0];
          const htmlField = firstDoc.data().html;
          // Remove HTML tags and keep only the text
          const cleanText = htmlField.replace(/(<([^>]+)>)/gi, "");
          setCleanText(cleanText);
        }
      };

      fetchCleanText();
    }
  }, [combinedRef, preview]);

  ///////////-----------Errors-----------//////////
  const [error, setError] = useState(null);

  const [error1, setError1] = useState();

  const [error2, setError2] = useState();

  const [downloadError, setDownloadError] = useState(null);

  useEffect(() => {
    const errorRef = doc(db, `users/${uid}/video-errors/${id}`);
    const unsubscribe = onSnapshot(errorRef, (snapshot) => {
      if (snapshot.exists()) {
        setError(snapshot.data());
      } else {
        setError(null);
      }
    });

    return () => {
      // Cleanup function to unsubscribe from the snapshot listener
      unsubscribe();
    };
  }, [uid, id]);

  const deleteError = async () => {
    const errorRef = doc(db, `users/${uid}/video-errors/${id}`);
    const videoRef = doc(db, `users/${userID}/videos/${id}`);

    await deleteDoc(errorRef);
    await updateDoc(videoRef, { renderFinished: true });
  };

  //////////////////----------////////////////////

  /////////////------Download Video Trigger-------/////////
  const initialRender = useRef(true);

  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //   } else {
  //     if (downloadUrl) {
  //       // Programmatically trigger the download
  //       const link = document.createElement("a");
  //       link.href = downloadUrl;
  //       link.download = "video.mp4";
  //       link.click();
  //       setDownloadStarted(true);
  //     }
  //   }
  // }, [downloadUrl]);

  ///----------------------------------------////

  ///////////-------------------////////////

  const fps = 25;

  //////Convert Video Function////

  const videoConversion = async () => {
    if (!selectedVoice) {
      return alert("Please select a voice first.");
    }
    if (loading) {
      return alert("Please wait for current preview to finish");
    }

    const words = cleanText.split(" ");
    if (words?.length - 1 > 2500) {
      return alert(
        `Your article is ${
          words.length - 1
        } words long which is above the maximum limit of 2500 words. `
      );
    }

    let tokensToConsume = Math.round(cleanText?.length * 0.5) + 500;

    if (tokensToConsume > availableTokens) {
      return alert(`You don't have enough tokens. Please top up.`);
    }

    setPreviewprogress(0);

    setDuration(1);

    setLoading(true);

    const videoRef = collection(db, `users/${userID}/video-trigger`);

    await addDoc(videoRef, { uid: userID, docid: id, speaker: selectedVoice });

    const videoRef2 = doc(db, `users/${userID}/videos/${id}`);

    await setDoc(
      videoRef2,
      {
        music,
        resolution,
        duration: 1,
        progress: null,
        sceneDurations: [],
        sequence: [],
        images: [],
        error: [],
        sceneDurations: [],
        Previewprogress: 0,
      },
      { merge: false }
    );
  };

  const videoRender = async () => {
    if (!updatedData || !updatedData[0]) {
      return alert(`Please create the video first`);
    }
    if (renderFinished === false) {
      return alert(`Please wait for current download to finish`);
    }

    handleCheckErrors();

    if (errorIndexes[0]) {
      return alert(
        `Please remove or replace all clips which are causing an error`
      );
    }

    await applyChanges();

    setRenderFinished(false);

    setProgress(0);
    setShowBar(true);
    setDownloadUrl();
    setError1();
    setError2();
    setRenderFinished(false);
    let tokensToConsume = Math.round(duration * 0.521) + 1000;

    if (tokensToConsume > availableTokens) {
      return alert(`You don't have enough tokens. Please top up.`);
    }
    const videoRef = doc(db, `users/${userID}/videos/${id}`);

    await updateDoc(videoRef, {
      duration: duration,
      progress: null,
      sceneDurations: sceneDurations,
      error1: null,
      error2: null,
      renderFinished: false,
    });

    const renderRef = collection(db, `users/${userID}/video-render/`);

    await addDoc(renderRef, { docid: id, uid: userID, title: title });
  };

  // console.log(`duration is ${duration}`);

  useEffect(() => {
    let isMounted = true;

    if (userID !== "" && id) {
      ////////////////////////////////
      const videoRef = doc(db, `users/${userID}/videos/${id}`);

      const setResolution2 = async () => {
        await updateDoc(videoRef, { resolution });
      };

      const unsubscribe = onSnapshot(videoRef, (snapshot) => {
        if (isMounted) {
          if (snapshot.exists()) {
            const title = snapshot.data()?.title;

            const sequence = snapshot.data().sequence;

            const renderFinished = snapshot.data().renderFinished;

            const progress = snapshot.data().progress;

            const Previewprogress = snapshot.data().Previewprogress;

            const imgs = snapshot.data().images;

            const music = snapshot.data().music;

            const voices = snapshot.data().voices;

            const error1 = snapshot.data()?.error1;

            const error2 = snapshot.data()?.error2;

            const timeout = snapshot.data()?.progress_timeout;

            // const framesRendered = snapshot.data()?.progress?.framesRendered;
            // const framesEncoded =
            //   snapshot.data()?.progress?.encodingStatus?.framesEncoded;

            if (voices) {
              setVoices(voices);
            }

            if (renderFinished) {
              setRenderFinished(true);
            }
            if (music) {
              setMusic(music);
            }

            setImages(imgs);

            if (Previewprogress) {
              setPreviewprogress(Number(Previewprogress));

              // if (PreviewProgress > 90)
            }

            if (progress) {
              setProgDone(progress.done);
              setProgress(progress.overallProgress);
              setDownloadUrl(progress.outputFile);
            }

            if (error1) {
              setError1(error1);
            }

            if (error2) {
              setError2(error2);
            }

            if (timeout) {
              setTimeout(timeout);
            }

            if (title) {
              setTitle(title);
            }

            if (sequence) {
              setSequences(sequence);
            }
          } else {
            // Handle the case where the document does not exist
          }
        }
      });

      if (userID) {
        setResolution2();
      }

      return () => {
        isMounted = false;

        unsubscribe();
      };
    }
  }, [userID, id, resolution]);

  useEffect(() => {
    if (sequences && sequences[0]) {
      console.log(`fetchAudioDurations Trigerred`);
      setLoading(false);

      const fetchAudioDurations = async () => {
        try {
          const durations = await Promise.all(
            sequences
              .sort((a, b) => a.index - b.index)
              .map(async (data) => await getAudioDurationInSeconds(data.url))
          );

          const roundedDurations = durations.map((duration) =>
            Math.ceil(duration * fps)
          );

          const totalDuration = roundedDurations.reduce(
            (accumulator, currentDuration) => accumulator + currentDuration,
            0
          );

          setDuration(totalDuration);

          const sceneDurationsWithFrom = roundedDurations.reduce(
            (acc, duration, index) => {
              const previousSum = acc[index - 1]
                ? acc[index - 1].from + acc[index - 1].durationInFrames
                : 0;
              let from;

              if (index === 0) {
                from = 0;
              } else {
                from = previousSum + 1;
              }
              const durationInFrames = duration;
              acc.push({ durationInFrames, from });
              return acc;
            },
            []
          );

          setSceneDurations(sceneDurationsWithFrom);
          const videoRef = doc(db, `users/${userID}/videos/${id}`);

          await updateDoc(videoRef, {
            sceneDurations: sceneDurations,
          });
        } catch (error) {
          console.error("Failed to fetch audio durations:", error);
        }
      };

      if (userID !== "") {
        fetchAudioDurations();
      }
    }
  }, [sequences, userID]);

  // console.log(sceneDurations);

  const playerRef = useRef(null);

  /////////////////////////////////////////////
  const [selectedDiv, setSelectedDiv] = useState(null);

  const viewportRef = useRef(null);

  const handleScrollToIndex = (index) => {
    // event.preventDefault();
    // const index = parseInt(event.target.elements.index.value, 10);

    if (Number.isInteger(index) && index >= 0 && index < sequences.length) {
      setSelectedDiv(index);
      setCurrentIndex(index);

      const element = viewportRef.current.children[index];
      const viewportWidth = viewportRef.current.offsetWidth;
      const elementWidth = element.offsetWidth;
      const marginLeft = parseInt(
        getComputedStyle(viewportRef.current).marginLeft,
        10
      );
      const scrollLeft =
        element.offsetLeft -
        (viewportWidth - elementWidth) / 2 -
        marginLeft +
        elementWidth / 2;

      viewportRef.current.scroll({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  ////////////////

  useEffect(() => {
    if (!sequences) return;
    const handlePlayerFrameUpdate = () => {
      const currentFrame = playerRef.current.getCurrentFrame();

      sceneDurations.forEach((sequence, index) => {
        const sequenceFromFrame = sequence.from;
        const sequenceToFrame = sequenceFromFrame + sequence.durationInFrames;

        if (
          currentFrame >= sequenceFromFrame &&
          currentFrame <= sequenceToFrame
        ) {
          handleScrollToIndex(index);
          // setCurrentIndex(index);
        }
      });
    };

    if (playerRef.current) {
      playerRef.current.addEventListener(
        "frameupdate",
        handlePlayerFrameUpdate
      );
    }

    return () => {
      // playerRef.current.removeEventListener(
      //   "frameupdate",
      //   handlePlayerFrameUpdate
      // );
    };
  }, [sequences, currentIndex]);

  /////////Seek To////////

  const handleDivClick = (index) => {
    setCurrentIndex(index);
    setSelectedDiv(index);
    handleScrollToIndex(index);
    const from = sceneDurations?.[index]?.from;

    playerRef.current.seekTo(from);
  };

  //////////////
  const [zoomLevel, setZoomLevel] = useState(0.4);

  const handleZoomIn = () => {
    if (zoomLevel < 1) {
      setZoomLevel(zoomLevel + 0.05);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.15) {
      setZoomLevel(zoomLevel - 0.05);
    }
  };

  /////////////////

  const [index, setIndex] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  // ///////////////////////////

  const removeField = (index, ...fieldNames) => {
    const newData = [...updatedData]; // Copy the array
    const obj = { ...newData[index] }; // Get the object at the specified index

    fieldNames.forEach((fieldName) => {
      delete obj[fieldName]; // Remove the field from the object
    });

    newData[index] = obj; // Update the object in the copied array
    setUpdatedData(newData); // Update the state with the modified array
  };

  const addMusic = async (musicUrl, musicName) => {
    const audioRef = doc(db, `users/${userID}/videos/${id}`);

    await updateDoc(audioRef, { music: { musicUrl, musicName } });
  };

  const updateMusicVolume = (musicVolume) => {
    const updatedChanges = [...changes];
    updatedChanges[0] = {
      ...updatedChanges[0],
      musicVolume,
    };
    setChanges(updatedChanges);
  };

  const updateVoiceVolume = (voiceVolume) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      voiceVolume,
    };
    setChanges(updatedChanges);
  };

  const updateImageUrl = (imageUrl) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      imageUrl,
    };
    setChanges(updatedChanges);
  };

  const updateImageArray = (imageUrls) => {
    if (!imageUrls[0]) {
      return alert(`Please search for images before using this option.`);
    }
    const updatedChanges = [...changes];

    const maxIndexes = Math.min(
      updatedData.length - currentIndex,
      imageUrls.length
    );

    // Loop through the imageUrls array and update the corresponding indexes in updatedChanges
    for (let i = 0; i < maxIndexes; i++) {
      updatedChanges[currentIndex + i] = {
        ...updatedChanges[currentIndex + i],
        imageUrl: imageUrls[i],
      };
    }

    setChanges(updatedChanges);
  };

  const updateVideoUrl = (videoUrl, videoImage, videoQuality) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      videoUrl,
      videoImage,
      videoQuality,
    };
    setChanges(updatedChanges);

    handleCheckErrors();
  };

  const updateHdVideos = (hdVideos) => {
    if (!hdVideos[0]) {
      return alert(`Please search for videos before using this option.`);
    }

    const updatedChanges = [...changes];

    const maxIndexes = Math.min(
      updatedData.length - currentIndex,
      hdVideos.length
    );

    // Loop through the hdVideos array and update the corresponding indexes in updatedChanges
    for (let i = 0; i < maxIndexes; i++) {
      const { link, image, quality } = hdVideos[i];

      updatedChanges[currentIndex + i] = {
        ...updatedChanges[currentIndex + i],
        videoUrl: link,
        videoImage: image,
        videoQuality: quality,
      };
    }

    setChanges(updatedChanges);
    // applyChanges();
  };

  const updateTextTranslate = (textTranslateX, textTranslateY) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textTranslateX,
      textTranslateY,
    };
    setChanges(updatedChanges);
    // applyChanges();
  };

  const updateTextFade = (textFade) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textFade,
    };
    setChanges(updatedChanges);
  };

  const updateTextZoom = (textZoom) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textZoom,
    };
    setChanges(updatedChanges);
  };

  const updateImageZoom = (imageZoom) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      imageZoom,
    };
    setChanges(updatedChanges);
  };

  const updateTextColor = (textColor) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textColor,
    };
    setChanges(updatedChanges);
  };

  const updateBackgroundColor = (backgroundColor) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      backgroundColor,
    };
    setChanges(updatedChanges);
  };

  const updateTextBg = (textBg) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textBg,
    };
    setChanges(updatedChanges);
  };

  const updateTextWidth = (textWidth) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textWidth,
    };
    setChanges(updatedChanges);
  };

  const updateTextHeight = (textHeight) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textHeight,
    };
    setChanges(updatedChanges);
  };

  const updateTextPadding = (textPadding) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textPadding,
    };
    setChanges(updatedChanges);
  };

  const updateTextMargin = (textMargin) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textMargin,
    };
    setChanges(updatedChanges);
  };

  const updateFontSize = (fontSize) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      fontSize,
    };
    setChanges(updatedChanges);
  };

  const updateTextAlign = (textAlign) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      textAlign,
    };
    setChanges(updatedChanges);
  };

  /////------ Update Position------///

  const updatePosition = (position) => {
    const updatedChanges = [...changes];
    const { justifyContent, alignItems } = getPositionStyles(position);
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      justifyContent,
      alignItems,
    };
    setChanges(updatedChanges);
  };

  const getPositionStyles = (position) => {
    switch (position) {
      case 1:
        return { justifyContent: "flex-start", alignItems: "flex-start" };
      case 2:
        return { justifyContent: "center", alignItems: "flex-start" };
      case 3:
        return { justifyContent: "flex-end", alignItems: "flex-start" };
      case 4:
        return { justifyContent: "flex-start", alignItems: "center" };
      case 5:
        return { justifyContent: "center", alignItems: "center" };
      case 6:
        return { justifyContent: "flex-end", alignItems: "center" };
      case 7:
        return { justifyContent: "flex-start", alignItems: "flex-end" };
      case 8:
        return { justifyContent: "center", alignItems: "flex-end" };
      case 9:
        return { justifyContent: "flex-end", alignItems: "flex-end" };
      default:
        return { justifyContent: "center", alignItems: "center" };
    }
  };

  /////////Update Font Family//////////
  const updateFontFamily = (selectedFontFamily) => {
    const updatedChanges = [...changes];
    updatedChanges[currentIndex] = {
      ...updatedChanges[currentIndex],
      fontFamily: selectedFontFamily,
    };
    setChanges(updatedChanges);
    setFontFamily(selectedFontFamily);
  };

  ///////----------------////////

  const applyChanges = async () => {
    setLoading3(true);

    try {
      const videoRef = doc(db, `users/${userID}/videos/${id}`);
      const docSnapshot = await getDoc(videoRef);

      if (docSnapshot.exists()) {
        const videoData = docSnapshot.data();

        videoData.sequence = updatedData;
        videoData.sceneDurations = sceneDurations;

        await updateDoc(videoRef, videoData);
        setChanges([]);
        setLoading3(false);
      }
    } catch (error) {
      alert(`Error updating document: ${error}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Combine the sequences and changes to get the updated data
    const updatedData = sequences?.map((sequence, index) => {
      const change = changes[index];
      return {
        ...sequence,
        ...change,
      };
    });

    setUpdatedData(updatedData);
  }, [changes, sequences]);

  useEffect(() => {
    if (progress === 0) {
      setInit(true);
    } else {
      setInit(false);
    }
  }, [progress]);

  const applyChangesAtIndex = async (index, heading) => {
    const changesToApply = changes[index];

    // Create an array with the length of sequences
    const updatedChanges = Array(sequences.length);

    // Apply changes to the matching indexes
    for (let i = 0; i < sequences.length; i++) {
      if (sequences[i].meta === heading) {
        updatedChanges[i] = changesToApply;
      }
    }

    const updatedData = sequences?.map((sequence, i) => {
      const change = updatedChanges[i];
      if (change) {
        return {
          ...sequence,
          ...change,
        };
      }
      return sequence;
    });

    setUpdatedData(updatedData);
    // setApplyTrig(!applyTrig);
  };

  // useEffect(() => {
  //   applyChanges();
  // }, [applyTrig]);

  const handleCheckErrors = () => {
    if (updatedData && updatedData.length > 0 && updatedData[0]) {
      setErrorIndexes([]);

      videoRefs.current.forEach((videoRef, index) => {
        if (updatedData?.[index]?.videoUrl) {
          videoRef.src = updatedData?.[index]?.videoUrl;
          videoRef.load();
        }
      });

      setCheckErrors(true);
    }
  };

  useEffect(() => {
    if (duration === 1) {
      setLoading2(true);
    } else {
      setLoading2(false);
    }
  }, [duration]);

  useEffect(() => {
    if (duration > 1) {
      handleCheckErrors();
    }
  }, [loading2]);

  ///---------Check Subscription Status------///
  const [activePlan, setActivePlan] = useState();

  useEffect(() => {
    if (userID && userID.length > 0) {
      async function checkSubscriptionStatus() {
        try {
          const subscriptionRef = collection(
            db,
            `users/${userID}/subscriptions`
          );
          const snapshot = await getDocs(subscriptionRef);
          const tokensRef = doc(
            db,
            `users/${userID}/subscriptions/active-plan`
          );

          if (snapshot.empty) {
            ///?do something ?///
            setActivePlan("free");
          } else {
            let isActive = false;
            snapshot.forEach((doc) => {
              if (doc.data().status === "active") {
                isActive = true;
                setActivePlan(doc.data().items[0].price.product.name); // set activePlan based on active subscription
              }
            });
          }
        } catch (error) {}
      }
      checkSubscriptionStatus();
    }
  }, [userID]);

  //////////----------------------////////////
  const videoRefs = useRef([]);
  const [errorIndexes, setErrorIndexes] = useState([]);
  const [bulkCheck, setBulkCheck] = useState(false);

  const handleVideoError = (index) => {
    setErrorIndexes((prevIndexes) => [...prevIndexes, index + 1]);
  };

  // Your updatedData array
  //   const updatedData = [
  //     // Array elements
  //   ];

  useEffect(() => {
    if (updatedData) {
      handleCheckErrors();
      setBulkCheck(false);
    }
  }, [updatedData]);

  // const handleCheckErrors = () => {
  //   setErrorIndexes([]);
  //   videoRefs.current.forEach((videoRef, index) => {
  //     videoRef.src = updatedData?.[index]?.videoUrl;
  //     videoRef.load();
  //   });
  //   setCheckErrors(true);
  // };

  /////////////////////
  const [screenSize, setScreenSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        // window.location.href = "https://sparker.ai/screen-size-2";
        setScreenSize(true);
      } else {
        setScreenSize(false);
      }
    };

    handleResize(); // check on initial load
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

  // console.log(activePlan);

  return (
    <>
      {screenSize && activePlan !== "free" ? (
        <div className="screen-size">
          <h2>Your Screen size is too small.</h2> Most mobile phone screens are
          too small to display the app. For a better experience please use the
          app on your laptop, tablet or desktop. We also advise that you
          maximise your borwser window when using the app.
        </div>
      ) : (
        ""
      )}
      {updatedData &&
        updatedData.length > 0 &&
        updatedData[0] &&
        updatedData.map(
          (data, index) =>
            data?.videoUrl && (
              <video
                key={index}
                ref={(ref) => (videoRefs.current[index] = ref)}
                onError={() => handleVideoError(index)}
                style={{ display: "none" }}
              />
            )
        )}

      {/* <button onClick={handleCheckErrors}>Check Errors</button> */}
      {/* {errorIndexes.length > 0 && (
        <p>
          Errors occurred for video components at indexes:{" "}
          {errorIndexes.join(", ")}
        </p>
      )} */}

      {!activePlan ? (
        <div className="loading-loader"></div>
      ) : (
        // activePlan === "free" ? (
        //   <VideoPlan db={db} />
        // ) :
        <>
          <div className="video-container">
            <div className="column left-column">
              <TopButtons
                selectedDiv2={selectedDiv2}
                setSelectedDiv2={setSelectedDiv2}
              />
              <div className="left-bar">
                {selectedDiv2 === 0 ? (
                  <Voices
                    voices={voices}
                    setSelectedVoice={setSelectedVoice}
                    selectedVoice={selectedVoice}
                  />
                ) : (
                  ""
                )}
                {selectedDiv2 === 1 ? (
                  <Images
                    updateImageUrl={updateImageUrl}
                    updateImageArray={updateImageArray}
                  />
                ) : (
                  ""
                )}
                {selectedDiv2 === 2 ? (
                  <Videos
                    updateVideoUrl={updateVideoUrl}
                    updateHdVideos={updateHdVideos}
                    setBulkCheck={setBulkCheck}
                    handleCheckErrors={handleCheckErrors}
                  />
                ) : (
                  ""
                )}
                {selectedDiv2 === 3 ? <Music addMusic={addMusic} /> : ""}
              </div>
            </div>
            <div className="column center-column">
              <ErrorMessage
                error={error}
                setError={setError}
                deleteError={deleteError}
              />

              {download ? (
                <div className="download-modal">
                  <div
                    className="close-txt-anima"
                    onClick={() => {
                      setDownload(false);
                    }}
                  >
                    x
                  </div>
                  <div className="preview-voice">
                    {duration && cleanText && duration > 1
                      ? `We estimate $${(duration * 0.48 * 0.0005).toFixed(
                          2
                        )} credit will be used to render this video. Please note once download has started it cannot be stopped.`
                      : "please generate a preview first !"}
                  </div>
                  <div
                    className="start-video-preview"
                    onClick={() => {
                      videoRender();
                    }}
                  >
                    Start Download
                  </div>

                  {showBar ? (
                    <>
                      {error1 ? (
                        <div className="download-progress2">
                          <span className="render-error">
                            Oops! something went wrong. Don't worry you've not
                            been charged any tokens. Please wait and try again
                            after 5-10 mins. If the problem persists please
                            contact us using our contact page.
                          </span>
                        </div>
                      ) : (
                        <>
                          {error2 ? (
                            <div className="download-progress2">
                              {" "}
                              <span className="render-error">{error2}</span>
                            </div>
                          ) : (
                            <>
                              {progress === 0 ? (
                                <div className="download-bar">
                                  <div className="progress-text">
                                    please wait...
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {progress < 1 ? (
                                    <div className="download-bar">
                                      <div
                                        className="download-progress"
                                        style={{ width: `${progress * 100}%` }}
                                      ></div>
                                      <div className="progress-text">
                                        {`${Math.round(progress * 100)}%`}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="download-progress2">
                                      <span>Download complete! </span>
                                      <a href={downloadUrl}>Download link</a>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="video-render-buttons2">
                <div
                  className="save-button save-video"
                  onClick={() => setPreview(!preview)}
                >
                  Generate Preview
                </div>
              </div>

              {preview ? (
                <div className="preview-modal">
                  <div
                    className="close-txt-anima"
                    onClick={() => {
                      setPreview(false);
                    }}
                  >
                    x
                  </div>
                  <div className="preview-voice">
                    Voice:{" "}
                    <span>
                      {selectedVoice?.name
                        ? `${selectedVoice.name}`
                        : "None selected"}
                    </span>
                  </div>

                  <div className="preview-voice">
                    We estimate ${(cleanText.length * 0.000165).toFixed(2)}{" "}
                    credit will be used to generate this AI voice track.
                  </div>
                  <div
                    className="start-video-preview"
                    onClick={() => videoConversion()}
                  >
                    Start
                  </div>
                </div>
              ) : (
                ""
              )}

              {checkErrors ? (
                <>
                  {errorIndexes.length > 0 ? (
                    <div className="preview-modal2">
                      <div
                        className="close-txt-anima"
                        onClick={() => {
                          setCheckErrors(false);
                        }}
                      >
                        x
                      </div>
                      <div className="error-media">
                        Error loading media at scences:{" "}
                        {errorIndexes.join(", ")}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              <div className="video-render-buttons">
                <div className="save-button save-video" onClick={applyChanges}>
                  Save Progress
                </div>
                <div
                  className="save-button download-video"
                  onClick={() => {
                    setDownload(!download);
                  }}
                >
                  Download Video
                </div>
              </div>

              {updatedData || Previewprogress || sequences ? (
                <div className="player" style={{ zoom: zoomLevel }}>
                  <PlayerContainer
                    duration={duration}
                    playerRef={playerRef}
                    updatedData={updatedData}
                    sceneDurations={sceneDurations}
                    music={music}
                    resolution={resolution}
                  />

                  <div
                    className={loading || loading3 ? "video-loader" : "hide"}
                  ></div>
                  <div className={loading ? "video-percent" : "hide"}>
                    <span> {Math.ceil(Previewprogress)} %</span>
                  </div>
                  <div
                    className={loading2 && !loading ? "video-percent2" : "hide"}
                  >
                    <span> Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="video-message">
                  Please Select a Voice and then click 'Generate Preview' to get
                  started.{" "}
                </div>
              )}

              <div className="resolution-buttons">
                {/* <span>desktop:</span> */}
                <div>
                  <button
                    className={selectedResolution === 1 ? "selected11" : ""}
                    onClick={() => {
                      setSelectedResolution(1);
                      setResolution({ width: 1920, height: 1080, res: "hd" });
                      setZoomLevel(0.4);
                    }}
                  >
                    Desktop
                  </button>
                  {/* <button
                    className={selectedResolution === 2 ? "selected11" : ""}
                    onClick={() => {
                      setSelectedResolution(2);
                      setResolution({ width: 3840, height: 2160, res: "4k" });
                      setZoomLevel(0.2);
                    }}
                  >
                    4K
                  </button> */}
                </div>
              </div>
              <div className="resolution-buttons2">
                {/* <span>mobile:</span> */}
                <div>
                  <button
                    className={selectedResolution === 3 ? "selected11" : ""}
                    onClick={() => {
                      setSelectedResolution(3);
                      setResolution({ width: 1080, height: 1920, res: "hd" });
                      setZoomLevel(0.3);
                    }}
                  >
                    Mobile
                  </button>
                  {/* <button
                    className={selectedResolution === 4 ? "selected11" : ""}
                    onClick={() => {
                      setSelectedResolution(4);
                      setResolution({ width: 2160, height: 3840, res: "4k" });
                      setZoomLevel(0.15);
                    }}
                  >
                    4K
                  </button> */}
                </div>
              </div>
              <div className="zoom-buttons">
                <button onClick={handleZoomIn}>+</button>
                <button onClick={handleZoomOut}>-</button>
              </div>
            </div>
            <div className="column right-column">
              <CurrentScene
                currentIndex={currentIndex}
                setChanges={setChanges}
                setSceneDurations={setSceneDurations}
                sceneDurations={sceneDurations}
                updateVideoUrl={updateVideoUrl}
                updateImageUrl={updateImageUrl}
                updateTextTranslate={updateTextTranslate}
                updateImageZoom={updateImageZoom}
                updateTextFade={updateTextFade}
                updateTextZoom={updateTextZoom}
                sequences={sequences}
                updatedData={updatedData}
                setUpdatedData={setUpdatedData}
                updateTextColor={updateTextColor}
                updateBackgroundColor={updateBackgroundColor}
                updateTextBg={updateTextBg}
                updateTextWidth={updateTextWidth}
                updateTextHeight={updateTextHeight}
                applyChanges={applyChanges}
                updatePosition={updatePosition}
                setFontFamily={setFontFamily}
                fontFamily={fontFamily}
                updateFontFamily={updateFontFamily}
                selectedDiv3={selectedDiv3}
                setSelectedDiv3={setSelectedDiv3}
                updateTextPadding={updateTextPadding}
                updateTextMargin={updateTextMargin}
                updateFontSize={updateFontSize}
                applyChangesAtIndex={applyChangesAtIndex}
                updateTextAlign={updateTextAlign}
                updateMusicVolume={updateMusicVolume}
                updateVoiceVolume={updateVoiceVolume}
                music={music}
                removeField={removeField}
              />
            </div>
          </div>
          <div className="bottom-row">
            <ScenesReel
              sequences={sequences}
              updatedData={updatedData}
              handleDivClick={handleDivClick}
              viewportRef={viewportRef}
              selectedDiv={selectedDiv}
              images={images}
              errorIndexes={errorIndexes}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Video;
