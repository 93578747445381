import React, { useState } from "react";

const PexelVideos = ({
  updateVideoUrl,
  updateHdVideos,
  setBulkCheck,
  handleCheckErrors,
}) => {
  const [videos, setVideoes] = useState([[]]);
  const [hdVideos, setHdVideos] = useState([]);

  const [showVideo, setShowVideo] = useState();
  const [videoFiles, setVideoFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1); //new state variable

  const handleSubmit = async (e) => {
    e.preventDefault();
    setVideoes([[]]);
    try {
      const response = await fetch(
        `https://api.pexels.com/videos/search?query=${searchTerm}&per_page=5&page=1`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      setVideoes([data.videos]);
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  const handleMore = async () => {
    console.log(page);

    try {
      const response = await fetch(
        `https://api.pexels.com/videos/search?query=${searchTerm}&per_page=80&page=${page}`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      videos.push(data.videos);

      console.log(videos);
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  const handleLoadMore = async () => {
    setPage(page + 1);
    handleMore();
  };

  const clearPhotos = async () => {
    setVideoes([[]]);
  };

  const showOptions = (videoFiles, i) => {
    // console.log(i);

    setVideoFiles(videoFiles);
    setShowVideo(i);
  };

  const filterHDVideos = () => {
    const hdVideos = [];

    videos.forEach((videoArr) => {
      videoArr.forEach((video) => {
        const image = video.image;
        const hdVideo = video.video_files.find((file) => file.quality === "hd");

        if (hdVideo) {
          hdVideo.image = image;
          hdVideos.push(hdVideo);
        }
      });
    });

    setHdVideos(hdVideos);
    updateHdVideos(hdVideos);
    setBulkCheck(true);
  };

  console.log(videos);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          className="gen-input"
          type="text"
          placeholder="Search videos"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="list-gen-btn" type="submit">
          Search
        </button>
      </form>
      {/* <button onClick={filterHDVideos}>Filter HD Videos</button> */}
      <button
        className="bulk-add"
        onClick={() => {
          filterHDVideos();
        }}
      >
        Bulk add videos
      </button>
      {videos.map((photosArray, i) => (
        <div key={i} className="photos-container">
          {photosArray.map((photo, j) => (
            <div key={`${i}-${j}`}>
              <img
                src={photo?.image}
                alt={photo?.user?.name}
                onClick={() => {
                  showOptions(photo?.video_files, j);
                }}
              />

              {showVideo === j ? (
                <div className="show-videos-modal">
                  <span
                    onClick={() => {
                      setShowVideo();
                    }}
                  >
                    x
                  </span>
                  <div className="select-resolution">Select a Resolution</div>
                  {videoFiles.map((video, k) => (
                    <div
                      key={`${k}-2`}
                      className="video-options"
                      onClick={() => {
                        updateVideoUrl(video.link, photo?.image, video.quality);
                      }}
                    >
                      <div className="video-quality">{video.quality}</div>
                      <div className="video-res">
                        {video.width} x {video.height}
                      </div>
                      <div className="video-res">FPS: {video?.fps}</div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      ))}

      <div
        className={videos?.[0].length > 0 ? "photos-btm" : "hide"}
        onClick={handleLoadMore}
      >
        <div className="loadmore" onClick={handleLoadMore}>
          Load More
        </div>
        <div className="clear-photos" onClick={clearPhotos}>
          Clear All
        </div>
      </div>
    </div>
  );
};

export default PexelVideos;
