import React, { useState, useEffect, useRef } from "react";
import htmlToImage from "html-to-image";

import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import {
  collection,
  getDocs,
  getDoc,
  set,
  addDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import AllTemplates from "../Components/AllTemplates";
import AllImages from "../Components/AllImages";
import AllVideos from "../Components/AllVideos";
import SurveyForm from "../Components/SurveyForm";
import WizardForm from "../Components/WizardForm";
import Niches from "../Components/Niches";
import AutoVideo from "../VideoComponents/AutoVideo";
// import AllVideos
const INITIAL_LIMIT = 10000;

const Main = ({ db }) => {
  const { logOut, user } = UserAuth();
  const navigate = useNavigate();

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  // user.emailVerified ? navigate('/account') : user ? navigate('/verify') : navigate('/');
  const [menu, setMenu] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [docs, setDocs] = useState([]);
  const [limitCount, setLimitCount] = useState(INITIAL_LIMIT);
  const [change, setChange] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [mainPage, setMainPage] = useState(true);

  const [templates, setTemplates] = useState(false);

  const [docsTab, setDocsTab] = useState(true);

  const [imgsTab, setImgsTab] = useState(false);
  const [vidsTab, setVidsTab] = useState(false);
  const [keyTab, setKeyTab] = useState(false);
  const [running, setRunning] = useState(false);

  const [wizard, setWizard] = useState(false);

  const [add, setAdd] = useState(1);
  const [deleteDocs, setdeleteDocs] = useState(false);
  const [deleteImgs, setdeleteImgs] = useState(false);
  const [deleteVids, setdeleteVids] = useState(false);

  const [docDel, setDocDel] = useState();
  const [imgDel, setImgDel] = useState();

  const [title, setTitle] = useState("Untitled");

  const [placeholder, setPlaceholder] = useState("Search documents by name");

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {}
  };

  ////////////-------------------/////////////

  const addNewDoc = async (templ) => {
    let obj = {
      start: 0,
    };
    const docRef = await addDoc(docsRef, obj);

    // Get the ID of the newly created document
    const docId = docRef.id;

    setChange(!change);
    navigate(`/doc/${docId}/${templ}`);
  };

  ////----------Add Niche--------///////
  const nicheRef2 = collection(db, `users/${userID}/niches`);

  const [newNicheValue, setNewNicheValue] = useState("");

  const addNewNiche = async (name) => {
    let obj = {
      name,
    };
    const nicheRef = await addDoc(nicheRef2, obj);

    // Get the ID of the newly created document
    const nicheId = nicheRef.id;

    setChange(!change);

    const url = new URL(`/niche/${nicheId}`, window.location.href);
    window.open(url.toString(), "_blank");

    // navigate(`/niche/${nicheId}`);
  };

  const handleInputChange = (event) => {
    setNewNicheValue(event.target.value);
  };

  ////////////-----------------/////////////

  //////////-------SEARCH BAR---////////////
  let filteredDocuments =
    searchQuery === ""
      ? docs
      : docs.filter((document) => {
          if (document.meta && document.meta.title) {
            return document.meta.title
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }
          return false;
        });
  ////////--------------------/////////////

  useEffect(() => {
    if (userID && userID.length > 0) {
      const getDocsData = async () => {
        const docsRef = collection(db, `users/${userID}/documents`);

        const q = query(docsRef, limit(limitCount));

        try {
          const querySnapshot = await getDocs(q);

          const docsData = [];

          const metadataPromises = querySnapshot.docs.map(async (snap) => {
            const imageRef = doc(
              db,
              `users/${userID}/documents/${snap.id}/aipics/featured`
            );

            const imageSnap = await getDoc(imageRef);
            let imageUrl;
            if (imageSnap.exists()) {
              const data = imageSnap.data();
              imageUrl = data && data.hostedUrl;
            } else {
              imageUrl = undefined;
            }

            const metaDocRef = doc(
              db,
              `users/${userID}/documents/${snap.id}/meta/meta`
            );
            const metaDocSnap = await getDoc(metaDocRef);

            if (metaDocSnap.exists()) {
              const metaDocData = metaDocSnap.data();
              return {
                id: snap.id,
                meta: metaDocData,
                img: imageUrl
                  ? `${imageUrl}?t=${Date.now()}`
                  : `http://sparker.ai/wp-content/uploads/2023/04/No-Image.png`,
              };
            } else {
              const defaultMetaDocData = { title: "Untitled" };
              return {
                id: snap.id,
                meta: defaultMetaDocData,
                img: `http://sparker.ai/wp-content/uploads/2023/04/Empty.png`,
              };
            }
          });

          const metadata = await Promise.all(metadataPromises);
          setDocs(metadata);
        } catch (error) {
          console.error(error);
        }
      };

      getDocsData();
    }

    return () => {
      // Cleanup function
    };
  }, [userID, change]);

  ////////////----------Scroll and open add button---------------////////////////
  const [addAppear, setaddAppear] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const shouldTopbarBeSolid = window.pageYOffset > 280;
      setaddAppear(shouldTopbarBeSolid);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  ////////////---------------------------------------//////////////
  const docTab = () => {
    setImgsTab(false);
    setVidsTab(false);
    setKeyTab(false);

    setDocsTab(true);
    setPlaceholder("Search documents by name");
    setSearchQuery("");
    setAdd(1);
  };

  const imgTab = () => {
    setImgsTab(true);
    setVidsTab(false);
    setKeyTab(false);

    setDocsTab(false);
    setAdd(true);
    setPlaceholder("Search images by prompt");
    setSearchQuery("");
  };

  const vidTab = () => {
    setVidsTab(true);
    setImgsTab(false);
    setKeyTab(false);

    setDocsTab(false);
    setAdd(true);
    setPlaceholder("Search videos by title");
    setSearchQuery("");
  };

  const keywordsTab = () => {
    setKeyTab(true);
    setImgsTab(false);
    setVidsTab(false);

    setDocsTab(false);
    setPlaceholder("Search niches by name");
    setSearchQuery("");
  };
  ////-----------------Zoom Image and Videos-----------------////////
  const [zoom, setZoom] = useState(false);
  const [zoom2, setZoom2] = useState(false);

  const [imgLink, setImgLink] = useState();
  const [vidLink, setVidLink] = useState();

  const [promp, setPromp] = useState();
  const [vidTitle, setVidTitle] = useState();
  const [vidDescription, setVidDescription] = useState();

  const [vidDocId, setVidDocId] = useState();

  const [date, setDate] = useState();
  const [date2, setDate2] = useState();

  const [docId, setDocId] = useState();
  const [vidId, setVidId] = useState();

  const [imageId, setImageId] = useState();

  const zoomImg = (image) => {
    setImgLink(image.hostedUrl);
    let promp = image.promp.slice(0, 700);
    if (image.promp.length > 700) {
      promp += "...";
    }
    setPromp(promp);
    setDate(image.date);
    setDocId(image.docId);
    setImageId(image.id);
  };

  const zoomVideo = (video) => {
    setVidLink(video.url);

    setVidTitle(video.title);
    setVidDescription(video?.description);

    setVidDocId(video.docid);

    setDate2(video.timestamp);
    setVidId(video.id);
  };

  function downloadImg(imgUrl) {
    const link = document.createElement("a");
    link.href = imgUrl;
    link.download = "image.png"; // Change the filename here as desired
    link.target = "_blank"; // Open the link in a new window

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const deleteImage = async (docID, imageID) => {
    const docRef = doc(
      collection(db, `users/${userID}/documents/${docID}/aipics`),
      imageID
    );
    await deleteDoc(docRef);
    setChange(!change);
  };

  const deleteVid = async (vidID) => {
    const docRef = doc(
      collection(db, `users/${userID}/completed-videos/`),
      vidID
    );
    await deleteDoc(docRef);
    setChange(!change);
  };
  ////////------------SMALL MENU-------------------///////////////

  const [smallMenu, setSmallMenu] = useState();
  const [menuVisible, setMenuVisible] = useState(false);

  //////////
  const deleteIt = async (id) => {
    let delID = id.id;
    const docRef = doc(docsRef, delID);
    await deleteDoc(docRef);
    setMenuVisible(false);
    setChange(!change);
  };

  //////////-------------------------------/////////////
  const [niche, setNiche] = useState(false);

  const docsRef = collection(db, `users/${userID}/documents`);

  ////////-----------Set Active Subscription in Database---------------/////
  const [portal, setPortal] = useState(false);
  const [activePlan, setActivePlan] = useState("free");

  useEffect(() => {
    if (userID && userID.length > 0) {
      async function checkSubscriptionStatus() {
        try {
          const subscriptionRef = collection(
            db,
            `users/${userID}/subscriptions`
          );
          const snapshot = await getDocs(subscriptionRef);
          const tokensRef = doc(
            db,
            `users/${userID}/subscriptions/active-plan`
          );

          if (snapshot.empty) {
            setPortal(false); // subscription collection doesn't exist
          } else {
            let isActive = false;
            snapshot.forEach((doc) => {
              if (doc.data().status === "active") {
                isActive = true;
                setActivePlan(doc.data().items[0].price.product.name); // set activePlan based on active subscription
              }
            });
            setPortal(isActive); // set portal based on subscription status
          }
        } catch (error) {}
      }
      checkSubscriptionStatus();
    }
  }, [userID]);

  useEffect(() => {
    async function checkSubscriptionStatus() {
      try {
        if (userID) {
          const tokensRef = doc(db, `users/${userID}/active-plan/active-plan`);

          await setDoc(tokensRef, { active_plan: activePlan });
        }
      } catch (error) {}
    }
    checkSubscriptionStatus();
  }, [activePlan, userID]);

  //////------------------------------------------------------------//////

  /////////-----------NICHES-------//////////
  const [nichesData, setNichesData] = useState([]);

  useEffect(() => {
    const fetchNichesData = async () => {
      try {
        const nichesRef = collection(db, `users/${userID}/niches`);
        const snapshot = await getDocs(nichesRef);
        const nichesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setNichesData(nichesData);
      } catch (error) {
        console.error("Error fetching niches data:", error);
      }
    };

    fetchNichesData();
  }, [db, userID]);

  return (
    <>
      {userID ? (
        <>
          {wizard ? (
            <WizardForm
              db={db}
              setWizard={setWizard}
              addNewDoc={addNewDoc}
              running={running}
              setRunning={setRunning}
            />
          ) : (
            <></>
          )}
          {niche && (
            <div className="suggestions-box-container">
              <div
                className="close"
                onClick={() => {
                  setNiche(false);
                }}
              >
                close
              </div>
              <input
                className="suggestions-search"
                type="text"
                value={newNicheValue}
                onChange={handleInputChange}
                placeholder="Enter Niche Name"
              />
              <button
                className="get-suggestions"
                onClick={() => {
                  addNewNiche(newNicheValue);
                }}
              >
                Add New Niche
              </button>
            </div>
          )}{" "}
          <SurveyForm db={db} userID={userID} setWizard={setWizard} />
          <div className={deleteDocs ? "delete-zoom" : "hide"}>
            <h4>Delete?</h4>
            <span>Please note this action is irreversible!</span>
            <span>
              {" "}
              Are you sure you want to permanently delete this document?
            </span>
            <div className="delete-btns">
              <button
                className="delete"
                onClick={() => {
                  deleteIt(docDel);
                  setChange(!change);
                  setdeleteDocs(false);
                }}
              >
                Delete Forever
              </button>
              <button
                className="cancel"
                onClick={() => {
                  setdeleteDocs(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className={deleteImgs ? "delete-zoom" : "hide"}>
            <h4>Delete?</h4>
            <span>Please note this action is irreversible!</span>
            <span>
              {" "}
              Are you sure you want to permanently delete this image?
            </span>
            <div className="delete-btns">
              <button
                className="delete"
                onClick={() => {
                  deleteImage(docId, imageId);
                  setChange(!change);
                  setdeleteImgs(false);
                  setZoom(false);
                }}
              >
                Delete Forever
              </button>
              <button
                className="cancel"
                onClick={() => {
                  setdeleteImgs(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className={deleteVids ? "delete-zoom" : "hide"}>
            <h4>Delete?</h4>
            <span>Please note this action is irreversible!</span>
            <span>
              {" "}
              Are you sure you want to permanently delete this video?
            </span>
            <div className="delete-btns">
              <button
                className="delete"
                onClick={() => {
                  deleteVid(vidId);
                  setChange(!change);
                  setdeleteVids(false);
                  setZoom2(false);
                }}
              >
                Delete Forever
              </button>
              <button
                className="cancel"
                onClick={() => {
                  setdeleteVids(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          {/* ///////////////Zoom Videos START//////////////// */}
          <div className={zoom2 ? "image-zoom" : "hide"}>
            <div
              className="close"
              onClick={() => {
                setZoom2(false);
              }}
            >
              close
            </div>
            <div className="image-wraper">
              <div className="image">
                <video
                  src={vidLink}
                  style={{ maxHeight: "500px", maxWidth: "700px" }}
                  type="video/mp4"
                  controls
                />
              </div>
              <div className="meta">
                <div className="prompt">
                  <h4>Title:</h4>
                  <span>{vidTitle}</span>
                  <h4>Description:</h4>
                  <span>{vidDescription}</span>
                </div>
                <div className="date">
                  created:{" "}
                  {new Date(date2).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </div>
                <button onClick={() => downloadImg(vidLink)}>Download</button>
                <button onClick={() => navigate(`/doc/${vidDocId}/1`)}>
                  Open in Doc Editor
                </button>
                <button onClick={() => navigate(`/video/${vidDocId}/`)}>
                  Open in Video Studio
                </button>

                <button
                  className="deleteImg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setdeleteVids(true);
                    // setZoom(false);
                  }}
                >
                  Permanently delete video
                </button>
              </div>
            </div>
          </div>
          {/*///////////////Zoom Videos END//////////////// */}
          {/* ///////////////Zoom Images START//////////////// */}
          <div className={zoom ? "image-zoom" : "hide"}>
            <div
              className="close"
              onClick={() => {
                setZoom(false);
              }}
            >
              close
            </div>
            <div className="image-wraper">
              <div className="image">
                <img src={imgLink} />
              </div>
              <div className="meta">
                <div className="prompt">
                  <h4>Prompt:</h4>
                  <span>{promp}</span>
                </div>
                <div className="date">
                  created:{" "}
                  {new Date(date).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </div>
                <button onClick={() => downloadImg(imgLink)}>Download</button>
                <button
                  className="deleteImg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setdeleteImgs(true);
                  }}
                >
                  Permanently delete image
                </button>
              </div>
            </div>
          </div>
          {/*///////////////Zoom Images END//////////////// */}
          <div
            className="main-page"
            onClick={() => {
              setMenu(false);
              setMenuVisible(false);
              setZoom(false);
              setZoom2(false);
              setdeleteDocs(false);
            }}
          >
            <AllTemplates
              templates={templates}
              setTemplates={setTemplates}
              addNewDoc={addNewDoc}
            />
            <Navbar
              Link={Link}
              db={db}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              mainPage={mainPage}
              menu={menu}
              setMenu={setMenu}
              placeholder={placeholder}
            />
            <div
              className="main-templates"
              onClick={() => {
                setMenu(false);
              }}
            >
              <div
                className="all-templates"
                onClick={() => {
                  setTemplates(true);
                  setMenu(false);
                }}
              >
                All Templates
              </div>
              <div
                className="templates-container"
                onClick={() => {
                  setMenu(false);
                }}
              >
                <div
                  className="add-doc templ-item"
                  onClick={() => {
                    addNewDoc(1);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/Add-new-Doc.png" />
                  <span>New Blank</span>
                </div>
                <div
                  className="listicle templ-item"
                  onClick={() => {
                    addNewDoc(4);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/Listicle-3.png" />
                  <span>Listicle</span>
                </div>
                <div
                  className="comparison templ-item"
                  onClick={() => {
                    addNewDoc(2);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/Comparison-1.png" />
                  <span>Comparison</span>
                </div>
                <div
                  className="creative templ-item"
                  onClick={() => {
                    addNewDoc(5);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/Creative-Writing.png" />
                  <span>Creative Writing</span>
                </div>
                <div
                  className="video-script templ-item"
                  onClick={() => {
                    addNewDoc(3);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/how-article.png" />
                  <span>Tutorial</span>
                </div>
                {/* <div
                  className="video-script templ-item"
                  onClick={() => {
                    addNewDoc(9);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/video-script-5.png" />
                  <span>Video Script</span>
                </div> */}

                <div
                  className="ai-art templ-item"
                  onClick={() => {
                    addNewDoc(true);
                  }}
                >
                  <img src="http://sparker.ai/wp-content/uploads/2023/04/AI-ART.png" />
                  <span>AI Art</span>
                </div>
              </div>
            </div>
            <div
              className="main-container"
              onClick={() => {
                setMenu(false);
              }}
            >
              <div className="main-tabs">
                <div className={docsTab ? "tab-focus" : "tab"} onClick={docTab}>
                  Documents
                </div>{" "}
                <div className={vidsTab ? "tab-focus" : "tab"} onClick={vidTab}>
                  {" "}
                  Videos
                </div>
                <div className={imgsTab ? "tab-focus" : "tab"} onClick={imgTab}>
                  {" "}
                  AI Images
                </div>
                <div
                  className={keyTab ? "tab-focus" : "tab"}
                  onClick={keywordsTab}
                >
                  {" "}
                  Keywords
                </div>
              </div>
              <div className={imgsTab ? "documents-wraper" : "hide"}>
                <AllImages
                  docsRef={docsRef}
                  db={db}
                  userID={userID}
                  setZoom={setZoom}
                  zoomImg={zoomImg}
                  setChange={setChange}
                  change={change}
                  searchQuery={searchQuery}
                />
              </div>
              <div className={keyTab ? "documents-wraper" : "hide"}>
                {/* <Niches
                  nicheName={`bikes`}
                  db={db}
                  userID={userID}
                  setChange={setChange}
                  change={change}
                  searchQuery={searchQuery}
                /> */}

                <button
                  onClick={() => {
                    setNiche(true);
                  }}
                >
                  + New Niche
                </button>
                <div className="keywords-container">
                  {nichesData &&
                    nichesData.map((niche) => (
                      <div
                        className="niche"
                        key={niche.id}
                        onClick={() => navigate(`/niche/${niche.id}/`)}
                        // onClick={() => setId(niche.id)}
                      >
                        {niche.name}
                      </div>
                    ))}
                </div>
              </div>
              <div className={vidsTab ? "documents-wraper" : "hide"}>
                {" "}
                <AllVideos
                  db={db}
                  userID={userID}
                  setZoom2={setZoom2}
                  zoomVideo={zoomVideo}
                  setChange={setChange}
                  change={change}
                  searchQuery={searchQuery}
                />
              </div>
              <div className={docsTab ? "documents-wraper" : "hide"}>
                <div className="documents">
                  {filteredDocuments.length > 0 ? (
                    filteredDocuments.map((doc, i) => (
                      <div
                        className="document"
                        key={doc.id}
                        onClick={() => navigate(`/doc/${doc.id}/1`)}
                      >
                        <img
                          src={
                            doc.img != ""
                              ? doc.img
                              : `http://sparker.ai/wp-content/uploads/2023/04/Empty.png`
                          }
                        />
                        <div className="meta">
                          <div className="title">
                            {doc.meta.title ? doc.meta.title : "Untitled"}
                          </div>
                          <div className="date">
                            <div className="left">
                              Opened:{" "}
                              {doc.meta.last_opened
                                ? new Date(
                                    doc.meta.last_opened
                                  ).toLocaleDateString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })
                                : "unknown"}
                            </div>
                            <div className="right">
                              <img
                                src="http://sparker.ai/wp-content/uploads/2023/04/menu.png"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSmallMenu(i);
                                  setMenuVisible(!menuVisible);
                                }}
                              />
                            </div>
                          </div>

                          <div
                            className={
                              menuVisible && smallMenu === i
                                ? "small-menu"
                                : "hide"
                            }
                            style={{
                              position: "fixed",
                            }}
                          >
                            <div
                              className="items convert-video-item"
                              onClick={(e) => {
                                e.stopPropagation();

                                const url = new URL(
                                  `/video/${doc.id}`,
                                  window.location.href
                                );
                                window.open(url.toString(), "_blank");
                              }}
                            >
                              <img src="http://sparker.ai/wp-content/uploads/2023/06/video-studio-3.png" />
                              <span>
                                Convert to Video <sup>new</sup>
                              </span>
                            </div>
                            <div
                              className="items"
                              onClick={(e) => {
                                e.stopPropagation();

                                const url = new URL(
                                  `/doc/${doc.id}/${1}`,
                                  window.location.href
                                );
                                window.open(url.toString(), "_blank");
                              }}
                            >
                              <img src="http://sparker.ai/wp-content/uploads/2023/04/new-tab.png" />
                              <span>Open in new tab</span>
                            </div>
                            <div
                              className="items"
                              onClick={(e) => {
                                e.stopPropagation();

                                setDocDel(doc);
                                setdeleteDocs(true);
                              }}
                            >
                              <img src="http://sparker.ai/wp-content/uploads/2023/04/delete.png" />
                              <span>Delete</span>
                            </div>
                            {/* <div
                              className="items"
                              onClick={(e) => {
                                e.stopPropagation();

                                const url = new URL(
                                  `/doc/${doc.id}/${1}`,
                                  window.location.href
                                );
                                window.open(url.toString(), "_blank");
                              }}
                            >
                              <img src="http://sparker.ai/wp-content/uploads/2023/04/new-tab.png" />
                              <span>Open in new tab</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="no-creations">
                        No documents found. All your saved documents will appear
                        here.{" "}
                      </div>
                      <div className="video-demo-container">
                        <div>
                          {" "}
                          Learn how write your first researched article in under
                          2 mins:{" "}
                        </div>
                        <div className="video-demo">
                          <video
                            className="video-responsive2"
                            controls
                            src="https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/compressed+video+for+Prose+Flow+Gig+(2).mp4"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="wizard"
              onClick={() => {
                if (!running) {
                  setWizard(!wizard);
                } else if (running) {
                  return alert(
                    `cannot close wizard whilst function is running`
                  );
                }
              }}
            >
              <img src="http://sparker.ai/wp-content/uploads/2023/08/wizard-btn-1.png" />
            </div>

            {/* <div
              className={addAppear ? "btm-add" : "hide"}
              onClick={() => {
                addNewDoc(add);
              }}
            >
              <img src="http://sparker.ai/wp-content/uploads/2023/04/add-doc-btn-1.png" />
            </div> */}
          </div>
        </>
      ) : (
        <div className="loading-loader"></div>
      )}
    </>
  );
};

export default Main;
