import React, { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { loadStripe } from "@stripe/stripe-js";
import PortalButton from "../Components/PortalButton";
import PriceCalculator from "../Components/PriceCalculator";
import { collection, addDoc, getDocs, onSnapshot } from "firebase/firestore";
import PriceCalculator2 from "../Components/PriceCalculator2";

const Pricing = ({ db }) => {
  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const [menu, setMenu] = useState(false);

  const [loading, setLoading] = useState("");

  ////////////---------PORTAL OR PLANS---------------//////////////

  const [portal, setPortal] = useState(false);
  const [activePlan, setActivePlan] = useState();

  useEffect(() => {
    async function checkSubscriptionStatus() {
      try {
        const subscriptionRef = collection(db, `users/${userID}/subscriptions`);
        const snapshot = await getDocs(subscriptionRef);

        if (snapshot.empty) {
          setPortal(false); // subscription collection doesn't exist
        } else {
          let isActive = false;
          snapshot.forEach((doc) => {
            if (doc.data().status === "active") {
              isActive = true;
              setActivePlan(doc.data().items[0].price.product.name); // set activePlan based on active subscription
            }
          });
          setPortal(isActive); // set portal based on subscription status
        }
      } catch (error) {}
    }
    checkSubscriptionStatus();
  }, [userID]);

  ////////////////-----------------------------///////////////////

  ////------------------------------------/////
  const [sparkRate, setSparkRate] = useState(14);
  const [unlimitedRate, setUnlimitedRate] = useState(99);

  const [num, setNum] = useState(true);

  ////----------------------------------//////

  const loadCheckout = async (plan) => {
    let priceId;
    let session;

    if (plan === "starter" || plan === "publisher" || plan === "growth") {
      if (plan === "starter") {
        priceId = "price_1NeGhHEapUO2qFdSwsKIs0K0";
      } else if (plan === "publisher") {
        priceId = "price_1NeGjVEapUO2qFdSbSuHesGL";
      } else if (plan === "growth") {
        priceId = "price_1NeGkzEapUO2qFdSVQKnHO2N";
      }

      session = {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      };
    }

    const docRef = collection(db, `users/${uid}/checkout_sessions`);
    const checkoutDocRef = await addDoc(docRef, session);

    onSnapshot(checkoutDocRef, async (docSnapshot) => {
      const { error, sessionId } = docSnapshot.data();

      if (error) {
        alert(`An error occure: ${error.message}`);
      }

      if (sessionId) {
        const stripe = await loadStripe(
          "pk_live_51Mhss2EapUO2qFdSWOxYgcwn6IMdZtLN3h884Dv4xIPobyWvMiTSpEk18KhiKM6SLQAUoOXV5RoUmsMpD8JWJOWo001Mv08J9g"
        );

        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  //////////
  useEffect(() => {
    if (num) {
      setSparkRate(14);
      setUnlimitedRate(99);
    } else {
      setSparkRate(19);
      setUnlimitedRate(134);
    }
  }, [num]);
  //////////

  return (
    <>
      {userID ? (
        <>
          <div
            className="main-page"
            onClick={() => {
              setMenu(false);
            }}
          >
            <div className="plans-page">
              <Navbar Link={Link} menu={menu} setMenu={setMenu} db={db} />
            </div>

            <div className="plans-container2">
              {activePlan ? (
                <div className="topup-plan">
                  <span className="left-text">
                    {" "}
                    Active: <span className="right-text">{activePlan}</span>
                  </span>{" "}
                </div>
              ) : (
                <div className="topup-plan">
                  <span className="left-text">
                    {" "}
                    Active: <span className="right-text">Free Plan</span>
                  </span>{" "}
                </div>
              )}

              <div className="documents-wraper plans-wraper">
                <div className="documents">
                  <div className="plan starter">
                    <div className="plan-style">
                      <h3>Starter</h3>
                    </div>

                    <div className="plan-details">
                      <div className="price">
                        <div className="price-number">
                          <sup>$</sup>
                          <span>15</span>
                          <sub>/month</sub>
                        </div>
                      </div>
                    </div>
                    <div className="free-credit">
                      {" "}
                      <span>+ $5</span> free credit every month
                    </div>

                    <div className="price-total">
                      total = <span>$20</span> credit every month
                    </div>

                    {activePlan ? (
                      <div className="button-container">
                        {activePlan === "Spark Plan" ? (
                          <PortalButton text={"Manage"} />
                        ) : (
                          <PortalButton text={"Subscribe"} />
                        )}{" "}
                      </div>
                    ) : (
                      <div className="button-container">
                        <button
                          onClick={() => {
                            setLoading("starter");
                            loadCheckout("starter");
                          }}
                        >
                          {loading === "starter" ? (
                            <div className="portal-loader">
                              <span className="write-loader"></span>
                              <span>please wait..</span>
                            </div>
                          ) : (
                            <span>Subscribe</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="plan publisher">
                    <div className="plan-style">
                      <h3>Publisher</h3>
                    </div>

                    <div className="plan-details">
                      <div className="price">
                        <div className="price-number">
                          <sup>$</sup>
                          <span>35</span>
                          <sub>/month</sub>
                        </div>
                      </div>
                    </div>

                    <div className="free-credit">
                      {" "}
                      <span>+ $15</span> free credit every month
                    </div>

                    <div className="price-total">
                      total = <span>$50</span> credit every month
                    </div>

                    {activePlan ? (
                      <div className="button-container">
                        {activePlan === "Unlimited Plan" ? (
                          <PortalButton text={"Manage"} />
                        ) : (
                          <PortalButton text={"Subscribe"} />
                        )}{" "}
                      </div>
                    ) : (
                      <div className="button-container">
                        <button
                          onClick={() => {
                            setLoading("publisher");
                            loadCheckout("publisher");
                          }}
                        >
                          {loading === "publisher" ? (
                            <div className="portal-loader">
                              <span className="write-loader"></span>
                              <span>please wait..</span>
                            </div>
                          ) : (
                            <span>Subscribe</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="plan growth">
                    <div className="plan-style">
                      <h3>Growth</h3>
                    </div>

                    <div className="plan-details">
                      <div className="price">
                        <div className="price-number">
                          <sup>$</sup>
                          <span>65</span>
                          <sub>/month</sub>
                        </div>
                      </div>
                    </div>

                    <div className="free-credit">
                      {" "}
                      <span>+ $35</span> free credit every month
                    </div>
                    <div className="price-total">
                      total = <span>$100</span> credit every month
                    </div>
                    {activePlan ? (
                      <div className="button-container">
                        {activePlan === "Unlimited Plan" ? (
                          <PortalButton text={"Manage"} />
                        ) : (
                          <PortalButton text={"Subscribe"} />
                        )}{" "}
                      </div>
                    ) : (
                      <div className="button-container">
                        <button
                          onClick={() => {
                            setLoading("growth");
                            loadCheckout("growth");
                          }}
                        >
                          {loading === "growth" ? (
                            <div className="portal-loader">
                              <span className="write-loader"></span>
                              <span>please wait..</span>
                            </div>
                          ) : (
                            <span>Subscribe</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="plan2">
                    <div className="plan-style">
                      <h3>Enteprise</h3>
                    </div>

                    <div className="plan-details">
                      <div className="price">
                        <div className="price-number2">
                          <span>Let's talk.</span>
                        </div>
                      </div>
                    </div>

                    <div className="button-container">
                      <button
                        onClick={() => {
                          const url = new URL(
                            `https://sparker.ai/contact-us/`,
                            window.location.href
                          );
                          window.open(url.toString(), "_blank");
                        }}
                      >
                        <span>Contact Us</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="powered-by-stripe">
                <img src="https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/Stripe_(company)-Powered-by-Stripe-Outline-Logo.wine.svg" />
              </div>

              <div className="costs-flex">
                <div className="costs-text">
                  <span>Writing:</span> $0.0005 per word{" "}
                </div>
                <div className="costs-text">
                  <span>Video:</span> ~ $0.01 per second
                </div>
                <div className="costs-text">
                  <span>Keywords:</span> $0.05 per search{" "}
                </div>
              </div>
              <div className="star-text">
                All features are included with every plan.
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loading-loader"></div>
      )}
    </>
  );
};

export default Pricing;
