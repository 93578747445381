import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { collection, addDoc, getDocs, getDoc, doc } from "firebase/firestore";

const Admin = ({ db }) => {
  const { user } = UserAuth();

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);

  const [survey, setSurvey] = useState();
  const [menu, setMenu] = useState(false);
  const [mainPage, setMainPage] = useState(true);
  const [placeholder, setPlaceholder] = useState(
    "Search users by name, email or id"
  );

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = await getDocs(collection(db, "users"));
        const fetchedUsers = [];

        for (const userDoc of usersCollection.docs) {
          const userData = {
            id: userDoc.id,
            displayName: userDoc.data()?.displayName
              ? userDoc.data()?.displayName
              : "No Display Name",
            email: userDoc.data().email,
          };

          const surveyDocRef = doc(
            collection(db, `users/${userDoc.id}/survey`),
            "survey_doc"
          );
          const surveyDocSnapshot = await getDoc(surveyDocRef);

          if (surveyDocSnapshot.exists()) {
            const surveyData = surveyDocSnapshot.data();
            Object.assign(userData, surveyData);
          }

          const totalTokensDocRef = doc(
            collection(db, `users/${userDoc.id}/total-tokens`),
            "total-tokens"
          );
          const totalTokensDocSnapshot = await getDoc(totalTokensDocRef);

          if (totalTokensDocSnapshot.exists()) {
            const totalTokensData = totalTokensDocSnapshot.data();
            Object.assign(userData, totalTokensData);
          }

          const openedDocRef = doc(
            collection(db, `users/${userDoc.id}/video-studio-opened`),
            "opened"
          );
          const openedDocSnapshot = await getDoc(openedDocRef);

          if (openedDocSnapshot.exists()) {
            const openedData = openedDocSnapshot.data();
            Object.assign(userData, openedData);
          }

          const checkoutSessionsCollectionRef = collection(
            db,
            `users/${userDoc.id}/checkout_sessions`
          );
          const checkoutSessionsQuerySnapshot = await getDocs(
            checkoutSessionsCollectionRef
          );

          if (!checkoutSessionsQuerySnapshot.empty) {
            // If checkout_sessions collection exists, add checkout data
            Object.assign(userData, { checkout: "Checkout was opened" });
          } else {
            // If checkout_sessions collection doesn't exist, add empty checkout data
            Object.assign(userData, { checkout: "" });
          }

          fetchedUsers.push(userData);
        }

        setUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [userID]);

  useEffect(() => {
    const filteredUsers = users.filter((user) => {
      const formattedSubmissionTime = user?.submission_time
        ? user.submission_time.toDate().toLocaleDateString("en-GB") // Convert to string in "dd/mm/yyyy" format
        : "";

      return (
        user?.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.tokens?.toString().includes(searchQuery.toLowerCase()) ||
        user?.opened?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.hear_about_us
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        user?.app_interest?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formattedSubmissionTime.includes(searchQuery.toLowerCase())
      );
    });

    setUsersFiltered(filteredUsers);
  }, [searchQuery, userID, users]);

  console.log(users);

  return (
    <>
      {userID && userID === "Aw3pnLuHodOywniqY9fVMZAlPm02" ? (
        <div className="admin-page">
          <Navbar
            Link={Link}
            db={db}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            mainPage={mainPage}
            menu={menu}
            setMenu={setMenu}
            placeholder={placeholder}
          />
          <div className="total-users">
            Total user: <span>{usersFiltered.length}</span>
          </div>
          <div className="users-cards">
            {usersFiltered.map((user) => (
              <div className="user-card" key={user.id}>
                <h2>{user?.displayName}</h2>
                <div>
                  <span className="left">ID:</span>{" "}
                  <span className="right">{user.id}</span>
                </div>

                <div>
                  {" "}
                  <span className="left">Email:</span>{" "}
                  <span className="right">{user.email}</span>
                </div>
                <div>
                  {" "}
                  <span className="left">Hear About:</span>{" "}
                  <span className="right">{user?.hear_about_us}</span>
                </div>
                <div>
                  {" "}
                  <span className="left">App Interest:</span>{" "}
                  <span className="right">{user?.app_interest}</span>
                </div>
                <div>
                  {" "}
                  <span className="left">Tokens:</span>{" "}
                  <span className="right">{user?.tokens}</span>
                </div>
                <div>
                  {" "}
                  <span className="left">Vid opened:</span>{" "}
                  <span className="right">{user?.opened}</span>
                </div>
                <div>
                  {" "}
                  <span className="left">Checkout:</span>{" "}
                  <span className="right">{user?.checkout}</span>
                </div>
                <div>
                  {" "}
                  <span className="left">Time Joined:</span>{" "}
                  <span className="right">
                    {user?.submission_time?.toDate()?.toLocaleString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Admin;
