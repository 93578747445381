import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Outline5 = ({ newName, setNewName, setPromp2, readyToWrite }) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  //---------------STATES-----------------------//

  const [loading, setLoading] = useState(false);
  const [plot, setPlot] = useState(true);
  const [char, setChar] = useState(false);
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");

  const initialRender = useRef(true);

  //------------------------------------------//
  const [genre, setGenre] = useState("");

  //--------------DATABASE REF----------------//
  //-----------------------------------------//

  const plotTab = () => {
    setChar(false);
    setPlot(true);
  };

  const charTab = () => {
    setChar(true);
    setPlot(false);
  };
  //-----------------API CALL----------------//

  //---------------------------------------//

  //--------------ADD TO DATABASE---------------//

  //------------------------------------------//

  //------------clearList Function----------------//

  //---------------------------------------------//

  //---------------Event Handler-----------------//

  const handleChange = (event) => {
    setText(event.target.value);
  };
  const handleChange2 = (event) => {
    setText2(event.target.value);
  };
  //---------------------------------------------//

  let prompt = "";

  if (plot) {
    prompt = `Please write brief plot summary, where the experiences of the main character are described without narration, for a ${genre} story with the title '${newName}':`;
  } else if (char) {
    if (text.length < 100) {
      alert("Please add or create a plot summary first");
    } else {
      prompt = `I'm writing a ${genre} story with the title '${newName}'. I've created a brief plot summary for it (see below). Create characters for this story, giving each character a name and a backstory. Make sure to add adequate space between each character section.\n\nPlot Summary:\n${text}\n\nStory Characters:`;
    }
  }

  //----------------API CALL-------------//
  const plotAI = async (text) => {
    if (newName === "") {
      return alert("Please add some info in the outline to get suggestions");
    } else {
      let resp;
      try {
        setLoading(true);
        // Make a request to the Firebase function to fetch data from the OpenAI API
        const response = await fetch(
          `https://us-central1-cruncher-ai-app.cloudfunctions.net/fromAI?promp=${prompt}&u=${uid}&i=${id}`,
          {
            mode: "cors",
          }
        );
        const data = await response.json();
        if (data.model === "chat") {
          resp = data.data.choices[0].message.content;
        } else if (data.model === "davinci3" || data.model === "davinci2") {
          resp = data.data.choices[0].text;
        }
        setLoading(false);
      } catch (error) {
        alert(`something went wrong`);
        setLoading(false);
      }
      // return response;
      if (plot) {
        setText(resp);
      } else if (char) {
        setText2(resp);
      }
    }
  };
  //-----------------------------------------------//

  //-----------------Remove Item-------------------//

  //-------------------------------------------------//

  //--------------------Add Item---------------------//

  //-------------------------------------------------//

  //------------SEND DATA TO TEXTAREA----------------//

  useEffect(() => {
    if (text && text.length > 100) {
      if (genre === "Children's") {
        setPromp2(
          `Please write a children's story in the style of Roald Dahl, where the experiences of the main character are described without narration. The story should follow the journey of the main character. Please write in html and divide the story into 10 chapters giving h3 heading to each chapter. Use this plot summary as a reference for the story:\n${text}\nChildren's story in the style of Roald Dahl, where the experiences of the main character are described without narration (in html):`
        );
      } else if (genre === "Fiction") {
        setPromp2(
          `Please write a ${genre} story with the title '${newName}' in the style of JK Rowling, where the experiences of the main character are described without narration.\n\nPlot Summary:\n${text}\n\nStory Characters:\n${
            text2 ? text2 : "use from plot"
          }\n\nStory in the style of JK Rowling, where the experiences of the main character are described without narration. Written in HTML with 10 chapters, usign h3 headings for each chapter:`
        );
      } else {
        setPromp2(
          `I'm writing a ${genre} story with the title '${newName}'. I've created a brief plot summary and also created the characters with their backstories (see below). Using all this information write atleast 10 chapters for this story (in HTML), give each Chapter a h3 title followed by what happens in that chapter (using <p> tags where needed), give highly descriptive details of everything and keep the pace of story progression very slow. Describe everything in vivid detail.\n\nPlot Summary:\n${text}\n\nStory Characters:\n${
            text2 ? text2 : "use from plot"
          }\n\nDetailed Story Chapters (in HTML):`
        );
      }
    }
  }, [text, text2]);
  //-------------------------------------------------------------------------------------//
  // Define a function that will be called when the 'Move Up' button is clicked

  //---------------------------//
  //------------------------------------//
  return (
    <>
      <div className={loading === true ? "overlay2" : "hide"}>
        <div className="container">
          <span className="one"></span>
          <span className="two"></span>
          <span className="three"></span>
          <span className="four"></span>
        </div>
        <div className="five">imagining..</div>
      </div>
      <div className="gen-top">
        <div className="gen-top1">
          <input
            className="gen-input"
            placeholder="Enter your Story Title"
            onChange={(e) => {
              e.preventDefault();
              setNewName(e.target.value);
            }}
          />
        </div>
        <div className="out-tabs">
          <div className={plot ? "tab-focus" : "tab"} onClick={plotTab}>
            Plot
          </div>{" "}
          <div className={char ? "tab-focus" : "tab"} onClick={charTab}>
            {" "}
            Characters
          </div>
        </div>
      </div>

      <div className={plot ? "plot" : "hide"}>
        <div className="genre-area">
          <div className="genre-top">
            <input
              className="gen-input"
              placeholder="Genre"
              value={genre}
              onChange={(e) => {
                e.preventDefault();
                setGenre(e.target.value);
              }}
            />
          </div>
          <div className="genres">
            <div
              className={genre === "Fiction" ? "genre" : "genre2"}
              onClick={() => setGenre("Fiction")}
            >
              Fiction
            </div>
            <div
              className={genre === "Drama" ? "genre" : "genre2"}
              onClick={() => setGenre("Drama")}
            >
              Drama
            </div>
            <div
              className={genre === "Scifi" ? "genre" : "genre2"}
              onClick={() => setGenre("Scifi")}
            >
              Scifi
            </div>
            <div
              className={genre === "Fantasy" ? "genre" : "genre2"}
              onClick={() => setGenre("Fantasy")}
            >
              Fantasy
            </div>
            <div
              className={genre === "Romance" ? "genre" : "genre2"}
              onClick={() => setGenre("Romance")}
            >
              Romance
            </div>
            <div
              className={genre === "Children's" ? "genre" : "genre2"}
              onClick={() => setGenre("Children's")}
            >
              Children's
            </div>
            <div
              className={genre === "Thriller" ? "genre" : "genre2"}
              onClick={() => setGenre("Thriller")}
            >
              Thriller
            </div>
            <div
              className={genre === "Cyberpunk" ? "genre" : "genre2"}
              onClick={() => setGenre("Cyberpunk")}
            >
              Cyberpunk
            </div>
            <div
              className={genre === "Horror" ? "genre" : "genre2"}
              onClick={() => setGenre("Horror")}
            >
              Horror
            </div>
          </div>
        </div>
        <button
          disabled={newName.length < 3}
          className="list-gen-btn"
          onClick={plotAI}
        >
          Create Plot
        </button>
        <button
          disabled={newName.length < 6}
          className="ready-to-write"
          onClick={readyToWrite}
        >
          ✓ Ready to Write
        </button>
        <div className="form-area2">
          <textarea
            className="form-text"
            placeholder="Brief Plot Summary.."
            value={text}
            onChange={(event) => handleChange(event)}
          />
          <button
            className="clear-list-btn"
            onClick={() => {
              setText("");
            }}
          >
            Clear
          </button>
        </div>
      </div>

      <div className={char ? "char" : "hide"}>
        <button
          disabled={newName.length < 3}
          className="list-gen-btn"
          onClick={plotAI}
        >
          Create Characters
        </button>
        <button
          disabled={newName.length < 6}
          className="ready-to-write"
          onClick={readyToWrite}
        >
          ✓ Ready to Write
        </button>
        <div className="form-area3">
          <textarea
            className="form-text"
            placeholder="Characters and their backstories.."
            value={text2}
            onChange={(event) => handleChange2(event)}
          />
          <button
            className="clear-list-btn"
            onClick={() => {
              setText2("");
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

export default Outline5;
