import React from "react";

const AllTemplates = ({ templates, setTemplates, addNewDoc }) => {
  return (
    <div className={templates ? "full-templates" : "hide"}>
      <div
        className="all-templates"
        onClick={() => {
          setTemplates(false);
        }}
      >
        close
      </div>
      <div className="documents-wraper">
        <div className="documents">
          {/* <div className="templates-container"> */}
          <div
            className="add-doc templ-item"
            onClick={() => {
              addNewDoc(1);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/Add-new-Doc.png" />
            <span>New Blank</span>
          </div>
          <div
            className="listicle templ-item"
            onClick={() => {
              addNewDoc(4);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/Listicle-3.png" />
            <span>Listicle</span>
          </div>
          <div
            className="comparison templ-item"
            onClick={() => {
              addNewDoc(2);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/Comparison-1.png" />
            <span>Comparison</span>
          </div>
          <div
            className="creative templ-item"
            onClick={() => {
              addNewDoc(5);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/Creative-Writing.png" />
            <span>Creative Writing</span>
          </div>
          {/* <div
            className="video-script templ-item"
            onClick={() => {
              addNewDoc(9);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/video-script-5.png" />
            <span>Video Script</span>
          </div> */}
          <div
            className="ai-art templ-item"
            onClick={() => {
              addNewDoc(true);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/AI-ART.png" />
            <span>AI Art</span>
          </div>
          <div
            className="video-script templ-item"
            onClick={() => {
              addNewDoc(3);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/how-article.png" />
            <span>Tutorial</span>
          </div>
          <div
            className="creative templ-item"
            onClick={() => {
              addNewDoc(8);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/product-description.png" />
            <span>Product Description</span>
          </div>
          <div
            className="video-script templ-item"
            onClick={() => {
              addNewDoc(6);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/product-review-1.png" />
            <span>Product Review</span>
          </div>
          <div
            className="creative templ-item"
            onClick={() => {
              addNewDoc(7);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/ads-1.png" />
            <span>Ad Creative</span>
          </div>
          <div
            className="video-script templ-item"
            onClick={() => {
              addNewDoc(10);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/cold-email.png" />
            <span>Cold Email</span>
          </div>

          <div
            className="video-script templ-item"
            onClick={() => {
              addNewDoc(11);
            }}
          >
            <img src="http://sparker.ai/wp-content/uploads/2023/04/paragraph-1.png" />
            <span>Paragraph Maker</span>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default AllTemplates;
