import React, { useState, useEffect } from "react";


const ErrorMessage = ({
  // errorsRef,
  // combi,
  // setWrite,
  // loading2,
  // setLoading2,
  error,
  setError,
  deleteError,
  // error2,
  // setError2,
  // errorMessage,
  // db,
}) => {
  // const { id } = useParams();
  // const { user } = UserAuth();
  // let uid = user.uid;
  // //--------------------//
  // const stopRef = doc(db, `users/${uid}/documents/${id}/stop/start-stop`);

  //------------------//

  // const stopIt = async () => {
  //   await setDoc(stopRef, { stop: true });

  //   /////get doc refs for combined/////
  //   const querySnapshot = await getDocs(combi);
  //   let docids = [];
  //   querySnapshot.forEach((doc) => {
  //     docids.push(doc.id);
  //   });
  //   for (let i = 0; i < docids.length; i++) {
  //     const docRef = doc(
  //       db,
  //       `users/${uid}/documents/${id}/combined`,
  //       docids[i]
  //     );

  //     /////set error fields as empty/////
  //     setDoc(docRef, { error: "" }, { merge: true });
  //   }

  //   setLoading2(false);
  //   setWrite(false);
  //   setError(false);
  // };

  // const ignoreIt = async () => {
  //   /////get doc refs for combined/////
  //   const querySnapshot = await getDocs(combi);
  //   let docids = [];
  //   querySnapshot.forEach((doc) => {
  //     docids.push(doc.id);
  //   });
  //   for (let i = 0; i < docids.length; i++) {
  //     const docRef = doc(
  //       db,
  //       `users/${uid}/documents/${id}/combined`,
  //       docids[i]
  //     );

  //     /////set error fields as empty/////
  //     setDoc(docRef, { error: "" }, { merge: true });
  //   }

  //   setError(false);
  // };

  const closeit = () => {
    deleteError();
    setError(null);
  };

  return (
    <>
      <div className={`error ${error ? "show" : ""}`}>
        <span>
          {error?.error}
        </span>
        <div className="error-btns">
          
          <button className="ignore" onClick={closeit}>
            Close
          </button>
        </div>
      </div>
   
    </>
  );
};

export default ErrorMessage;
