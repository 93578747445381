import {
  Composition,
  Video,
  OffthreadVideo,
  Img,
  AbsoluteFill,
  Sequence,
  Audio,
  useCurrentFrame,
  //   delayRender,
  //   continueRender,
} from "remotion";
import { getAudioDurationInSeconds } from "@remotion/media-utils";
import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Template1 = ({ updatedData, sceneDurations, music }) => {
  const { id } = useParams();

  const [sequences, setSequences] = useState();
  const [images, setImages] = useState();

  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  useEffect(() => {
    let isMounted = true;

    if (userID !== "") {
      const videoRef = doc(db, `users/${userID}/videos/${id}`);

      const unsubscribe = onSnapshot(videoRef, (snapshot) => {
        if (isMounted) {
          if (snapshot.exists()) {
            const sequence = snapshot.data().sequence;
            const arrayOfObjects = Object.values(sequence);

            const imgs = snapshot.data().images;

            setSequences(arrayOfObjects);
            setImages(imgs);
          } else {
            // Handle the case where the document does not exist
          }
        }
      });

      return () => {
        isMounted = false;
        unsubscribe();
      };
    }
  }, [userID, id]);

  const fps = 30;

  const [audioDurations, setAudioDurations] = useState([]);

  useEffect(() => {
    if (sequences && sequences[0]) {
      const fetchAudioDurations = async () => {
        try {
          const durations = await Promise.all(
            sequences.map(
              async (data) => await getAudioDurationInSeconds(data.url)
            )
          );

          setAudioDurations(durations);
        } catch (error) {
          console.error("Failed to fetch audio durations:", error);
        }
      };

      if (sequences !== undefined) {
        fetchAudioDurations();

        // console.log("fetch audio durations triggered");
      }
    }
  }, [sequences]);

  let urlCounter = 0;

  // console.log(sequences)
  //   console.log(audioDurations);

  const currentframe = useCurrentFrame();

  const sceneFrame = (index) => {
    const frame = currentframe - sceneDurations[index].from;

    return frame;
  };

  const calculateSlowZoom = (index, style) => {
    let scale = 1;

    if (style === 1) {
      scale = Math.max(1.7 - sceneFrame(index) * 0.004, 1);
    }
    if (style === 2) {
      scale = Math.min(1 + sceneFrame(index) * 0.002, 2);
    }

    return scale;
  };

  // let style = Math.floor(Math.random() * 2) + 1;

  const calculateVideoSlowZoom = (index, style) => {
    let scale = 1;

    if (style === 1) {
      scale = Math.max(1.4 - sceneFrame(index) * 0.0015, 1);
    }
    if (style === 2) {
      scale = Math.min(1 + sceneFrame(index) * 0.0015, 1.4);
    }
    if (style === 0) {
      scale = 1;
    }

    return scale;
  };

  const calculateOpacity = (index, style) => {
    let opacity = 1;

    if (style === 1) {
      opacity = Math.max(1 - sceneFrame(index) * 0.02, 0);
    }
    if (style === 2) {
      opacity = Math.min(0 + sceneFrame(index) * 0.02, 1);
    }

    return opacity;
  };

  const calculateZoom = (index, style) => {
    console.log(style);
    let scale = 1;

    if (style === 1) {
      scale = Math.max(20 - sceneFrame(index) * 0.5, 1);
    }
    if (style === 2) {
      scale = Math.min(0 + sceneFrame(index) * 0.05, 1);
    }

    return scale;
  };

  const calculateTranslateY = (index, style) => {
    let translateY = 0;

    if (style === 0) {
      translateY = 0;
    }

    if (style === 1) {
      translateY = Math.max(4500 - sceneFrame(index) * 200, 0);
    }
    if (style === 2) {
      translateY = Math.min(-4500 + sceneFrame(index) * 200, 0);
    }

    return translateY;
  };

  const calculateTranslateX = (index, style) => {
    // console.log(`style is ${style}`);

    let translateX = 0;

    if (style === 0) {
      translateX = 0;
    }

    if (style === 1) {
      translateX = Math.max(4500 - sceneFrame(index) * 200, 0);
    }
    if (style === 2) {
      translateX = Math.min(-4500 + sceneFrame(index) * 200, 0);
    }

    return translateX;
  };

  if (!updatedData || !updatedData[0]) {
    return "";
  }

  const handleError = () => {
    alert(`error loading a video clip`);
  };
  // console.log(updatedData[2].textTranslateX);

  // const zoomOutPercentage = frame / 100;

  return (
    <AbsoluteFill>
      {music && (
        <Audio
          loop
          src={music.musicUrl}
          volume={
            updatedData?.[0]?.musicVolume ? updatedData?.[0]?.musicVolume : 0.1
          }
        />
      )}
      {updatedData &&
        updatedData[0]?.url &&
        sceneDurations.map((duration, index) => (
          <Sequence
            key={index}
            from={index > 0 ? duration.from : 0}
            durationInFrames={duration.durationInFrames}
          >
            <Audio
              src={updatedData?.[index]?.url}
              volume={updatedData?.[index]?.voiceVolume}
            />
            {updatedData?.[index]?.videoUrl ? (
              <Video
                // loop
                onError={(event) => {
                  // Handle  error here
                  event.target.src =
                    "https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/This+Video+clip+doesn't+exist+(1).mp4";
                }}
                muted
                src={updatedData?.[index]?.videoUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transform: `scale(${calculateVideoSlowZoom(
                    index,
                    updatedData?.[index]?.videoZoom
                      ? updatedData?.[index]?.videoZoom
                      : 0
                  )})`,
                }}
              />
            ) : (
              ""
            )}

            {updatedData?.[index]?.meta === "heading" ? (
              <>
                <div
                  style={{
                    backgroundImage: updatedData?.[index]?.imageUrl
                      ? `url(${updatedData?.[index]?.imageUrl})`
                      : "",
                    transform: `scale(${calculateSlowZoom(
                      index,
                      updatedData?.[index]?.imageZoom
                    )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundColor: updatedData?.[index]?.backgroundColor
                      ? updatedData?.[index]?.backgroundColor
                      : "black",
                    position: "absolute",
                    zIndex: -1,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: updatedData?.[index]?.justifyContent
                      ? updatedData?.[index]?.justifyContent
                      : "center",
                    alignItems: updatedData?.[index]?.alignItems
                      ? updatedData?.[index]?.alignItems
                      : "center",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",

                    padding: "0px",
                    color: updatedData?.[index]?.textColor
                      ? updatedData?.[index]?.textColor
                      : "white",
                    fontFamily: updatedData?.[index]?.fontFamily
                      ? updatedData?.[index]?.fontFamily
                      : "Arial",
                    fontSize: updatedData?.[index]?.fontSize
                      ? updatedData?.[index]?.fontSize
                      : "88px",
                    zIndex: 0,
                    textAlign: "center",

                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      textAlign: updatedData?.[index]?.textAlign
                        ? updatedData?.[index]?.textAlign
                        : "center",
                      padding: updatedData?.[index]?.textPadding
                        ? updatedData?.[index]?.textPadding
                        : "",
                      backgroundColor: updatedData?.[index]?.textBg
                        ? updatedData?.[index]?.textBg
                        : "",
                      width: updatedData?.[index]?.textWidth
                        ? updatedData?.[index]?.textWidth
                        : "85%",
                      height: updatedData?.[index]?.textHeight
                        ? updatedData?.[index]?.textHeight
                        : "",
                      margin: updatedData?.[index]?.textMargin
                        ? updatedData?.[index]?.textMargin
                        : "0px",
                      transform: `translate(${calculateTranslateX(
                        index,
                        updatedData?.[index]?.textTranslateX
                      )}px, ${calculateTranslateY(
                        index,
                        updatedData?.[index]?.textTranslateY
                      )}px) scale(${calculateZoom(
                        index,
                        updatedData?.[index]?.textZoom
                      )})`,
                      opacity: calculateOpacity(
                        index,
                        updatedData?.[index]?.textFade
                      ),
                      // transition: "transform 0.3s ease",
                    }}
                  >
                    {" "}
                    {updatedData?.[index]?.text}
                  </div>{" "}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    backgroundImage: updatedData?.[index]?.imageUrl
                      ? `url(${updatedData?.[index]?.imageUrl})`
                      : "",
                    transform: `scale(${calculateSlowZoom(
                      index,
                      updatedData?.[index]?.imageZoom
                    )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundColor: updatedData?.[index]?.backgroundColor
                      ? updatedData?.[index]?.backgroundColor
                      : "white",
                    position: "absolute",
                    zIndex: -1,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: updatedData?.[index]?.justifyContent
                      ? updatedData?.[index]?.justifyContent
                      : "center",
                    alignItems: updatedData?.[index]?.alignItems
                      ? updatedData?.[index]?.alignItems
                      : "center",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",

                    padding: "0px",
                    color: updatedData?.[index]?.textColor
                      ? updatedData?.[index]?.textColor
                      : "white",
                    fontFamily: updatedData?.[index]?.fontFamily
                      ? updatedData?.[index]?.fontFamily
                      : "Arial",
                    fontSize: updatedData?.[index]?.fontSize
                      ? updatedData?.[index]?.fontSize
                      : "48px",
                    zIndex: 0,
                    textAlign: "center",

                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      textAlign: updatedData?.[index]?.textAlign
                        ? updatedData?.[index]?.textAlign
                        : "center",
                      padding: updatedData?.[index]?.textPadding
                        ? updatedData?.[index]?.textPadding
                        : "20px",
                      backgroundColor: updatedData?.[index]?.textBg
                        ? updatedData?.[index]?.textBg
                        : "rgba(0, 0, 0, 0.4)",
                      borderRadius: "8px",
                      width: updatedData?.[index]?.textWidth
                        ? updatedData?.[index]?.textWidth
                        : "70%",
                      height: updatedData?.[index]?.textHeight
                        ? updatedData?.[index]?.textHeight
                        : "auto",
                      margin: updatedData?.[index]?.textMargin
                        ? updatedData?.[index]?.textMargin
                        : "0px",
                      transform: `translate(${calculateTranslateX(
                        index,
                        updatedData[index]?.textTranslateX
                      )}px, ${calculateTranslateY(
                        index,
                        updatedData?.[index]?.textTranslateY
                      )}px) scale(${calculateZoom(
                        index,
                        updatedData?.[index]?.textZoom
                      )})`,
                      opacity: calculateOpacity(
                        index,
                        updatedData?.[index]?.textFade
                      ),
                      // transition: "transform 0.1s ease",
                    }}
                  >
                    {" "}
                    {updatedData?.[index]?.text}
                  </div>{" "}
                </div>
              </>
            )}
          </Sequence>
        ))}
    </AbsoluteFill>
  );
};

export default Template1;
