import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

import {
  collection,
  getDocs,
  addDoc,
  getDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

const ImageAi = ({
  insertImage,
  aiImages,
  setAiImages,
  imageRef,
  imagePrompt,
  setImagePrompt,
  db,
  saveFunction,
  availableTokens,
  expectedWords,
  notEnough,
  setNotEnough,
}) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;

  ///////////////////////

  ////States///////
  const [image, setImage] = useState([]);
  const [loader, setLoader] = useState(false);

  const [progress, setProgress] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [insert, setInsert] = useState();

  ////Save AI Image to database function//////
  const save = async (url, docId, promp) => {
    let obj = {
      url: url,
      docId: docId,
      promp: promp,
      date: Date.now(),
    };

    await addDoc(imageRef, obj);
  };

  ///////Save ti=o get hosted Url////

  const save2 = async (url, docId, promp) => {
    let obj = {
      url: url,
      docId: docId,
      promp: promp,
      date: Date.now(),
    };

    const newDocRef = await addDoc(imageRef, obj); // Save the new document reference

    // Return a promise that resolves when the hostelUrl field is added to the document
    return new Promise((resolve) => {
      setTimeout(async () => {
        const newDocSnapshot = await getDoc(newDocRef); // Get the new document snapshot
        const hostedUrl = newDocSnapshot.get("hostedUrl"); // Get the value of the hostelUrl field
        resolve(hostedUrl); // Resolve the promise with the hostelUrl value
      }, 5000); // Wait for 5 seconds
    });
  };

  ///////////////////////

  const deleteImage = async (url) => {
    const querySnapshot1 = await getDocs(collection(db, `users/${uid}/aipics`));

    //////-----GET AIRETURNS DOCS------//////
    querySnapshot1.forEach((doc) => {
     

      //   if (doc.url === url) {
      //     deleteDoc(doc.id);
      //   }
    });
  };

  ///////////////////////

  useEffect(() => {
    let interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + (100 - prevProgress) / 20;
        return nextProgress > 99.5 ? 100 : nextProgress;
      });
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, []);
  /////Image functions///////
  const removeImage = (url) => {
    const filteredArray = image.filter((imageUrl) => {
      return imageUrl !== url;
    });
    setImage(filteredArray);
  };
  /////////////

  const aiImage = async () => {
    if (availableTokens < 1000) {
      return setNotEnough(true);
    }
    setProgress(0);

    setLoader(true);
    if (imagePrompt.length < 5) {
      return alert("Please add a more descriptive prompt");
    } else {
      try {
        // Make a request to the Firebase function to fetch data from the OpenAI API
        const response = await fetch(
          `https://us-central1-cruncher-ai-app.cloudfunctions.net/aiImage?promp=${imagePrompt}&u=${uid}&i=${id}`,
          {
            mode: "cors",
          }
        );
        const data = await response.json();
        let url = data.data[0].url;

        setImage([...image, url]);
      } catch (error) {
        alert(
          `There was an error processing your request, please try again after some time.`
        );
        console.error(error);
      }
    }

    setProgress(0);
    setLoader(false);
  };

  const imageAction = async (url, docId, i, promp) => {
    setInsert(i);
    await save2(url, docId, promp);

    insertImage(url);
    setInsert();
    saveFunction();
    setAiImages(false);
  };

  const reversedData = [];
  for (let i = image.length - 1; i >= 0; i--) {
    reversedData.push(image[i]);
  }

  return (
    <div className={aiImages ? "ai-images" : "hide"}>
      <div
        className="ai-close"
        onClick={() => {
          setAiImages(false);
        }}
      >
        Close
      </div>

      <div className="ai-container">
        <textarea
          className="ai-image-input"
          value={imagePrompt}
          placeholder="Enter a descriptive prompt"
          onChange={(e) => {
            e.preventDefault();
            setImagePrompt(e.target.value);
          }}
        />

        <button className="ai-gen-btn" onClick={aiImage}>
          <span>Generate</span>
        </button>
        <div className={loader ? "ai-loader" : "hide"}>
          <div className="ai-progress" style={{ width: `${progress}%` }}></div>
        </div>
        <div className="image-list">
          {image.map((data, i) => {
            return (
              <div
                className="image-item"
                key={i}
                id={i}
                onMouseEnter={() => setIsHovering(i)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <img
                  src={`${data}`}
                  width="100%"
                  height="100%"
                  className="ai-image"
                  // alt={prompt}
                />
                {insert === i && <div className="image-loader"></div>}
                {isHovering === i && (
                  <div className="ai-overlay">
                    <img
                      className="ai-insert"
                      src="http://sparker.ai/wp-content/uploads/2023/03/icons2.png"
                      width="20px"
                      onClick={() => {
                        imageAction(data, id, i, imagePrompt);
                      }}
                    />

                    <img
                      className="ai-save"
                      src="http://sparker.ai/wp-content/uploads/2023/03/icons2-1.png"
                      width="20px"
                      onClick={() => {
                        save(data, id, imagePrompt);
                      }}
                    />

                    <img
                      className="ai-delete"
                      src="http://sparker.ai/wp-content/uploads/2023/03/icons2-2.png"
                      width="20px"
                      onClick={() => {
                        removeImage(data);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageAi;
