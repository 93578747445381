import React, { useState } from "react";

const TextAnimations = ({
  setShowAnimations,
  updateTextTranslate,

  updateTextFade,
  updateTextZoom,
}) => {
  const [selectT, setSelectT] = useState();

  const [selectF, setSelectF] = useState();
  const [selectZ, setSelectZ] = useState();

  return (
    <div className="text-animations">
      <div
        className="close-txt-anima"
        onClick={() => {
          setShowAnimations(false);
        }}
      >
        x
      </div>
      <div className="text-item">
        <div className="transition-arrows anima-hover">
          <span
            className={selectT === 5 ? "selected11" : ""}
            onClick={() => {
              setSelectT(5);
              updateTextTranslate(0, 0);
            }}
          >
            ∅
          </span>
          <span
            className={selectT === 1 ? "selected11" : ""}
            onClick={() => {
              setSelectT(1);
              updateTextTranslate(1, 0);
            }}
          >
            ↤
          </span>
          <span
            className={selectT === 2 ? "selected11" : ""}
            onClick={() => {
              setSelectT(2);
              updateTextTranslate(2, 0);
            }}
          >
            ↦
          </span>
          <span
            className={selectT === 3 ? "selected11" : ""}
            onClick={() => {
              setSelectT(3);
              updateTextTranslate(0, 2);
            }}
          >
            ↧
          </span>
          <span
            className={selectT === 4 ? "selected11" : ""}
            onClick={() => {
              setSelectT(4);
              updateTextTranslate(0, 1);
            }}
          >
            ↥
          </span>
        </div>
      </div>
      <div className="text-item">
        <div className="text-fade anima-hover">
          <span
            className={selectF === 3 ? "selected11" : ""}
            onClick={() => {
              setSelectF(3);
              updateTextFade(0);
            }}
          >
            None
          </span>
          <span
            className={selectF === 2 ? "selected11" : ""}
            onClick={() => {
              setSelectF(2);
              updateTextFade(2);
            }}
          >
            Fade in
          </span>
          <span
            className={selectF === 1 ? "selected11" : ""}
            onClick={() => {
              setSelectF(1);
              updateTextFade(1);
            }}
          >
            Fade out
          </span>
        </div>
      </div>
      <div className="text-item">
        <div className="text-zoom anima-hover">
          <span
            className={selectZ === 3 ? "selected11" : ""}
            onClick={() => {
              setSelectZ(3);
              updateTextZoom(0);
            }}
          >
            None
          </span>
          <span
            className={selectZ === 2 ? "selected11" : ""}
            onClick={() => {
              setSelectZ(2);
              updateTextZoom(2);
            }}
          >
            Zoom in
          </span>
          <span
            className={selectZ === 1 ? "selected11" : ""}
            onClick={() => {
              setSelectZ(1);
              updateTextZoom(1);
            }}
          >
            Zoom out
          </span>
        </div>
      </div>
    </div>
  );
};

export default TextAnimations;
