import React, { useState, useEffect } from "react";

const FineTune = ({
  panel,
  research,
  setResearch,
  research2,
  setResearch2,
  writing,
  setWriting,
  creativity,
  setCreativity,
  linksnum,
  setLinksnum,
  num,
  setNum,
  bul,
  setBul,
  intro,
  setIntro,
  conc,
  setConc,
  subtopictag,
  setSubtopictag,
  subheadingtag,
  setSubheadingtag,
  autoImage,
  setAutoImage,
}) => {
  const themeValues = ["h2", "h3", "h4", "h5"];
  const subValues = ["h3", "h4", "h5", "h6"];

  const [writingMin, setWritingMin] = useState(0);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = parseInt(event.target.value);

    if (name === "research") {
      setResearch(value);
      if (value >= 45) {
        setWriting(value);
      }
    } else if (name === "research2") {
      setResearch2(value);
    } else if (name === "writing") {
      if (research >= 45 && value < research) {
        setWriting(research);
      } else if (research < 45 || value <= 100) {
        setWriting(value);
      }
    } else if (name === "creativity") {
      setCreativity(value);
    } else if (name === "linksnum") {
      setLinksnum(value);
    }
  };

  // useEffect(() => {
  //   const newWritingValue = ((research - 45) / 55) * 100;
  //   setWriting(Math.max(newWritingValue, 30));
  // }, [research]);

  return (
    <div className="fine-tune">
      <div className="toggle-buttons">
        <div className="toggle-box">
          <div className="toggle-text">Number Sub-topics</div>
          <div className="toggle-area">
            <span>OFF</span>
            <div className="toggle-button">
              <input
                type="checkbox"
                id="toggle1"
                name="num"
                checked={num}
                onChange={() => setNum(!num)}
              />{" "}
              <label htmlFor="toggle1" />
            </div>
            <span>ON</span>
          </div>
        </div>
      </div>
      <div className="toggle-buttons">
        <div className="toggle-box">
          <div className="toggle-text">Auto Insert Photos</div>
          <div className="toggle-area">
            <span>OFF</span>
            <div className="toggle-button">
              <input
                type="checkbox"
                id="toggle2"
                name="autoImage"
                checked={autoImage}
                onChange={() => setAutoImage(!autoImage)}
              />{" "}
              <label htmlFor="toggle2" />
            </div>
            <span>ON</span>
          </div>
        </div>
      </div>
      <div className="toggle-buttons">
        <div className="toggle-box">
          <div className="toggle-text">Bullets under Sub-headings</div>
          <div className="toggle-area">
            <span>OFF</span>
            <div className="toggle-button">
              <input
                type="checkbox"
                id="toggle3"
                name="bul"
                checked={bul}
                onChange={() => setBul(!bul)}
              />{" "}
              <label htmlFor="toggle3" />
            </div>
            <span>ON</span>
          </div>
        </div>
      </div>
      <div className="toggle-buttons">
        <div className="toggle-box">
          <div className="toggle-text">Add an Intro</div>
          <div className="toggle-area">
            <span>OFF</span>
            <div className="toggle-button">
              <input
                type="checkbox"
                id="toggle4"
                name="intro"
                checked={intro}
                onChange={() => setIntro(!intro)}
              />{" "}
              <label htmlFor="toggle4" />
            </div>
            <span>ON</span>
          </div>
        </div>
      </div>
      <div className="toggle-buttons">
        <div className="toggle-box">
          <div className="toggle-text">Add a Conclusion</div>
          <div className="toggle-area">
            <span>OFF</span>
            <div className="toggle-button">
              <input
                type="checkbox"
                id="toggle5"
                name="conc"
                checked={conc}
                onChange={() => setConc(!conc)}
              />{" "}
              <label htmlFor="toggle5" />
            </div>
            <span>ON</span>
          </div>
        </div>
      </div>
      <div className="range-slider">
        <div className="range-top">
          <span>Outline Research</span>
          <input
            type="text"
            value={research2}
            onChange={(e) => setResearch2(e.target.value)}
          />
        </div>
        <div className="the-range">
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            name="research2"
            value={research2}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="range-slider">
        <div className="range-top">
          <span>Article Research</span>
          <input
            type="text"
            value={research}
            onChange={(e) => setResearch(e.target.value)}
          />
        </div>
        <div className="the-range">
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            name="research"
            value={research}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="range-slider">
        <div className="range-top">
          <span>Writing Length</span>
          <input
            type="text"
            value={writing}
            readOnly={true}
            // onChange={(e) => {setWriting(e.target.value)}}
          />
        </div>
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          name="writing"
          value={writing}
          onChange={handleChange}
        />
      </div>
      <div className="range-slider">
        <div className="range-top">
          <span>Creativity level</span>
          <input
            type="text"
            value={creativity}
            onChange={(e) => setCreativity(e.target.value)}
          />
        </div>
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          name="creativity"
          value={creativity}
          onChange={handleChange}
        />
      </div>
      <div className="range-slider">
        <div className="range-top">
          <span>Insert Contextual Links</span>
          <input
            type="text"
            value={linksnum}
            onChange={(e) => setLinksnum(e.target.value)}
          />
        </div>
        <input
          type="range"
          min="0"
          max="4"
          step="1"
          name="linksnum"
          value={linksnum}
          onChange={handleChange}
        />
      </div>
      <div className="tags-top">
        <div className="tags-text">Sub-topics tag</div>
        <div className="h-tags-container">
          {themeValues.map((value) => (
            <button
              className={`slider-button ${
                subtopictag === value ? "selected" : ""
              }`}
              key={value}
              name="sliderValue"
              value={value}
              onClick={(e) => setSubtopictag(e.target.value)}
            >
              {value}
            </button>
          ))}
        </div>
      </div>

      <div className="tags-top">
        <div className="tags-text">Sub-headings tag</div>
        <div className="h-tags-container h-btm">
          {subValues.map((value) => (
            <button
              className={`slider-button ${
                subheadingtag === value ? "selected" : ""
              }`}
              key={value}
              name="sliderValue"
              value={value}
              onClick={(e) => setSubheadingtag(e.target.value)}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FineTune;
