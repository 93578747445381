import React, { useState, useEffect, useRef } from "react";
import ProgressMeta2 from "./ProgressMeta2";
import Slider from "./Slider";
import Confetti from "react-confetti";

const MobileProgress = ({
  db,
  wordCount,
  research22,
  aisize,
  percent,
  expected,
  rExpected,
  rComplete,
  wComplete,
  setrComplete,
  setwComplete,
  write,
  setWrite,
  setLoading2,
  calc,
  section2,
}) => {
  const [timer, setTimer] = useState(3);

  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(interval);
      setShowSlider(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  console.log(`showSlider is ${showSlider}`);

  return (
    <>
      <ProgressMeta2
        wordCount={wordCount}
        research22={research22}
        aisize={aisize}
        percent={percent}
        expected={expected}
        rExpected={rExpected}
        rComplete={rComplete}
        wComplete={wComplete}
        setrComplete={setrComplete}
        setwComplete={setwComplete}
        write={write}
        calc={calc}
        setLoading2={setLoading2}
        setWrite={setWrite}
      />
      <div
        className={
          write ? "mobile-progress-content" : "mobile-progress-not-started"
        }
      >
        {write ? (
          <>
            <h2>
              Hang tight, Sparker AI is doing the research and writing for you..
            </h2>{" "}
            {showSlider ? (
              <Slider db={db} percent={percent} />
            ) : (
              <div className="progress-slider2">
                <div className="loader5"></div>
                <div className="loader5-text">loading tidbits...</div>
              </div>
            )}
          </>
        ) : percent < 0.1 && !wComplete ? (
          <img
            style={{ width: `100%`, borderRadius: `5px`, maxHeight: "100%" }}
            src="http://sparker.ai/wp-content/uploads/2023/07/Click-the-4.gif"
          />
        ) : percent > 1 && wComplete ? (
          <img
            style={{ width: `100%`, borderRadius: `5px`, maxHeight: "100%" }}
            src="http://sparker.ai/wp-content/uploads/2023/07/remove-plag.png"
          />
        ) : percent < 1 && wComplete ? (
          <img
            style={{ width: `100%`, borderRadius: `5px`, maxHeight: "100%" }}
            src="http://sparker.ai/wp-content/uploads/2023/07/congrats-article-complete-1.png"
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MobileProgress;
