import React, { useState } from "react";

import SceneAudio from "./SceneAudio";
import SceneBG from "./SceneBG";
import SceneButtons from "./SceneButtons";
import SceneText from "./SceneText";

const CurrentScene = ({
  currentIndex,
  setChanges,
  setSceneDurations,
  sceneDurations,
  updateVideoUrl,
  updateImageUrl,
  updateTextTranslate,
  updateImageZoom,
  updateTextFade,
  updateTextZoom,
  sequences,
  updateTextColor,
  updateBackgroundColor,
  updateTextWidth,
  updateTextHeight,
  updatedData,
  setUpdatedData,
  applyChanges,
  updatePosition,
  setFontFamily,
  fontFamily,
  updateFontFamily,
  selectedDiv3,
  setSelectedDiv3,
  updateTextBg,
  updateTextPadding,
  updateTextMargin,
  updateFontSize,
  applyChangesAtIndex,
  updateTextAlign,
  updateMusicVolume,
  updateVoiceVolume,
  music,
  removeField,
}) => {
  // Assuming 'sceneDurations' is the state variable holding your array

  const adjustSceneDurations = (indexToRemove) => {
    setSceneDurations((prevSceneDurations) => {
      const updatedSceneDurations = [...prevSceneDurations];
      updatedSceneDurations.splice(indexToRemove, 1);

      for (let i = indexToRemove; i < updatedSceneDurations.length; i++) {
        if (i === 0) {
          updatedSceneDurations[i].from = 0;
        } else {
          const prevScene = updatedSceneDurations[i - 1];
          updatedSceneDurations[i].from =
            prevScene.from + prevScene.durationInFrames + 1;
        }
      }

      return updatedSceneDurations;
    });
  };

  const removeScene = (index) => {
    // Make a copy of the original array
    const updatedDataCopy = [...updatedData];

    // Remove the object at the specified index
    updatedDataCopy.splice(index, 1);

    // Update the state with the modified array
    setUpdatedData(updatedDataCopy);
    adjustSceneDurations(index);
  };

  //  const updateTextColor = (newColor) => {
  //    // Perform the desired actions with the newColor
  //    console.log("New color selected:", newColor);
  //  };

  // console.log(`current index is ${currentIndex}`)

  return (
    <>
      <SceneButtons
        selectedDiv3={selectedDiv3}
        setSelectedDiv3={setSelectedDiv3}
      />
      <div className="current-scene">
        <>
          Scene Number: <span>{`${currentIndex + 1}`}</span>{" "}
        </>
        <span
          className="delete-scene"
          onClick={() => {
            removeScene(currentIndex);
          }}
        >
          delete{" "}
        </span>
      </div>

      <div className="apply-buttons">
        <div
          className="apply-all"
          onClick={() => {
            applyChangesAtIndex(currentIndex, sequences[currentIndex].meta);
          }}
        >
          Apply to all
        </div>
        <div
          className="apply-all"
          onClick={() => {
            setChanges([]);
          }}
        >
          Cancel
        </div>
      </div>

      <div className="right-bar">
        {selectedDiv3 === 0 ? (
          <SceneText
            updateTextTranslate={updateTextTranslate}
            updateTextFade={updateTextFade}
            updateTextZoom={updateTextZoom}
            updateTextColor={updateTextColor}
            updateTextBg={updateTextBg}
            updatePosition={updatePosition}
            updateTextWidth={updateTextWidth}
            updateTextHeight={updateTextHeight}
            setFontFamily={setFontFamily}
            fontFamily={fontFamily}
            updateFontFamily={updateFontFamily}
            updateTextPadding={updateTextPadding}
            updateTextMargin={updateTextMargin}
            updateFontSize={updateFontSize}
            updatedData={updatedData}
            currentIndex={currentIndex}
            updateTextAlign={updateTextAlign}
          />
        ) : (
          ""
        )}
        {selectedDiv3 === 1 ? (
          <SceneBG
            updateVideoUrl={updateVideoUrl}
            updateImageUrl={updateImageUrl}
            updateBackgroundColor={updateBackgroundColor}
            updatedData={updatedData}
            currentIndex={currentIndex}
            removeField={removeField}
            updateImageZoom={updateImageZoom}
          />
        ) : (
          ""
        )}
        {selectedDiv3 === 2 ? (
          <SceneAudio
            music={music}
            updateMusicVolume={updateMusicVolume}
            updateVoiceVolume={updateVoiceVolume}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CurrentScene;
