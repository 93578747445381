import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";

const Slider = ({ db, percent }) => {
  const [documents, setDocuments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timer, setTimer] = useState(7);

  // Fetch documents from Firebase on component mount
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const q = query(collection(db, "progress"), orderBy("index"));
        const snapshot = await getDocs(q);
        const fetchedDocuments = snapshot.docs.map((doc) => doc.data());
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.log("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, []);

  // Start the timer
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the timer reaches 0
    if (timer === 0) {
      clearInterval(interval);
      moveToNextSlide();
    }

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [timer]);

  // Move to the next slide
  const moveToNextSlide = () => {
    setTimer(7);
    setCurrentIndex((prevIndex) =>
      prevIndex === documents.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Move to the previous slide
  const moveToPreviousSlide = () => {
    setTimer(7);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? documents.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="progress-slider">
      <div className="progress-slider-btns">
        <button onClick={moveToPreviousSlide}>←</button>
        <button onClick={moveToNextSlide}>→</button>
      </div>
      <img
        src={documents[currentIndex]?.image}
        alt={`Slide ${currentIndex + 1}`}
      />
    </div>
  );
};

export default Slider;
