import React, { useState } from "react";
import { SketchPicker } from "react-color";

const SceneBG = ({
  updateBackgroundColor,
  updatedData,
  currentIndex,
  removeField,
  updateImageZoom,
  updateVideoUrl,
  updateImageUrl,
}) => {
  const [color, setColor] = useState("rgba(255, 255, 255, 1)");
  const [zoom, setZoom] = useState();

  const [showPicker, setShowPicker] = useState(false);

  const handleColorChange = (color) => {
    const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;

    setColor(rgbaColor);
    updateBackgroundColor(rgbaColor);
  };

  const closeModal = () => {
    setShowPicker(false);
  };

  return (
    <>
      {/* <button
        onClick={() => {
          setShowPicker(true);
        }}
      >
        Open Color Picker
      </button> */}

      {/* {showPicker && (
        <div className="picker-modal">
          <div className="[ocker-modal-content">
            <SketchPicker
              color={color}
              onChange={(newColor) => handleColorChange(newColor)}
            />
            <div className="close-picker" onClick={closeModal}>
              x close
            </div>
          </div>
        </div>
      )} */}

      <div className="text-item">
        <span>Scene Background</span>
        <div
          className="color-box"
          style={{
            backgroundColor: updatedData?.[currentIndex]?.backgroundColor
              ? updatedData?.[currentIndex]?.backgroundColor
              : "white",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowPicker(!showPicker);
          }}
        ></div>

        {showPicker && (
          <div className="picker-modal">
            <div className="[ocker-modal-content">
              <SketchPicker
                color={color}
                onChange={(newColor) => handleColorChange(newColor)}
              />
              <div className="close-picker" onClick={closeModal}>
                x close
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="scene-bg-item">
        {updatedData?.[currentIndex]?.imageUrl && (
          <>
            <div className="text-item">
              <span>Scene Image</span>
              <div className="scene-bg-image">
                <img src={updatedData?.[currentIndex]?.imageUrl} />
                <div
                  className="delete-img"
                  onClick={() => {
                    // removeField(currentIndex, "imageUrl");
                    updateImageUrl(null);
                  }}
                >
                  X
                </div>
              </div>
            </div>
            <div className="img-zoom anima-hover">
              <span
                className={zoom === 0 ? "selected11" : ""}
                onClick={() => {
                  setZoom(0);
                  updateImageZoom(0);
                }}
              >
                No zoom
              </span>
              <span
                className={zoom === 2 ? "selected11" : ""}
                onClick={() => {
                  setZoom(2);
                  updateImageZoom(2);
                }}
              >
                Zoom in
              </span>
              <span
                className={zoom === 1 ? "selected11" : ""}
                onClick={() => {
                  setZoom(1);
                  updateImageZoom(1);
                }}
              >
                Zoom out
              </span>
            </div>
          </>
        )}
      </div>

      <div className="text-item scene-bg-item">
        {updatedData?.[currentIndex]?.videoImage && (
          <>
            <span>Scene Video</span>
            <div className="scene-bg-image">
              <img
                src={updatedData?.[currentIndex]?.videoImage}
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",

                  objectFit: "cover",
                }}
              />
              <div
                className="delete-img"
                onClick={() => {
                  // removeField(currentIndex, "videoImage", "videoUrl");
                  updateVideoUrl(null, null, null);
                }}
              >
                X
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SceneBG;
