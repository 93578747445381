import React, { useState } from "react";

const PexelSearch = ({ insertImage, autoImage, setAutoImage }) => {
  const [photos, setPhotos] = useState([[]]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1); //new state variable

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPhotos([[]]);
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${searchTerm}&per_page=80&page=1`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      setPhotos([data.photos]);
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  const handleMore = async () => {
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${searchTerm}&per_page=80&page=${page}`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      photos.push(data.photos);
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  const handleLoadMore = async () => {
    setPage(page + 1);
    handleMore();
  };

  const clearPhotos = async () => {
    setPhotos([[]]);
  };

  return (
    <div>
      <div className="toggle-buttons">
        <div className="toggle-box">
          <div className="toggle-text">Auto Insert Photos</div>
          <div className="toggle-area">
            <span>OFF</span>
            <div className="toggle-button">
              <input
                type="checkbox"
                id="toggle2"
                name="autoImage"
                checked={autoImage}
                onChange={() => setAutoImage(!autoImage)}
              />{" "}
              <label htmlFor="toggle2" />
            </div>
            <span>ON</span>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          className="gen-input"
          type="text"
          placeholder="Search photos"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="list-gen-btn" type="submit">
          Search
        </button>
      </form>
      {photos.map((photosArray, i) => (
        <div key={i} className="photos-container">
          {photosArray.map((photo, j) => (
            <img
              key={`${i}-${j}`}
              src={photo.src.medium}
              alt={photo.photographer}
              onClick={() => insertImage(photo.src.large)}
            />
          ))}
        </div>
      ))}

      <div
        className={photos?.[0].length > 0 ? "photos-btm" : "hide"}
        onClick={handleLoadMore}
      >
        <div className="loadmore" onClick={handleLoadMore}>
          Load More
        </div>
        <div className="clear-photos" onClick={clearPhotos}>
          Clear All
        </div>
      </div>
    </div>
  );
};

export default PexelSearch;
