import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

const SparkerMenu = ({
  expectedWords,
  db,
  availableTokens,
  setSection1,
  section1,
}) => {
  const navigate = useNavigate();
  const { user } = UserAuth();

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  ////------Check Exceeds-------////

  const [exceeds, setExceeds] = useState(false);

  useEffect(() => {
    if (expectedWords + 100 > availableTokens) {
      setExceeds(true);
    } else {
      setExceeds(false);
    }
  }, [expectedWords, availableTokens]);

  return (
    <div className="left-panel-item">
      {" "}
      <div className="user">
        <div className="left">
          <Link to="/">← Home</Link>
        </div>
        <div className="right">
          <span>{user.displayName[0]}</span>
        </div>
      </div>
      <div className="tokens">
        <div className="left">
          <img src="http://sparker.ai/wp-content/uploads/2023/04/tokens-2.png" />
        </div>
        <div className="right">
          <span className="amount">
            ${availableTokens}
            <span className="heading"> available</span>
          </span>

          <button
            className="link"
            onClick={(e) => {
              e.stopPropagation();
              navigate("/top-up");
            }}
          >
            Top Up
          </button>
        </div>
      </div>
      <div
        className="subscription"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/manage-subscription");
        }}
      >
        <div className="left">
          <img src="http://sparker.ai/wp-content/uploads/2023/04/subscription.png" />
        </div>
        <div className="right">
          <span className="heading">Manage Subscription</span>
        </div>
      </div>
      <div className="item expected">
        <div className="left">
          <img src="http://sparker.ai/wp-content/uploads/2023/04/predicted.png" />
        </div>
        <div className="right">
          <span className="heading">
            Predicted usage ≈{" "}
            {exceeds ? (
              <>
                <span className="red">
                  {expectedWords + 400}
                  <sup>*</sup>
                </span>{" "}
                tokens !
              </>
            ) : (
              <>
                <span className="blue">
                  {expectedWords + 400}
                  <sup>*</sup>
                </span>{" "}
                tokens
              </>
            )}
          </span>
        </div>
      </div>
      <div className="expected-message">
        {exceeds ? (
          <>
            <span>
              Your predicted tokens (~ words) exceed available tokens.
            </span>
            <span>
              We recommend that you top-up tokens or upgrade to a higher plan to
              avoid any unexpected cut-off.
            </span>
            <span>
              For Spark Plan subscribers, once writing has started, it will
              continue into credit (to a maximum of ≈ 10% the limit for next
              month).
            </span>
          </>
        ) : (
          <>
            <span>
              In our estimate you have enough tokens to cover your current
              writing configuration.
            </span>
          </>
        )}
      </div>
      <span className="disclaimer">
        * This is a very rough estimate and actual results may vary
        significantly. We only charge you for actual usage. 1 token ≈ 1 word.
      </span>
    </div>
  );
};

export default SparkerMenu;
