import React, { useState } from "react";
import FontPicker from "./FontPicker";
import { SketchPicker } from "react-color";
import TextAnimations from "./TextAnimations";

const SceneText = ({
  updateTextTranslate,
  updateTextFade,
  updateTextZoom,
  updateTextColor,
  updatePosition,
  updateTextWidth,
  updateTextHeight,
  setFontFamily,
  fontFamily,
  updateFontFamily,
  updateTextBg,
  updateTextPadding,
  updateTextMargin,
  updateFontSize,
  currentIndex,
  updatedData,
  updateTextAlign,
}) => {
  const [color, setColor] = useState("rgba(0, 0, 0, 1)");
  const [color2, setColor2] = useState("rgba(255, 255, 255, 1)");

  const [position, setPosition] = useState(5);
  const [showPicker, setShowPicker] = useState(false);
  const [showPicker2, setShowPicker2] = useState(false);
  const [textWidth, setTextWidth] = useState(800); // Initial text width
  const [textAlign, setTextAlign] = useState("center"); // Initial text width
  const [showAnimations, setShowAnimations] = useState(false);

  const handleSliderChange = (event) => {
    const width = parseInt(event.target.value);
    setTextWidth(width);
    updateTextWidth(width);
  };

  const [textHeight, setTextHeight] = useState(100); // Initial text width

  const handleSliderChange2 = (event) => {
    const height = parseInt(event.target.value);
    setTextHeight(height);
    updateTextHeight(height);
  };

  const [textPadding, setTextPadding] = useState(100); // Initial text width

  const handleSliderChange3 = (event) => {
    const padding = parseInt(event.target.value);
    setTextPadding(padding);
    updateTextPadding(padding);
  };

  const [textMargin, setTextMargin] = useState(100); // Initial text width

  const handleSliderChange4 = (event) => {
    const margin = parseInt(event.target.value);
    setTextMargin(margin);
    updateTextMargin(margin);
  };

  const [fontSize, setFontSize] = useState(20); // Initial text width

  const handleSliderChange5 = (event) => {
    const fontSize = parseInt(event.target.value);
    setFontSize(fontSize);
    updateFontSize(fontSize);
  };

  const handleColorChange = (color) => {
    const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;

    setColor(rgbaColor);
    updateTextColor(rgbaColor);
  };

  const handleColorChange2 = (color) => {
    const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;

    setColor2(rgbaColor);
    updateTextBg(rgbaColor);
  };

  const closeModal = () => {
    setShowPicker(false);
  };

  const closeModal2 = () => {
    setShowPicker2(false);
  };

  return (
    <div>
      {" "}
      {showAnimations ? (
        <TextAnimations
          setShowAnimations={setShowAnimations}
          updateTextTranslate={updateTextTranslate}
          updateTextFade={updateTextFade}
          updateTextZoom={updateTextZoom}
        />
      ) : (
        ""
      )}
      <div className="text-item">
        <span>Animate</span>
        <button
          className="text-animate"
          onClick={() => {
            setShowAnimations(!showAnimations);
          }}
        >
          Show Animations
        </button>
      </div>
      <div className="text-item">
        <span>Font Family</span>
        <FontPicker
          setFontFamily={setFontFamily}
          fontFamily={fontFamily}
          updateFontFamily={updateFontFamily}
        />
      </div>
      <div className="text-item">
        <span>Font Size</span>
        <input
          type="range"
          min="0"
          max="500"
          value={fontSize}
          onChange={handleSliderChange5}
        />
      </div>
      <div className="text-item">
        <span>Text Align</span>
        <div>
          <button
            onClick={() => {
              updateTextAlign("left");
            }}
          >
            left
          </button>
          <button
            onClick={() => {
              updateTextAlign("center");
            }}
          >
            center
          </button>
          <button
            onClick={() => {
              updateTextAlign("right");
            }}
          >
            right
          </button>
        </div>
      </div>
      <div className="text-item">
        <span>Text Color</span>
        <div
          className="color-box"
          style={{
            backgroundColor: updatedData?.[currentIndex]?.textColor
              ? updatedData?.[currentIndex]?.textColor
              : "white",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowPicker(!showPicker);
          }}
        ></div>

        {showPicker && (
          <div className="picker-modal">
            <div className="[ocker-modal-content">
              <SketchPicker
                color={color}
                onChange={(newColor) => handleColorChange(newColor)}
              />
              <div className="close-picker" onClick={closeModal}>
                x close
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-item">
        <span>Padding Color</span>
        <div
          className="color-box"
          style={{
            backgroundColor: updatedData?.[currentIndex]?.textBg
              ? updatedData?.[currentIndex]?.textBg
              : "white",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowPicker2(!showPicker2);
          }}
        ></div>
        {showPicker2 && (
          <div className="picker-modal">
            <div className="[ocker-modal-content">
              <SketchPicker
                color={color2}
                onChange={(newColor) => handleColorChange2(newColor)}
              />
              <div className="close-picker" onClick={closeModal2}>
                x close
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-item">
        <span className="text-position">Postion</span>

        <div className="position-grid-container">
          <div
            className={position === 1 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(1);
              setPosition(1);
            }}
          ></div>
          <div
            className={position === 2 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(2);
              setPosition(2);
            }}
          ></div>
          <div
            className={position === 3 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(3);
              setPosition(3);
            }}
          ></div>
          <div
            className={position === 4 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(4);
              setPosition(4);
            }}
          ></div>
          <div
            className={position === 5 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(5);
              setPosition(5);
            }}
          ></div>
          <div
            className={position === 6 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(6);
              setPosition(6);
            }}
          ></div>
          <div
            className={position === 7 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(7);
              setPosition(7);
            }}
          ></div>
          <div
            className={position === 8 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(8);
              setPosition(8);
            }}
          ></div>
          <div
            className={position === 9 ? "grid-item selected3" : "grid-item"}
            onClick={() => {
              updatePosition(9);
              setPosition(9);
            }}
          ></div>
        </div>
      </div>
      <div className="text-item">
        <span>Width</span>
        <input
          type="range"
          min="0"
          max="1920"
          value={textWidth}
          onChange={handleSliderChange}
        />
      </div>
      <div className="text-item">
        <span>Height</span>
        <input
          type="range"
          min="0"
          max="1080"
          value={textHeight}
          onChange={handleSliderChange2}
        />
      </div>
      <div className="text-item">
        <span>Padding</span>
        <input
          type="range"
          min="0"
          max="500"
          value={textPadding}
          onChange={handleSliderChange3}
        />
      </div>
      <div className="text-item">
        <span>Margins</span>
        <input
          type="range"
          min="0"
          max="1920"
          value={textMargin}
          onChange={handleSliderChange4}
        />
      </div>
    </div>
  );
};

export default SceneText;
