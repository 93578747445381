import React, { useState, useEffect, useRef } from "react";
import SubHeadings from "./SubHeadings";
import SubHeadings2 from "./SubHeadings2";
import SubHeadings3 from "./SubHeadings3";

import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";

const Outline10 = ({ newName, setNewName, setPromp2, readyToWrite }) => {
  //---------------STATES-----------------------//

  const [loading, setLoading] = useState(false);
  // const [plot, setPlot] = useState(true);
  // const [char, setChar] = useState(false);
  const [text, setText] = useState("");

  const initialRender = useRef(true);

  //------------------------------------------//
  const [genre, setGenre] = useState("");

  //--------------DATABASE REF----------------//

  //-----------------------------------------//

  // const plotTab = () => {
  //   setChar(false);
  //   setPlot(true);
  // };

  // const charTab = () => {
  //   setChar(true);
  //   setPlot(false);
  // };
  //-----------------API CALL----------------//

  //---------------------------------------//

  //--------------ADD TO DATABASE---------------//

  //------------------------------------------//

  //------------clearList Function----------------//

  //---------------------------------------------//

  //---------------Event Handler-----------------//

  const handleChange = (event) => {
    setText(event.target.value);
  };

  //---------------------------------------------//

  let promp = `I want to write a cold email using the insights from the best email marketing books such as "Cold Email Outreach: A Comprehensive Guide" by Sujan Patel and "The Cold Emailing Playbook" by Steli Efti.\n\nDetails for email:\n${text}\n\nUsing all this information and insights from the books write an email in HTML with a ${genre} tone:`;

  //----------------API CALL-------------//

  //-----------------------------------------------//

  //-----------------Remove Item-------------------//

  //-------------------------------------------------//

  //--------------------Add Item---------------------//

  //-------------------------------------------------//

  //------------SEND DATA TO TEXTAREA----------------//

  useEffect(() => {
    setPromp2(promp);
  }, [text, genre]);
  //-------------------------------------------------------------------------------------//
  // Define a function that will be called when the 'Move Up' button is clicked

  //---------------------------//

  //------------------------------------//
  return (
    <>
      <div className="email-area">
        <div className="form-area2">
          <div className="form-title">Add some details for the email</div>
          <button
            disabled={text.length < 20}
            className="ready-to-write"
            onClick={readyToWrite}
          >
            ✓ Ready to Write
          </button>
          <textarea
            className="form-text"
            placeholder="Briefly tell us about your target recipient and how you can help them. You can also specify an outline the AI should follow."
            value={text}
            onChange={(event) => handleChange(event)}
          />
          <button
            className="clear-list-btn"
            onClick={() => {
              setText("");
            }}
          >
            Clear
          </button>
        </div>
        <div className="tones-area">
          <div className="genre-top">
            <input
              className="gen-input"
              placeholder="Tone of voice"
              value={genre}
              onChange={(e) => {
                e.preventDefault();
                setGenre(e.target.value);
              }}
            />
          </div>
          <div className="tones">
            <div
              className={genre === "Friendly" ? "genre" : "genre2"}
              onClick={() => setGenre("Friendly")}
            >
              Friendly
            </div>
            <div
              className={genre === "Professional" ? "genre" : "genre2"}
              onClick={() => setGenre("Professional")}
            >
              Professional
            </div>
            <div
              className={genre === "Relaxed" ? "genre" : "genre2"}
              onClick={() => setGenre("Relaxed")}
            >
              Relaxed
            </div>
            <div
              className={genre === "Bold" ? "genre" : "genre2"}
              onClick={() => setGenre("Bold")}
            >
              Bold
            </div>
            <div
              className={genre === "Witty" ? "genre" : "genre2"}
              onClick={() => setGenre("Witty")}
            >
              Witty
            </div>

            <div
              className={genre === "Polite" ? "genre" : "genre2"}
              onClick={() => setGenre("Polite")}
            >
              Polite
            </div>
            <div
              className={genre === "Empathetic" ? "genre" : "genre2"}
              onClick={() => setGenre("Empathetic")}
            >
              Empathetic
            </div>
            <div
              className={genre === "Respectful" ? "genre" : "genre2"}
              onClick={() => setGenre("Respectful")}
            >
              Respectful
            </div>

            <div
              className={genre === "Luxury" ? "genre" : "genre2"}
              onClick={() => setGenre("Luxury")}
            >
              Luxury
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Outline10;
