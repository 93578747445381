import { useEffect, useRef, useState } from "react";

function CheckRefresh({ setPanel, panel }) {
  //   const [refreshed, setRefreshed] = useState(false);

  const refreshRef = useRef(false);

  useEffect(() => {
    // Check if the value of the refreshRef has changed
    const storedValue = localStorage.getItem("refreshRef");
    if (storedValue === "true") {
      localStorage.setItem("refreshRef", false);

      setPanel(999);
      setTimeout(() => {
        setPanel(1);
      }, 2000);
    }

    // Set the initial value of the refreshRef and store it in localStorage
    localStorage.setItem("refreshRef", refreshRef.current);

    // Add an event listener to update the value of refreshRef on page refresh
    const handleRefresh = () => {
      localStorage.setItem("refreshRef", true);
    };
    window.addEventListener("beforeunload", handleRefresh);

    // Clean up the event listener and remove the stored value from localStorage
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
      localStorage.removeItem("refreshRef");
    };
  }, []);

  return <></>;
}

export default CheckRefresh;
