import React, { useState } from "react";

const PexelSearch = ({ updateImageUrl, updateImageArray }) => {
  const [photos, setPhotos] = useState([[]]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1); //new state variable

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPhotos([[]]);
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${searchTerm}&per_page=80&page=1`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      setPhotos([data.photos]);
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  const handleMore = async () => {
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${searchTerm}&per_page=80&page=${page}`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      photos.push(data.photos);
      console.log(photos);
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  const handleLoadMore = async () => {
    setPage(page + 1);
    handleMore();
  };

  const clearPhotos = async () => {
    setPhotos([[]]);
  };

  const bulkAddImages = () => {
    const imgArray = [];

    photos.forEach((photoArr) => {
      photoArr.forEach((photo) => {
        const image = photo.src.large2x;

        if (image) {
          imgArray.push(image);
        }
      });
    });

    updateImageArray(imgArray);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          className="gen-input"
          type="text"
          placeholder="Search photos"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="list-gen-btn" type="submit">
          Search
        </button>
      </form>
      <button className="bulk-add" onClick={bulkAddImages}>
        Bulk add images
      </button>
      {photos.map((photosArray, i) => (
        <div key={i}>
          <div className="photos-container">
            {photosArray.map((photo, j) => (
              <img
                key={`${i}-${j}`}
                src={photo.src.medium}
                alt={photo.photographer}
                onClick={() => updateImageUrl(photo.src.large2x)}
              />
            ))}
          </div>
        </div>
      ))}

      <div
        className={photos?.[0].length > 0 ? "photos-btm" : "hide"}
        onClick={handleLoadMore}
      >
        <div className="loadmore" onClick={handleLoadMore}>
          Load More
        </div>
        <div className="clear-photos" onClick={clearPhotos}>
          Clear All
        </div>
      </div>
    </div>
  );
};

export default PexelSearch;
