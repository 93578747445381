import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { collection, addDoc, getDocs, onSnapshot } from "firebase/firestore";
import PortalButton from "../Components/PortalButton";

const Account = ({ db }) => {
  const { logOut, user  } = UserAuth();
  const navigate = useNavigate();

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const [menu, setMenu] = useState(false);

  // user.emailVerified ? navigate('/account') : user ? navigate('/verify') : navigate('/');

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
    }
  };


  ////--------------------------------/////
  const [activePlan, setActivePlan] = useState("Free Plan");

  useEffect(() => {
    async function checkSubscriptionStatus() {
      try {
        const subscriptionRef = collection(db, `users/${userID}/subscriptions`);
        const snapshot = await getDocs(subscriptionRef);

        if (snapshot.empty) {
          setActivePlan("Free Plan"); // subscription collection doesn't exist
        } else {
          let isActive = false;
          snapshot.forEach((doc) => {
            if (doc.data().status === "active") {
              isActive = true;
              setActivePlan(doc.data().items[0].price.product.name); // set activePlan based on active subscription
            }
          });
        }
      } catch (error) {
      }
    }
    checkSubscriptionStatus();
  }, [userID]);

  //////////Available Tokens/////////
  const [availableTokens, setAvailableTokens] = useState();

  useEffect(() => {
    async function getAvailableTokens() {
      const querySnapshot = await getDocs(
        collection(db, `users/${userID}/tokens/`)
      );

      let sum = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.tokens) {
          sum += data.tokens;
        }
      });
      const availableTokens = (sum / 2000).toFixed(2);

      setAvailableTokens(availableTokens);
    }

    getAvailableTokens();
  }, [userID]);

  return (
    <>
      <div
        className="main-page"
        onClick={() => {
          setMenu(false);
        }}
      >
        <Navbar Link={Link} db={db} menu={menu} setMenu={setMenu} />
        <div className="account-container">
          <div className="item">
            <img src={user.photoURL} />
          </div>
          <div className="item">
            <div className="left">Name:</div>
            <div className="right">
              {" "}
              <input value={user.displayName || ""} disabled={true} />
            </div>
          </div>
          <div className="item">
            <div className="left">Email:</div>
            <div className="right">
              {" "}
              <input value={user.email || ""} disabled={true} />
            </div>
          </div>
          <div className="item">
            <div className="left">Password:</div>
            <div className="right">
              {" "}
              <input value="****" disabled={true} />
            </div>
          </div>
          <div className="item">
            <div className="left">Active Plan:</div>
            <div className="right">
              {" "}
              <span className="plans-style">{activePlan}</span>
            </div>
          </div>
          {/* <div className="item">
            <div className="left">Billing:</div>
            <div className="right">
              {" "}
              <PortalButton text={"Manage"} />{" "}
            </div>
          </div> */}

          <div className="item">
            <div className="left">Credit:</div>
            <div className="right">
              {" "}
              <span className="tokens-style">${availableTokens}</span>available
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
