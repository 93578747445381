import React, { useState } from "react";

const TopButtons = ({selectedDiv2, setSelectedDiv2}) => {

  const handleDivClick = (index) => {
    setSelectedDiv2(index);
  };

  return (
    <div className="top-buttons-container">
      <div
        className={`top-button ${selectedDiv2 === 0 ? "selected2" : ""}`}
        onClick={() => handleDivClick(0)}
      >
        Voices
      </div>
      <div
        className={`top-button ${selectedDiv2 === 1 ? "selected2" : ""}`}
        onClick={() => handleDivClick(1)}
      >
        Images
      </div>
      <div
        className={`top-button ${selectedDiv2 === 2 ? "selected2" : ""}`}
        onClick={() => handleDivClick(2)}
      >
        Videos
      </div>
      <div
        className={`top-button ${selectedDiv2 === 3 ? "selected2" : ""}`}
        onClick={() => handleDivClick(3)}
      >
        Music
      </div>
    </div>
  );
};

export default TopButtons;
