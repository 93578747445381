import React from 'react'

const Templates = ({ setPanel, panel, outF }) => {
  return (
    <div className="templates-area">
      <button
        className={
          panel === 1
            ? "template-icon article-ico"
            : "template-icon article-ico2"
        }
        onClick={() => {setPanel(1); outF();}}
      />
      <button
        className={
          panel === 2
            ? "template-icon compare-ico"
            : "template-icon compare-ico2"
        }
        onClick={() => {
          setPanel(2);
          outF();
        }}
      />
      <button
        className={
          panel === 3 ? "template-icon how-ico" : "template-icon how-ico2"
        }
        onClick={() => {
          setPanel(3);
          outF();
        }}
      />
      <button
        className={
          panel === 4
            ? "template-icon listicle-ico"
            : "template-icon listicle-ico2"
        }
        onClick={() => {
          setPanel(4);
          outF();
        }}
      />
      <button
        className={
          panel === 5
            ? "template-icon creative-ico"
            : "template-icon creative-ico2"
        }
        onClick={() => {
          setPanel(5);
          outF();
        }}
      />
      <button
        className={
          panel === 6 ? "template-icon review-ico" : "template-icon review-ico2"
        }
        onClick={() => {
          setPanel(6);
          outF();
        }}
      />
      <button
        className={
          panel === 7 ? "template-icon ads-ico" : "template-icon ads-ico2"
        }
        onClick={() => {
          setPanel(7);
          outF();
        }}
      />
      <button
        className={
          panel === 8 ? "template-icon pdesc-ico" : "template-icon pdesc-ico2"
        }
        onClick={() => {
          setPanel(8);
          outF();
        }}
      />
      {/* <button
        className={
          panel === 9 ? "template-icon script-ico" : "template-icon script-ico2"
        }
        onClick={() => {
          setPanel(9);
          outF();
        }}
      /> */}
      
      <button
        className={
          panel === 10 ? "template-icon email-ico" : "template-icon email-ico2"
        }
        onClick={() => {
          setPanel(10);
          outF();
        }}
      />
      <button
        className={
          panel === 11
            ? "template-icon paragraph-ico"
            : "template-icon paragraph-ico2"
        }
        onClick={() => {
          setPanel(11);
          outF();
        }}
      />
      {/* <button
        className={
          panel === 12
            ? "template-icon reflections-ico"
            : "template-icon reflections-ico2"
        }
        onClick={() => setPanel(12)}
      /> */}
    </div>
  );
};

export default Templates