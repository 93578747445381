import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

const MusicList = ({ addMusic }) => {
  const [musicList, setMusicList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selection, setSelection] = useState();

  useEffect(() => {
    const fetchMusicList = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "music"));
        const musicData = querySnapshot.docs.map((doc) => doc.data());
        setMusicList(musicData);
      } catch (error) {
        console.error("Error fetching music list: ", error);
      }
    };

    fetchMusicList();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredMusicList = musicList.filter((music) => {
    const { name, tags } = music;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      name.toLowerCase().includes(lowerCaseSearchQuery) ||
      tags.toLowerCase().includes(lowerCaseSearchQuery)
    );
  });

  return (
    <div className="music-list">
      <input
        className="gen-input7"
        type="text"
        value={searchQuery}
        onChange={handleSearchInputChange}
        placeholder="Search music"
      />

      {filteredMusicList.map((music, i) => (
        <div
          key={music.id}
          className={selection === i ? "music-item selection" : "music-item"}
          onClick={() => {
            setSelection(i);
            addMusic(music.url, music.name);
          }}
        >
          <span>{music.name}</span>
          <div className="audio-wrapper">
            <audio controls src={music.url} controlsList="nodownload" />
            {/* <source src={music.url} type="audio/mpeg" />
            </audio> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MusicList;
