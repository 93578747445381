import React, { useState, useEffect } from "react";

const Outline8 = ({ panel, setPromp2, readyToWrite }) => {
  //---------------------------------//
  const [text, setText] = useState("");

  //--------------------------//

  //------------------------//
  // const [frame, setFrame] = useState(1);

  const [plat, setPlat] = useState(1);

  //------------------------------//
  const handleChange = (event) => {
    setText(event.target.value);
  };
  //----------------------//

  //-------------------------//
  /////////

  //////////
  let framework = {
    main: `I want to write a great product description for my product / service (data given below). Subtly add in some testimonials as well. Write me the best product description in HTML giving headings as needed, write at least 300 words:`,
    end: `Awesome product description (in HTML with headings), at least 300 words:`,
  };

  /////////
  //--------------------------------//
  if (panel === 8) {
    let prompt = `${framework.main}\n\nProduct / Service Data:\n${text}\n\n${framework.end}`;
    setPromp2(prompt);
  } else {
  }
  //---------------------------//

  //--------------------------------//

  //-------------------------------//

  return (
    <>
      <div className="form-area">
        <div className="form-title">
          Briefly tell us about your product or service
        </div>
        <button
          disabled={text.length < 20}
          className="ready-to-write"
          onClick={readyToWrite}
        >
          ✓ Ready to Write
        </button>
        <textarea
          className="form-text"
          placeholder="Product name, brand name, USP, target audience, any brand guidelines."
          value={text}
          onChange={(event) => handleChange(event)}
        />
      </div>
      <div className="framework">Platform</div>
      <div className="platforms">
        <div
          className={plat === 1 ? "plat amazon" : "plat2 amzon2"}
          onClick={() => setPlat(1)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/amazon-2.png" />
        </div>
        <div
          className={plat === 2 ? "plat shopify" : "plat2 shopify2"}
          onClick={() => setPlat(2)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/shopify.png" />
        </div>
        <div
          className={plat === 3 ? "plat woo" : "plat2 woo2"}
          onClick={() => setPlat(3)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/woo.png" />
        </div>
        <div
          className={plat === 4 ? "plat etsy" : "plat2 etsy2"}
          onClick={() => setPlat(4)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/etsy.png" />
        </div>
        <div
          className={plat === 5 ? "plat ebay" : "plat2 ebay2"}
          onClick={() => setPlat(5)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/ebay.png" />
        </div>
        <div
          className={plat === 6 ? "plat big" : "plat2 big2"}
          onClick={() => setPlat(6)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/big.png" />
        </div>
        <div
          className={plat === 7 ? "plat shopee" : "plat2 shopee2"}
          onClick={() => setPlat(7)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/shopee.png" />
        </div>
        <div
          className={plat === 8 ? "plat other" : "plat2 other2"}
          onClick={() => setPlat(8)}
        >
          <img src="http://sparker.ai/wp-content/uploads/2023/01/other.png" />
        </div>
      </div>
    </>
  );
};

export default Outline8;
