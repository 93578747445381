import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  doc,
  deleteDoc,
} from "firebase/firestore";
import VideoPlan from "../VideoComponents/VideoPlan";

function AllVideos({
  db,
  userID,
  setZoom2,
  zoomVideo,
  setChange,
  change,
  searchQuery,
}) {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const videosRef = collection(db, `users/${userID}/completed-videos/`);

      const q = query(videosRef, limit(10000));

      const videoSnapshot = await getDocs(q);

      const videosData = videoSnapshot.docs.map((doc) => ({
        id: doc.id, // Include the document ID as a field
        ...doc.data(), // Include other fields from the document
      }));
      setVideos(videosData);
    };

    if (userID) {
      fetchVideos();
    }
  }, [userID, change]);

  ///////--------------------------------------//////////

  let filteredVideos =
    searchQuery === ""
      ? videos
      : videos.filter((document) => {
          if (document.title) {
            return document.title
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }
          return false;
        });

  return (
    <>
      <div className="documents">
        {filteredVideos.length > 0 ? (
          filteredVideos.map((video, i) => (
            <div
              className="document"
              key={i}
              onClick={(e) => {
                e.stopPropagation();

                zoomVideo(video);
                setZoom2(true);
              }}
            >
              {video.url && (
                <video
                  src={video.url}
                  style={{ maxWidth: "240px", maxHeight: "200px" }}
                />
              )}
              {video.title && <div className="vid-title">{video.title}</div>}
            </div>
          ))
        ) : (
          <>
            <div className="no-creations">
              No videos found. All your video creations will appear here.
            </div>
            <div className="video-demo-container">
              <div>
                {" "}
                Learn how you can create your first video within minutes:{" "}
              </div>
              <div className="video-demo">
                <video
                  className="video-responsive2"
                  controls
                  src="https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/Sparker+AI+Text+to+Video+Demo.mp4"
                />
              </div>
              <div className="learn-how">
                Please note before you can begin creating a video you need have an article
                saved so that it appears under the documents tab.
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AllVideos;
