import React, { useState } from "react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

function PortalButton({text}) {
  const [loading, setLoading] = useState(false);

  async function handleButtonClick() {
    setLoading(true);
    document.querySelectorAll("button").forEach((b) => (b.disabled = true));

    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({ returnUrl: window.location.origin });

    window.location.assign(data.url);
  }

  return (
    <div>
      <button
        id="billing-portal-button"
        className="portal-button"
        onClick={handleButtonClick}
      >
        {!loading ? (
          <span>{text}</span>
        ) : (
          <div className="portal-loader">
            <span className="write-loader"></span>
          <span>please wait..</span></div>
        )}
      </button>
    </div>
  );
}

export default PortalButton;
