import React, { useState, useEffect, useRef } from "react";

const ProgressMeta = ({
  wordCount,
  research22,
  aisize,
  percent,
  expected,
  rExpected,
  rComplete,
  wComplete,
  setrComplete,
  setwComplete,
  write,
  setWrite,
  setLoading2,
  
  calc,
}) => {
  const [writing2, setWriting2] = useState(0);
  const [researchBgColor, setResearchBgColor] = useState("white");
  const [writingBgColor, setWritingBgColor] = useState("white");
  const initialRender = useRef(true);

  let research = (Number(research22) / Number(rExpected)) * 100;
  let writing = 0;
  if (write) {
    writing = (Number(aisize.current) / Number(expected)) * 100;
  }

  let research1 = research.toFixed(1);
  let writing1 = writing.toFixed(1);

  let research2 = 0;
  if (research1 > 99) {
    research2 = 100;
  } else {
    research2 = research1;
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (!wComplete && writing > 99) {
        setwComplete(true);
        setLoading2(false);
        setWrite(false);
        setWriting2(100);
      } else if (!wComplete) {
        setWriting2(writing1);
      }
    }
  }, [aisize.current, wComplete]);

  useEffect(() => {
    setResearchBgColor("yellow");
    const timer = setTimeout(() => {
      setResearchBgColor("white");
    }, 300);
    return () => clearTimeout(timer);
  }, [research2]);

  useEffect(() => {
    setWritingBgColor("yellow");
    const timer = setTimeout(() => {
      setWritingBgColor("white");
    }, 300);
    return () => clearTimeout(timer);
  }, [writing2]);

  return (
    <div className={calc ? "progress-meta" : "hide"}>
      <div className="meta-each">
        research{" "}
        <span
          style={{
            display: "inline",
            backgroundColor: researchBgColor,
            transition: "background-color 100ms ease-in-out",
          }}
        >
          {research2} %
        </span>{" "}
      </div>
      <div className="meta-each">
        writing{" "}
        <span
          style={{
            display: "inline",
            backgroundColor: writingBgColor,
            transition: "background-color 100ms ease-in-out",
          }}
        >
          {writing2} %
        </span>
      </div>
    </div>
  );
};

export default ProgressMeta;
