import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
  updatePassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  deleteUser,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";

import { auth } from "../firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  //----------Email SignUp--------------//
  const createUser = async (email, password, username) => {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(user.user);
    await updateProfile(user.user, { displayName: username });
    const usersRef = doc(db, "users", user.user.uid);

    await setDoc(
      usersRef,
      {
        email: user.user.email,
        displayName: user.user.displayName,
        // add other data you want to store
      },
      { merge: true }
    );

    return user;
  };

  //-----------------------------------------//
  //----------Google Auth------------//
  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const user = await signInWithPopup(auth, provider);

    const usersRef = doc(db, "users", user.user.uid);
    await setDoc(
      usersRef,
      {
        email: user.user.email,
        displayName: user.user.displayName,
        // add other data you want to store
      },
      { merge: true }
    );
  };

  //-----------------Email Sign In-------------//

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  //-------------------------------------------//
  //-------------Loghout-------------------------//
  const logOut = () => {
    signOut(auth);
  };

  //--------------Forgot Password-----------------//

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  //-------------Re-Send Verify Email----------//

  const verifyEmail = (user) => {
    return sendEmailVerification(user);
  };

  //------------------Delete User------------------//
  const deleteUserAccount = async (user) => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      try {
        await deleteUser(user);
        // Delete user data from Firestore or Realtime Database if necessary
        // Redirect user to sign up page or home page, etc.
      } catch (error) {
        console.error(error);
      }
    }
  };
  //----------------------------------------------//
  // Update Display Name
  const updateDisplayName = async (newDisplayName) => {
    if (user) {
      await updateProfile(user, { displayName: newDisplayName });
      setUser({ ...user, displayName: newDisplayName });
    }
  };

  // Update Password
  const updatePassword2 = async (newPassword) => {
    if (user) {
      await updatePassword(user, newPassword);
    }
  };

  //-----------------------------------------------//
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        googleSignIn,
        createUser,
        signIn,
        logOut,
        forgotPassword,
        verifyEmail,
        deleteUserAccount,
        updateDisplayName,
        updatePassword2,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
