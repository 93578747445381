import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import GoogleLogin from "./GoogleLogin";
import Navbar from "../Components/Navbar";
import firebase from "firebase/app";
import "firebase/firestore";




const SignUp = () => {

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { createUser, user } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password || !username) {
        throw new Error("All fields are required.");
      }
      await createUser(email, password, username);
    //     const usersRef = doc(db, "users", user.uid);

    //  await setDoc(usersRef, {
    //    email: user.email,
    //    displayName: user.dispalyName,
    //    // add other data you want to store
    //  });
      // navigate("/verify");
    } catch (err) {
      setError(err.message);
    }
  };

//   const test = async () => {

//       await setDoc(usersRef, {
//     email: "email",
//     displayName: "displayName",
//     // add other data you want to store
//   });
// }


  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="line-sep"></div>
            <span className="line-text">Or continue with Email</span>
            <form className="login-form">
              <input
                type="email"
                placeholder="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="form-btn cont-email"
                onClick={() => setStep(2)}
              >
                Continue with Email
              </button>
            </form>
          </>
        );
      case 2:
        return (
          <>
            <div className="line-sep"></div>
            <span className="line-text">Create username and password</span>
            <form className="login-form" onSubmit={handleSubmit}>
              {error && <div className="error">{error}</div>}
              <div>
                <input
                  type="text"
                  id="username"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className="form-btn" type="submit">
                Join
              </button>
            </form>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="login-background">
        {/* <Navbar Link={Link} /> */}
        <div className="login-page">
          <div className="login-area">
            {/* <button onClick={test}>Test Users</button> */}
            <div className="login-top">
              <img
                src="http://sparker.ai/wp-content/uploads/2023/01/Sparker-new-logo-3-5.png"
                width="33px"
                align="center"
              />
              <h2>Create Your Account</h2>
            </div>

            <GoogleLogin />
            {renderStep()}
            <div className="account-yet">
              Already have an account? <Link to="/login">Login</Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
