import React, { useState, useEffect } from "react";

const LeftPopup = ({ leftPopup, setLeftPopup, leftPopupMessage }) => {
  //------------------//

  // console.log(leftPopup)

  const closeit = () => {
    setLeftPopup(false);
  };

  return (
    <>
      <div className={`leftpopup ${leftPopup ? "show" : "hide"}`}>
        <span>{leftPopupMessage}</span>
        <div className="error-btns">
          <button className="ignore" onClick={closeit}>
            close
          </button>
        </div>
      </div>
    </>
  );
};

export default LeftPopup;
