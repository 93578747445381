import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import VoiceList from "../VideoComponents/VoiceList";
import CopyToClipboardBox from "./CopyToClipboardBox";
// import { getAudioDurationInSeconds } from "@remotion/media-utils";

import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import Accordion from "./Accordion";

const SocialVideos = ({
  db,
  id,
  num,
  functionTrigger,
  selectedVoice,
  setSelectedVoice,
  setRunning,
  // updates,
  // setUpdates,
}) => {
  //   const { id } = useParams();

  // const id = "YNJeiI0oHNvtlHdi3IuC";

  const { user } = UserAuth();
  let uid = user.uid;

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  const initialRender = useRef(true);

  const [videos, setVideos] = useState();

  const [videoArray, setVideoArray] = useState();
  const [startingProcess, setStartingProcess] = useState(false);

  const [updates, setUpdates] = useState(`please wait..`);

  const [previewReady, setPreviewReady] = useState(false);

  // const [selectedVoice, setSelectedVoice] = useState();
  const [duration, setDuration] = useState(1);
  const [previewprogress, setPreviewprogress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sceneDurations, setSceneDurations] = useState();
  const [sequences, setSequences] = useState();
  const [resolution, setResolution] = useState({
    width: 1080,
    height: 1920,
    res: "hd",
  });
  const [voices, setVoices] = useState();
  const [progDone, setProgDone] = useState();
  const [images, setImages] = useState();
  const [music, setMusic] = useState({
    musicName: "Space Atmospheric Background",
    musicUrl:
      "https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/music/space-atmospheric-background-124841.mp3",
  });

  const [error1, setError1] = useState();
  const [error2, setError2] = useState();
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState("Untitled");
  const [renderFinished, setRenderFinished] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState();
  const [musicList, setMusicList] = useState();
  const [description, setDescription] = useState();
  ////-------Function Trigger-------/////
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (functionTrigger) {
        videoConversion();
      }
    }
  }, [functionTrigger]);

  ///---------------Available Tokens--------------///
  const [availableTokens, setAvailableTokens] = useState();

  const tokensRef = collection(db, `users/${userID}/tokens/`);

  const [runIt, setRunIt] = useState(false);

  ///------------------Sequences data----------///

  // useEffect(() => {
  //   let isMounted = true;

  //   setPreviewReady(false);

  //   if (userID !== "" && id) {
  //     ////////////////////////////////
  //     const videoRef = doc(db, `users/${userID}/videos/${id}`);

  //     const unsubscribe = onSnapshot(videoRef, (snapshot) => {
  //       if (isMounted) {
  //         if (snapshot.exists()) {
  //           const title = snapshot.data()?.title;
  //           const sequence = snapshot.data().sequence;
  //           const renderFinished = snapshot.data().renderFinished;
  //           const progress = snapshot.data().progress;
  //           const Previewprogress = snapshot.data().Previewprogress;
  //           const imgs = snapshot.data().images;
  //           const music = snapshot.data().music;
  //           const voices = snapshot.data().voices;
  //           const error1 = snapshot.data()?.error1;
  //           const error2 = snapshot.data()?.error2;
  //           const timeout = snapshot.data()?.progress_timeout;

  //           if (voices) {
  //             setVoices(voices);
  //           }

  //           if (renderFinished) {
  //             setRenderFinished(true);
  //           }
  //           if (music) {
  //             setMusic(music);
  //           }

  //           setImages(imgs);

  //           if (Previewprogress) {
  //             setPreviewprogress(Number(Previewprogress));
  //           }

  //           if (progress) {
  //             setProgDone(progress.done);
  //             setUpdates(
  //               `Render Progress: ${parseFloat(
  //                 progress.overallProgress.toFixed(1) * 100
  //               )} %`
  //             );

  //             setProgress(progress.overallProgress);
  //             setDownloadUrl(progress.outputFile);
  //           }

  //           if (error1) {
  //             setError1(error1);
  //           }

  //           if (error2) {
  //             setError2(error2);
  //           }

  //           if (timeout) {
  //             setTimeout(timeout);
  //           }

  //           if (title) {
  //             setTitle(title);
  //           }

  //           if (sequence) {
  //             setSequences(sequence);
  //           }
  //         } else {
  //           // Handle the case where the document does not exist
  //         }
  //       }
  //     });

  //     if (userID) {
  //       // setResolution2();
  //     }

  //     return () => {
  //       isMounted = false;

  //       unsubscribe();
  //     };
  //   }
  // }, [userID, id]);

  useEffect(() => {
    let isMounted = true;

    setPreviewReady(false);

    if (userID !== "" && id) {
      ////////////////////////////////
      const videoRef = doc(db, `users/${userID}/videos/${id}`);

      const unsubscribe = onSnapshot(videoRef, (snapshot) => {
        if (isMounted) {
          // if (snapshot.exists()) {
          console.log(`fetching video data for id: ${id}`);

          const title = snapshot.data()?.title;
          const sequence = snapshot.data()?.sequence;
          const renderFinished = snapshot.data()?.renderFinished;
          const progress = snapshot.data()?.progress;
          const Previewprogress = snapshot.data()?.Previewprogress;
          const imgs = snapshot.data()?.images;
          const music = snapshot.data()?.music;
          const voices = snapshot.data()?.voices;
          const error1 = snapshot.data()?.error1;
          const error2 = snapshot.data()?.error2;
          const timeout = snapshot.data()?.progress_timeout;

          if (voices) {
            setVoices(voices);
          }

          if (renderFinished) {
            setRenderFinished(true);
            setStartingProcess(false);
          }
          if (music) {
            setMusic(music);
          }

          setImages(imgs);

          if (Previewprogress) {
            setPreviewprogress(Number(Previewprogress));
          } else {
            setPreviewprogress(null);
          }

          if (progress?.done) {
            setProgDone(progress.done);
          } else {
            setProgDone(null);
          }

          if (progress?.overallProgress) {
            setProgress(progress.overallProgress);
          } else {
            setProgress(null);
          }

          if (progress?.outputFile) {
            setDownloadUrl(progress.outputFile);
            setUpdates(null);
            setStartingProcess(false);
            setRunning(false);
          } else {
            setDownloadUrl(null);
          }

          if (error1) {
            setError1(error1);
            setStartingProcess(false);
          } else {
            setError1(null);
          }

          if (error2) {
            setError2(error2);
            setStartingProcess(false);
          } else {
            setError2(null);
          }

          if (timeout) {
            setTimeout(timeout);
          }

          if (title) {
            setTitle(title);
          }

          if (sequence) {
            setSequences(sequence);
          }
          // }
          // else {
          //   // Handle the case where the document does not exist
          // }
        }
      });

      if (userID) {
        // setResolution2();
      }

      return () => {
        isMounted = false;

        unsubscribe();
      };
    }
  }, [userID, id]);

  console.log(`preview progress is: ${previewprogress}`);

  useEffect(() => {
    if (previewprogress && startingProcess) {
      const roundedNumber = Number(previewprogress.toFixed(2));
      setUpdates(`voiceover completed..${roundedNumber} % `);
      setRunning(true);
    }
  }, [previewprogress]);

  console.log(`progress is:`);
  console.log(progress);

  useEffect(() => {
    if (progress && progress > 0 && startingProcess) {
      const roundedNumber = Math.round(progress * 1000) / 10; // Round to 1 decimal point
      setUpdates(`video rendering..${roundedNumber} %`);
      setRunning(true);
    }
  }, [progress]);

  ///---------------Scene Durations--------------////
  let fps = 25;

  const getAudioDurationInSeconds = async (audioUrl) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(audioUrl);

      audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        audio.remove(); // Clean up the audio element
        resolve(duration);
      });

      audio.addEventListener("error", (error) => {
        audio.remove(); // Clean up the audio element
        reject(error);
      });
    });
  };

  const fetchAudioDurations = async () => {
    setUpdates(`combining audios..`);
    setRunning(true);

    console.log(`fetch audio durations triggered`);

    // console.log(`fetch audio durations triggered`);

    console.log(`Audio part 1`);

    try {
      console.log(`Audio part 2`);

      console.log(`sequences is:`);
      console.log(sequences);

      const durations = await Promise.all(
        sequences
          .sort((a, b) => a.index - b.index)
          .map(async (data) => await getAudioDurationInSeconds(data.url))
      );
      console.log(`Audio part 3`);

      const roundedDurations = durations.map((duration) =>
        Math.ceil(duration * fps)
      );
      console.log(`Audio part 4`);

      const totalDuration = roundedDurations.reduce(
        (accumulator, currentDuration) => accumulator + currentDuration,
        0
      );

      setDuration(totalDuration);

      const sceneDurationsWithFrom = roundedDurations.reduce(
        (acc, duration, index) => {
          const previousSum = acc[index - 1]
            ? acc[index - 1].from + acc[index - 1].durationInFrames
            : 0;
          let from;

          if (index === 0) {
            from = 0;
          } else {
            from = previousSum + 1;
          }
          const durationInFrames = duration;
          acc.push({ durationInFrames, from });
          return acc;
        },
        []
      );

      setSceneDurations(sceneDurationsWithFrom);
      const videoRef = doc(db, `users/${userID}/videos/${id}`);

      await updateDoc(videoRef, {
        sceneDurations: sceneDurationsWithFrom,
        duration: totalDuration,
      });
    } catch (error) {
      console.error("Failed to fetch audio durations:", error);
    }
  };

  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //   } else {
  //     if (sequences && sequences[0] && !sceneDurations && runIt) {
  //       fetchAudioDurations();
  //       processTexts();
  //     }
  //   }
  // }, [sequences]);

  useEffect(() => {
    const fetchDataAndProcessTexts = async () => {
      if (initialRender.current) {
        initialRender.current = false;
      } else {
        if (sequences && sequences[0] && !sceneDurations && runIt) {
          try {
            // Await the completion of fetchAudioDurations
            await fetchAudioDurations();

            // Now that fetchAudioDurations is done, you can proceed with processTexts
            processTexts();
          } catch (error) {
            // Handle errors if any occurred during fetchAudioDurations
            console.error("Failed to fetch audio durations:", error);
          }
        }
      }
    };

    fetchDataAndProcessTexts(); // Call the async function
  }, [sequences]);

  ///////////-----------------------/////////////////

  useEffect(() => {
    if (userID && tokensRef) {
      async function getAvailableTokens() {
        const querySnapshot = await getDocs(tokensRef);

        let sum = 0;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.tokens) {
            sum += data.tokens;
          }
        });
        const availableTokens = sum;

        setAvailableTokens(availableTokens);
      }

      getAvailableTokens();
    }
  }, [userID, tokensRef]);

  ///-------------Voice List--------------///
  ///------------Video Conversion---------///
  const videoConversion = async () => {
    if (!selectedVoice) {
      return alert("Please select a voice first.");
    }
    if (loading) {
      return alert("Please wait for current preview to finish");
    }

    setStartingProcess(true);

    setUpdates(`converting to mobile video..`);
    setRunning(true);

    console.log(`video conversion started`);
    setRunIt(true);

    // const words = cleanText.split(" ");
    const words = "One word two words";
    if (words?.length - 1 > 2500) {
      return alert(
        `Your article is ${
          words.length - 1
        } words long which is above the maximum limit of 2500 words. `
      );
    }

    let tokensToConsume = Math.round(words?.length * 0.5) + 500;

    if (tokensToConsume > availableTokens) {
      return alert(`You don't have enough tokens. Please top up.`);
    }

    setPreviewprogress(0);

    setDuration(1);

    setLoading(true);

    const videoRef = collection(db, `users/${userID}/video-trigger`);

    await addDoc(videoRef, {
      uid: userID,
      docid: id,
      speaker: selectedVoice,
    });

    console.log(`video trigger added`);

    const videoRef2 = doc(db, `users/${userID}/videos/${id}`);

    await setDoc(
      videoRef2,
      {
        music: "",
        resolution,
        duration: 1,
        progress: null,
        sceneDurations: [],
        sequence: [],
        images: [],
        error: [],
        sceneDurations: [],
        Previewprogress: 0,
      },
      { merge: false }
    );

    console.log(`video preview initialised`);
  };

  ////////---------------------////////////

  ////--------------Video Render-------///
  const videoRender = async (description) => {
    setProgress(0);
    setDownloadUrl();
    setError1();
    setError2();
    setRenderFinished(false);

    setUpdates(`Video Rendering started..`);
    setRunning(true);

    let tokensToConsume = Math.round(duration * 0.521) + 1000;

    if (tokensToConsume > availableTokens) {
      return alert(`You don't have enough tokens. Please top up.`);
    }
    const videoRef = doc(db, `users/${userID}/videos/${id}`);

    await updateDoc(videoRef, {
      progress: null,
      error1: null,
      error2: null,
      renderFinished: false,
    });

    const renderRef = collection(db, `users/${userID}/video-render/`);

    await addDoc(renderRef, {
      docid: id,
      uid: userID,
      title: title,
      description: description ? description : "nil",
    });
  };

  ////////////--------------------////////////

  ////---------Pexel Videos--------/////

  const searchPexels = async (searchTerms, page) => {
    try {
      const response = await fetch(
        `https://api.pexels.com/videos/search?query=${searchTerms}&per_page=20&page=${page}`,
        {
          headers: {
            Authorization:
              "563492ad6f91700001000001158c5856c24a4e9786de300918d57011",
          },
        }
      );
      const data = await response.json();
      return data.videos;
    } catch (error) {
      alert(`something went wrong`);
    }
  };

  //---------From AI function----/////

  const fromAI = async (promp) => {
    console.log(`fromAI started`);

    let resp;
    try {
      const response = await fetch(
        `https://us-central1-cruncher-ai-app.cloudfunctions.net/fromAI?promp=${promp}&u=${uid}&i=${id}`,
        {
          mode: "cors",
        }
      );
      const data = await response.json();
      if (data.model === "chat") {
        resp = data.data.choices[0].message.content;
      } else if (data.model === "davinci3" || data.model === "davinci2") {
        resp = data.data.choices[0].text;
      }
    } catch (error) {
      alert(`something went wrong`);
    }

    return resp;
  };

  /////////////

  function diceCoefficient(sentence, keywords) {
    let maxScore = 0;

    const lowtext1 = sentence.toLowerCase();
    const lowtext2 = keywords.toLowerCase();

    const words1 = lowtext1.split(" ");
    const words2 = lowtext2.split(" ");
    let intersection = 0;
    let union = words1.length + words2.length;

    // Check for intersection
    for (let j = 0; j < words1.length; j++) {
      for (let k = 0; k < words2.length; k++) {
        if (words1[j] === words2[k]) {
          intersection++;
          words2.splice(k, 1);
          break;
        }
      }
    }

    // Calculate Dice's coefficient
    const coefficient = (2 * intersection) / union;
    if (coefficient > maxScore) {
      maxScore = coefficient;
    }

    return maxScore;
  }
  ////////------------------/////////

  async function processTexts() {
    console.log(`process Texts Started`);

    setRunIt(false);

    let result = [];

    ///------Get Short Descriptors for full video-----////

    ////-----reduce texts array to generate descriptor--------////
    // const headingObjects = sequences?.filter(
    //   (sequence) => sequence?.meta === "heading"
    // );
    // const nonHeadingObjects = sequences?.filter(
    //   (sequence) => sequence?.meta !== "heading"
    // );

    // if (headingObjects.length >= 2) {
    //   texts = headingObjects
    //     .sort(() => Math.random() - 0.5)
    //     .slice(0, 10)
    //     .map((sequence) => sequence?.text);
    // } else {
    //   const remainingCount = 10 - headingObjects.length;
    //   texts = headingObjects.map((sequence) => sequence?.text);

    //   if (remainingCount > 0 && nonHeadingObjects.length > 0) {
    //     const additionalTexts = nonHeadingObjects
    //       .sort(() => Math.random() - 0.5)
    //       .slice(0, remainingCount)
    //       .map((sequence) => sequence?.text);

    //     texts = [...texts, ...additionalTexts];
    //   }
    // }

    let texts = sequences.map((sequence) => sequence?.text);

    const paragraph = texts.join(" ");

    ///-------Generate description and hashtags----////

    let promp4 = `Give me viral social media video description including emojis and atleast 10 hashtags for this video transcript ----
            ${paragraph}
           ----- Only give me the viral social media video description including emojis and 10 hashtags without any explanation:
            `;

    setUpdates(`creating description and hashtags..`);

    let description = await fromAI(promp4);

    console.log(`description is:`);
    console.log(description);

    setDescription(description);

    let promp2 = `Give me a word to describe the top-level niche or category this paragraph is from:
            ${paragraph}
           ----- Only give me the top-level niche or category without any explanation:
            `;

    setUpdates(`searching for relevant video clips..`);

    let searchTerms = await fromAI(promp2);

    // console.log(`search terms are:`);
    // console.log(searchTerms);

    ///////-------Gather Videos From Pexels------------------////

    // let videos2 = [];
    // for (let i = 0; i < 1; i += 1) {
    //   let videosArray = await searchPexels(searchTerms, i);

    //   videos2 = videos2.concat(videosArray);
    // }

    // setVideos(videos2);

    // ///-------For Each of the Videos make an object with descriptive url and the actual video link----///
    // const urlsObjects2 = videos2?.map((video) => {
    //   const file = video.video_files?.find((file) => file.quality === "hd");
    //   const link = file ? file.link : "";

    //   return { url: video.url, link };
    // });

    // /////--------From the Descriptive URL extract keywords and add to the same object------////
    // for (let i = 0; i < urlsObjects2.length; i++) {
    //   const urlObject = urlsObjects2[i];
    //   const url = urlObject?.url;

    //   const keywords = url
    //     ?.replace(/^https?:\/\/(?:www\.)?pexels\.com\/video\//, "")
    //     ?.replace(/-\d+\/$/, "")
    //     ?.replace(/-/g, " ");

    //   urlObject.keywords = keywords;
    // }

    // console.log(`total videos are:`);
    // console.log(urlsObjects2);
    // ///--------Keep only unique objects and remove duplicates-------////

    // const uniqueKeywords = {};
    // const filteredUrlsObjects2 = urlsObjects2.filter((obj) => {
    //   if (!uniqueKeywords[obj.keywords]) {
    //     uniqueKeywords[obj.keywords] = true;
    //     return true;
    //   }
    //   return false;
    // });

    // console.log(`Unique videos are:`);
    // console.log(filteredUrlsObjects2);

    // //----------//

    // for (let i = 0; i < sequences.length; i += 1) {
    //   ////--------Remove urls already added to the results array-------////
    //   const filteredUrlsObjects3 = filteredUrlsObjects2.filter((obj) => {
    //     return !result.some((url) =>
    //       url.includes(obj.keywords.replace(/ /g, "-"))
    //     );
    //   });

    //   console.log(`iteration is ${i}`);

    //   console.log(`current videos remaining are:`);
    //   console.log(filteredUrlsObjects3);

    //   ////--------Get Scene Sentence----////
    //   const sentence = sequences[i].text;

    //   //------Compare Sentence to Video Keywords and keep top 15----//

    //   const scoredObjects = filteredUrlsObjects3
    //     .map((obj) => {
    //       const score = diceCoefficient(sentence, obj.keywords);
    //       return { ...obj, score };
    //     })
    //     .sort((a, b) => b.score - a.score);

    //   const top15Objects = scoredObjects.slice(0, 20);

    //   console.log(`top 20 videos are:`);
    //   console.log(top15Objects);

    //   // const urls = top15Objects?.map((obj) => obj.url);

    //   const urls = filteredUrlsObjects3?.map((obj) => obj.url);

    //   ////------ASK AI to choose the signle best video url from the top 15-------/////

    //   setUpdates(`matching best clip for scene no. ${i}`);

    //   let promp = `I am giving you a sentence and a array of video url strings. Your task is to pick one url string that would best match the sentence and return this. Here is the sentence: ${sentence} ----- here is the array of video url strings to choose from: ${urls}  --------- Please reply with the best url string match for the sentence, you must pick one url even if none of them matches. Do not give any explanations, only write the url string as a plain string without any other characters like inverted commas or newline characters:`;

    //   const response = await fromAI(promp);

    //   console.log(`AI picked video URL:`);
    //   console.log(response);

    //   ////------Push this to the results array----///

    //   result.push(response.trim());
    // }

    // console.log(`All AI picked Urls array is:`);
    // console.log(result);
    // ///--------make the actual video links array from the descriptive urls array---/////
    // const links = filteredUrlsObjects2
    //   .filter((obj) => result.includes(obj.url)) // Filter objects whose 'url' field matches the strings in 'result'
    //   .map((obj) => obj.link); // Extract 'link' field values from the matching objects

    // console.log(`All AI picked Actual Links array is:`);
    // console.log(links);
    // let result = [];
    let links = [];

    for (let i = 0; i < sequences.length; i += 1) {
      ////--------Remove urls already added to the results array-------////

      ////--------Get Scene Sentence----////
      const sentence = sequences[i].text;

      let promp6 = `Give me one relatively common proper noun that best captures this sentence which from an article on ${searchTerms}. Please avoid any specific brand names. Here is the sentence "${sentence}" -- Give me the relatively common proper noun only`;

      let searchTerm = await fromAI(promp6);

      let videosArray = await searchPexels(searchTerm, 1);

      ///-------For Each of the Videos make an object with descriptive url and the actual video link----///
      const urlsObjects2 = videosArray?.map((video) => {
        const file = video.video_files?.find((file) => file.quality === "hd");
        const link = file ? file.link : "";

        return { url: video.url, link };
      });

      /////--------From the Descriptive URL extract keywords and add to the same object------////
      for (let i = 0; i < urlsObjects2.length; i++) {
        const urlObject = urlsObjects2[i];
        const url = urlObject?.url;

        const keywords = url
          ?.replace(/^https?:\/\/(?:www\.)?pexels\.com\/video\//, "")
          ?.replace(/-\d+\/$/, "")
          ?.replace(/-/g, " ");

        urlObject.keywords = keywords;
      }
      ////--------Remove urls already added to the results array-------////
      const filteredUrlsObjects3 = urlsObjects2.filter((obj) => {
        return !result.some((url) =>
          url.includes(obj.keywords.replace(/ /g, "-"))
        );
      });

      //------Compare Sentence to Video Keywords and keep top 15----//

      const scoredObjects = filteredUrlsObjects3
        .map((obj) => {
          const score = diceCoefficient(sentence, obj.keywords);
          return { ...obj, score };
        })
        .sort((a, b) => b.score - a.score);

      const top15Objects = scoredObjects.slice(0, 10);

      console.log(`top 10 videos are:`);
      console.log(top15Objects);

      const urls = top15Objects?.map((obj) => obj.url);

      ////------ASK AI to choose the signle best video url from the top 15-------/////

      setUpdates(
        `selecting relevant video clips.. ${
          Number((i / sequences.length).toFixed(1)) * 100
        } %`
      );

      let promp = `I am giving you a sentence and a array of video url strings. Your task is to pick one url string that would best match the sentence and return this. Here is the sentence: ${sentence} ----- here is the array of video url strings to choose from: ${urls}  --------- Please reply with the best url string match for the sentence, you must pick one url even if none of them matches. Do not give any explanations, only write the url string as a plain string without any other characters like inverted commas or newline characters:`;

      const response = await fromAI(promp);

      console.log(`AI picked video URL:`);
      console.log(response);

      ////------Push this to the results array----///

      let result2 = response.trim();

      result.push(response.trim());

      console.log(`All AI picked Urls array is:`);
      console.log(result2);
      ///--------make the actual video links array from the descriptive urls array---/////

      let link = null;

      // Search through urlsObjects2
      for (const obj of urlsObjects2) {
        if (obj.url.includes(result2)) {
          link = obj.link;
          break; // Stop searching once a match is found
        }
      }

      links.push(link);
    }

    const videoRef = doc(db, `users/${userID}/videos/${id}`);

    const updatedSequences = sequences.map((obj, index) => ({
      ...obj,
      videoUrl: links[index]
        ? links[index]
        : "https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/background+video+backup.mp4",
      videoZoom: Math.floor(Math.random() * 2) + 1, // Randomly generates either 1 or 2
    }));

    setUpdates(`adding selected video clips..`);

    await updateDoc(videoRef, {
      sequence: updatedSequences,
    });

    // const updatedSequence = sequences.map((obj, index) => ({
    //   ...obj,
    //   videoUrl: links[index]
    //     ? links[index]
    //     : "https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/static/background+video+backup.mp4",
    //   videoZoom: Math.floor(Math.random() * 2) + 1, // Randomly generates either 1 or 2
    // }));

    // const videoRef = doc(db, `users/${userID}/videos/${id}`);

    // await updateDoc(videoRef, {
    //   sequence: updatedSequence,
    // });

    /////////------------Get Music Data------/////////
    const querySnapshot = await getDocs(collection(db, "music"));
    const musicData = querySnapshot.docs.map((doc) => doc.data());

    //////-------Get Music Names------//////
    const musicArray = musicData?.map((obj) => obj.name);

    const musicNames = musicArray.join(`  @@@@  `);

    let promp3 = `I am giving you a lot of names for background music tracks and I'm also giving you the discriptor for a video article. Your task is to pick one music track name that would be best used as background music in the video article without giving any explanations. Here is the descriptor for the video article: ${searchTerms} ----- here are the music track names to choose from: ${musicNames}  --------- Please reply with the best music track name match for the descriptor, you must pick one name even if none of them matches. Do not give any explanations, only write the exact full music track name that you have chosen don't add any other characters like inverted commas or newline characters or explanations:`;

    console.log(promp3);

    setUpdates(`selecting suitable background music..`);

    const response2 = await fromAI(promp3);

    let musicName = response2.trim();

    console.log(`music name is: `);

    console.log(musicName);

    let musicLink; // Variable to store the matching URL

    // Iterate over the 'musicArray'
    // for (let i = 0; i < musicData.length; i++) {
    //   if (musicData[i].name.toLowerCase() === musicName.toLowerCase()) {
    //     musicLink = musicData[i].url; // Store the URL value if the name matches
    //     break; // Exit the loop since a match has been found
    //   }
    // }

    for (let i = 0; i < musicData.length; i++) {
      if (musicData[i].name.toLowerCase().includes(musicName.toLowerCase())) {
        musicLink = musicData[i].url; // Store the URL value if the name includes the musicName
        break; // Exit the loop since a match has been found
      }
    }

    console.log(`music link is: `);

    console.log(musicLink);

    // Assign the fallback URL if 'musicUrl' is still null or undefined
    if (!musicLink) {
      musicLink =
        "https://remotionlambda-useast1-j1zvxvq9hn.s3.amazonaws.com/music/space-atmospheric-background-124841.mp3";
    }

    await updateDoc(videoRef, {
      music: { musicName: "something", musicUrl: musicLink },
    });

    setPreviewReady(true);

    if (!num) {
      setUpdates(`Starting video render process..`);

      await videoRender(description);
    } else {
      setUpdates(null);
      setRunning(false);
    }

    const docRef = doc(db, `users/${userID}/documents/${id}`);

    setUpdates(`deleting temp document`);
    await deleteDoc(docRef);
    setUpdates(null);
    setRunning(false);
  }

  return (
    // <div className="social-download">
    //   {/* <VoiceList
    //     setSelectedVoice={setSelectedVoice}
    //     selectedVoice={selectedVoice}
    //   /> */}
    //   {/* <button
    //     onClick={() => {
    //       videoConversion();
    //     }}
    //   >
    //     Convert Video
    //   </button> */}

    //   {downloadUrl ? (
    //     <>
    //       <span
    //         className="article-link"
    //         // to={`/doc/${newDoc}/1`}
    //         onClick={(e) => {
    //           const url = new URL(`${downloadUrl}`, window.location.href);
    //           window.open(url.toString(), "_blank");
    //         }}
    //       >
    //         Social Media Video is Ready
    //       </span>
    //     </>
    //   ) : (
    //     <div className="social-progress">
    //       <div className="social-loader2"></div>
    //       <div className="social-updates">{updates}</div>
    //     </div>
    //   )}
    //   {downloadUrl && <CopyToClipboardBox text={description} />}
    // </div>

    <>
      <div className="filter-tabs3">
        {/* <button
          className="write-btn"
          onClick={() => {
            videoConversion();
          }}
        >
          Convert to Video
        </button> */}

        <div className="article-progress">
          <>
            {previewReady && (
              <span
                className="article-link"
                onClick={(e) => {
                  const url = new URL(`/video/${id}/`, window.location.href);
                  window.open(url.toString(), "_blank");
                }}
              >
                Preview is Ready (draft)
              </span>
            )}
          </>
          {downloadUrl && (
            <span
              className="article-link"
              // to={`/doc/${newDoc}/1`}
              onClick={(e) => {
                const url = new URL(`${downloadUrl}`, window.location.href);
                window.open(url.toString(), "_blank");
              }}
            >
              Download Link
            </span>
          )}

          {updates && (
            <div className="social-progress">
              <div className="social-loader2"></div>
              <div className="social-updates">{updates}</div>
              {/* <div className="social-description">{description}</div> */}
            </div>
          )}
        </div>
      </div>

      {(downloadUrl || previewReady) && (
        <>
          {" "}
          <CopyToClipboardBox text={description} />{" "}
        </>
      )}
    </>
  );
};

export default SocialVideos;
