import React, { useId, useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { loadCheckout, customerPortal } from "./StripeFunction";

import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

const Navbar = ({
  Link,
  db,
  searchQuery,
  setSearchQuery,
  mainPage,
  menu,
  setMenu,
  placeholder,
}) => {
  const { user, logOut, deleteUserAccount } = UserAuth();
  const navigate = useNavigate();

  let userID = "";
  if (user) {
    userID = user.uid;
  }

  //////////Available Tokens/////////
  const [availableTokens, setAvailableTokens] = useState();

  useEffect(() => {
    async function getAvailableTokens() {
      const querySnapshot = await getDocs(
        collection(db, `users/${userID}/tokens/`)
      );

      let sum = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.tokens) {
          sum += data.tokens;
        }
      });
      const availableTokens = (sum / 2000).toFixed(2);

      setAvailableTokens(availableTokens);
    }

    getAvailableTokens();
  }, [userID]);

  //////////Sign OUT//////////////
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {}
    navigate("/");
  };
  ////////////Delete USER//////////
  const handleDeleteUser = async (user) => {
    let uid = user.uid;

    // // ////Delete from Database/////////
    const userRef = doc(db, `users/${uid}`);
    await deleteDoc(userRef);

    ///Delete Auth Account/////
    user
      .delete()
      .then(() => {
        // User deleted.
        alert("Your account was deleted successfully");
      })
      .catch((error) => {
        alert("There was a problem deleting your account");
      });
  };

  /////------------------////
  const [isTopbarSolid, setIsTopbarSolid] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const shouldTopbarBeSolid = window.pageYOffset > 80;
      setIsTopbarSolid(shouldTopbarBeSolid);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //  const topbarClassName = isTopbarSolid ? "topbar2" : "";

  ////------------------/////
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    // If the image fails to load, set imageError to true
    setImageError(true);
  };

  return (
    <div className={isTopbarSolid ? "top-bar top-bar2" : "top-bar"}>
      <div
        className="logo"
        onClick={(e) => {
          e.stopPropagation();
          setMenu(false);
          navigate("/");
        }}
      >
        <img
          src="http://sparker.ai/wp-content/uploads/2023/01/Sparker-new-logo-3-5.png"
          width="33px"
          align="center"
        />
        <span
          onClick={(e) => {
            e.stopPropagation();
            setMenu(false);
            navigate("/");
          }}
        >
          Sparker
        </span>
      </div>{" "}
      <div
        className={mainPage ? "searchbar" : "hide"}
        onClick={() => {
          setMenu(false);
        }}
      >
        <img src="http://sparker.ai/wp-content/uploads/2023/04/search.png" />
        <input
          className="searchbox"
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={placeholder}
        />
      </div>
      {user && user.displayName ? (
        <div className="top-btns">
          {" "}
          {user.photoURL && !imageError ? (
            <img
              className="user-image"
              src={user.photoURL}
              onError={handleImageError} // Handle image load error
              onClick={(e) => {
                e.stopPropagation();

                setMenu(!menu);
              }}
            />
          ) : (
            <div
              className="user-icon"
              onClick={(e) => {
                e.stopPropagation();

                setMenu(!menu);
              }}
            >
              {user.displayName[0]}
            </div>
          )}
          <div
            className={menu ? "nav-menu" : "hide"}
            onClick={(e) => {
              e.stopPropagation();

              setMenu(true);
            }}
          >
            <div className="user">
              <div className="left">
                <span>{user.displayName[0]}</span>
              </div>
              <div className="right">
                <h4>{user.displayName}</h4>
                <span>{user.email}</span>
              </div>
            </div>
            <div
              className="tokens"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/top-up");
              }}
            >
              <div className="left">
                <img src="http://sparker.ai/wp-content/uploads/2023/04/tokens-2.png" />
              </div>
              <div className="right">
                <span className="amount">${availableTokens}</span>
                <span className="heading">available</span>

                <button
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/top-up");
                  }}
                >
                  Top Up
                </button>
              </div>
            </div>
            <div
              className="item subscription"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/");
              }}
            >
              <div className="left">
                <img src="http://sparker.ai/wp-content/uploads/2023/04/home.png" />
              </div>
              <div className="right">
                <span className="heading">Home</span>
              </div>
            </div>

            <div
              className="item subscription"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/manage-subscription");
              }}
            >
              <div className="left">
                <img src="http://sparker.ai/wp-content/uploads/2023/04/subscription.png" />
              </div>
              <div className="right">
                <span className="heading">Manage Subscription</span>
                <img src="http://sparker.ai/wp-content/uploads/2023/04/stripe.png" />
              </div>
            </div>
            {/* <div className="item help">
              <div className="left">
                <img src="http://sparker.ai/wp-content/uploads/2023/04/help.png" />
              </div>
              <div className="right">
                <span className="heading">Get Help</span>
              </div>
            </div> */}
            <div
              className="item account"
              onClick={() => {
                navigate("/account");
              }}
            >
              <div className="left">
                <img src="http://sparker.ai/wp-content/uploads/2023/04/account-3.png" />
              </div>
              <div className="right">
                <span className="heading">Account</span>
              </div>
            </div>
            <div className="item logout" onClick={handleSignOut}>
              <div className="left">
                <img src="http://sparker.ai/wp-content/uploads/2023/04/logout.png" />
              </div>
              <div className="right">
                <span className="heading">Lougout</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="top-btns">
          <div className="sign-btn2">
            <Link to="/login">Log in</Link>
          </div>
          {"  "}
          <div className="sign-btn">
            <Link to="/signup">Sign up</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
