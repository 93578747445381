import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";

const StopMessage = ({ stopFunction, stop, setStop }) => {
  const { id } = useParams();
  const { user } = UserAuth();
  let uid = user.uid;
  //--------------------//

  //------------------//

  return (
    <>
      <div className={stop ? "editor-message" : "hide"}>
        <div
          className="all-templates close-message"
          onClick={() => {
            setStop(false);
          }}
        >
          close
        </div>
        <div className="check-container">
          <h3>Are you sure ?</h3>
          <span>
            Note: Any topics which have already been researched will usually
            still be delivered and cost tokens.{" "}
          </span>
        </div>
        <div className="writer-container stop-container">
          <button
            className="write-btn"
            // className={write ? "no-write" : "write-btn"}
            onClick={() => {
              stopFunction();
              setStop(false);
            }}
          >
            <img
              src="http://sparker.ai/wp-content/uploads/2023/01/write-spark-120-×-120-px.png"
              width="15px"
              align="left"
              alt=""
            />
            <span>Stop writing</span>
          </button>{" "}
        </div>
      </div>
    </>
  );
};

export default StopMessage;
